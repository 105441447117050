import type { EmitData, EmitEvent, ReconnectType } from './types';

interface QueuedEvent<T extends keyof EmitEvent> {
  event: T;
  data?: EmitEvent[T]['data'];
}

class WorkGramClient {
  private static instance: WorkGramClient;

  private static privateInstance: WorkGramClient | undefined;

  private static socket?: WebSocket;

  private static url: string;

  private static authorization?: string;

  private static sendQueue: QueuedEvent<keyof EmitEvent>[] = [];

  private static eventListeners: Map<keyof EmitEvent, (data: any) => void>;

  private static reconnectInterval: number = 5000;

  private static shouldReconnect: boolean = true;

  private static pingInterval: number = 10000;

  private static pingTimerId?: NodeJS.Timeout;

  private static reconnectTimerId?:NodeJS.Timeout;

  private static maxReconnectAttempts: number = 12;

  private static reconnectAttempts: number = 0;

  private static isReconnecting:boolean = false;

  // type = error | ping |
  private static reconnectType :ReconnectType;

  private constructor(url?: string, authorization?: string) {
    if (url && authorization) {
      WorkGramClient.url = `${url}?authorization=${authorization}`;
    }
    WorkGramClient.authorization = authorization;
    if (!WorkGramClient.eventListeners) WorkGramClient.eventListeners = new Map();
    if ((url && authorization) || WorkGramClient.url) {
      WorkGramClient.socket = new WebSocket(WorkGramClient.url);
      WorkGramClient.socket.onopen = WorkGramClient.onOpen;
      WorkGramClient.socket.onmessage = WorkGramClient.onMessage;
      WorkGramClient.socket.onclose = WorkGramClient.onClose;
      WorkGramClient.socket.onerror = WorkGramClient.onError;
    }
  }

  public static get Instance(): WorkGramClient | null {
    if (!WorkGramClient.privateInstance) {
      // eslint-disable-next-line no-null/no-null
      return null;
    }
    return WorkGramClient.privateInstance;
  }

  public static get Socket(): WebSocket | undefined {
    if (!WorkGramClient.socket) {
      return undefined;
    }
    return WorkGramClient.socket;
  }

  static connect(url?: string, authorization?: string): void {
    if (WorkGramClient.privateInstance && WorkGramClient.socket) return;
    WorkGramClient.privateInstance = new WorkGramClient(url, authorization);
    WorkGramClient.isReconnecting = false;
  }

  static reconnect():void {
    if (WorkGramClient.isReconnecting) return;
    if (WorkGramClient.reconnectAttempts >= WorkGramClient.maxReconnectAttempts) {
      clearInterval(WorkGramClient.reconnectTimerId);
      return;
    }
    WorkGramClient.isReconnecting = true;

    WorkGramClient.reconnectAttempts += 1;
    WorkGramClient.disconnect();
    WorkGramClient.socket = undefined;
    WorkGramClient.privateInstance = undefined;
    WorkGramClient.connect();
  }

  static onOpen = () => {
    while (WorkGramClient.sendQueue.length > 0) {
      const { event, data } = WorkGramClient.sendQueue.shift()!;
      WorkGramClient.defaultEmitEvent(event, data);
    }
    WorkGramClient.reconnectInterval = 5000;
    WorkGramClient.reconnectAttempts = 0;
    WorkGramClient.isReconnecting = false;
    WorkGramClient.reconnectType = undefined;

    if (WorkGramClient.pingTimerId) {
      clearInterval(WorkGramClient.pingTimerId);
    }
    if (WorkGramClient.reconnectTimerId) {
      clearInterval(WorkGramClient.reconnectTimerId);
    }
    WorkGramClient.defaultEmitEvent('ping');
    WorkGramClient.pingTimerId = setInterval(() => {
      WorkGramClient.defaultEmitEvent('ping');

      WorkGramClient.reconnectTimerId = setInterval(() => {
        if (WorkGramClient.pingTimerId) clearInterval(WorkGramClient.pingTimerId);
        if (WorkGramClient.isReconnecting) return;
        if (WorkGramClient.reconnectType === 'error') {
          clearInterval(WorkGramClient.reconnectTimerId);
          return;
        }
        WorkGramClient.reconnectType = 'ping';
        WorkGramClient.reconnect();
      }, WorkGramClient.reconnectInterval);
    }, WorkGramClient.pingInterval);

    WorkGramClient.defaultSubscribeToEvent('ping', () => {
      if (WorkGramClient.reconnectTimerId) clearInterval(WorkGramClient.reconnectTimerId);
    });
  };

  static onMessage = (event: MessageEvent) => {
    const response = JSON.parse(event.data);
    const listener = WorkGramClient.eventListeners.get(response.event);
    if (listener) {
      listener(response);
    }
  };

  static onClose = () => {
    if (WorkGramClient.pingTimerId) {
      clearInterval(WorkGramClient.pingTimerId);
    }

    if (WorkGramClient.shouldReconnect) {
      setTimeout(() => WorkGramClient.connect(), WorkGramClient.reconnectInterval);
      WorkGramClient.reconnectInterval = Math.min(30000, WorkGramClient.reconnectInterval * 2); // Cap at 30 seconds
    }
  };

  static onError = () => {
    WorkGramClient.reconnectType = 'error';
    if (WorkGramClient.reconnectAttempts >= WorkGramClient.maxReconnectAttempts) return;
    setTimeout(() => WorkGramClient.reconnect(), WorkGramClient.reconnectInterval);
  };

  static disconnect(): void {
    WorkGramClient.shouldReconnect = false;

    if (WorkGramClient.pingTimerId) {
      clearInterval(WorkGramClient.pingTimerId);
      WorkGramClient.pingTimerId = undefined;
    }

    if (WorkGramClient.socket) {
      WorkGramClient.socket.close();
    }
  }

  static defaultSubscribeToEvent<T extends keyof EmitEvent>(
    event: T,
    callback: (data:EmitData<T, EmitEvent[T]['response']>
    ) => void,
  ) {
    if (!WorkGramClient.eventListeners) WorkGramClient.eventListeners = new Map();
    WorkGramClient.eventListeners.set(event, callback);
    return WorkGramClient.defaultSubscribeToEvent;
  }

  subscribeToEvent = WorkGramClient.defaultSubscribeToEvent;

  static defaultUnsubscribeFromEvent(event: keyof EmitEvent): void {
    WorkGramClient.eventListeners.delete(event);
  }

  unsubscribeFromEvent = WorkGramClient.defaultUnsubscribeFromEvent;

  static defaultEmitEvent<T extends keyof EmitEvent>(event: T, data?: EmitEvent[T]['data']): void {
    if (WorkGramClient.socket && WorkGramClient.socket.readyState === WebSocket.OPEN) {
      WorkGramClient.socket.send(JSON.stringify({ event, data }));
    } else {
      const existingIndex = WorkGramClient.sendQueue.findIndex((q) => q.event === event);

      if (existingIndex > -1) {
        WorkGramClient.sendQueue[existingIndex] = { event, data };
      } else {
        WorkGramClient.sendQueue.push({ event, data });
      }
    }
  }

  emitEvent = WorkGramClient.defaultEmitEvent;
}

export default WorkGramClient;
