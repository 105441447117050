export interface DictOptionRes {
  types?: string[];
  [property: string]: any;
}
export interface DictOptionReq {
  /**
   * 状态码
   */
  code?: number;
  /**
   * 数据集
   */
  data?: { [key: string]: any };
  error?: { [key: string]: any };
  /**
   * 提示消息
   */
  message?: string;
  /**
   * 业务编码
   */
  svcCode?: string;
  /**
   * 服务器时间戳
   */
  timestamp?: number;
  /**
   * 链路ID
   */
  traceID?: string;
  [property: string]: any;
}
