import type { FC } from '../../../../lib/teact/teact';
import React, {
  memo,
} from '../../../../lib/teact/teact';

import type { MemberInfo, TgUserListModel } from '../../../../api/types';
import type { IndependentTgUserItem, TgUserGroupInfo } from '../../../../lib/workGramClient/tgUser';
import type { LeftColumnContent, LoadMoreDirection } from '../../../../types';
import type { WorkGramUserData } from '../../../../util/praseWorkGramHash';
import type { UnReadGroupProps, UnReadGroupTypeValues } from '../TgUserMenuItemSetting';

import buildClassName from '../../../../util/buildClassName';

import InfiniteScroll from '../../../ui/InfiniteScroll';
import BuildLoadingView from './BuildLoadingView';
import BuildTgUserGroup from './BuildTgUserGroup';
import BuildTgUserList from './BuildTgUserList';
import GroupButton from './GroupButton';
import IndependentHeader from './IndependentHeader';

import './styles/TgUsersListGroupButton.scss';

type OwnProps = {
  isIndependent?:boolean;
  content: LeftColumnContent;
  tgUserList:{
    list: (IndependentTgUserItem | TgUserListModel)[];
    groupedUsers: TgUserGroupInfo[];
  };
  tgUserListRef: React.RefObject<HTMLDivElement>;
  className?:string;
  tgUsersGroupsFilter?:string;
  tgUsersFilter?:string;
  handleKeyDown: (e: React.KeyboardEvent<any>) => void;
  getTgUserMore: ({ direction }: { direction: LoadMoreDirection; noScroll?: boolean }) => void;

  loginUserTop:boolean;
  isOpenAutoReplyConfig:boolean;
  unReadGroupValue:UnReadGroupProps;
  tgUserSet: TgUserListModel[][];

  workData:WorkGramUserData | null;
  listImmobilization:boolean;
  independentMemberInfo:MemberInfo | null;
  handleMemberClick:(id:number)=>void;
  handleSwitchTgUsers?:()=>void;
  listImmobilizationClick:()=>void;
  handleLoginUserTop:(e:React.ChangeEvent<HTMLInputElement>)=>void;
  handleReadAll:()=>void;
  unReadGroupClick:()=>void;
  handleUnReadGroupType:(type:UnReadGroupTypeValues, value:UnReadGroupTypeValues | undefined)=>void;
  handleUnReadOtherDataTimeChange:(date?:Date | number)=>void;
  handleIsOpenAutoReplyConfigChange:(e:React.ChangeEvent<HTMLInputElement>)=>void;
  getAutoReplyIsOpen:()=>void;

  onMenuHandler:(type:'single' | 'all' | 'unpinTop' | 'pinTop' | 'comment' | 'addGroup' | 'moveFolder',
    item?:TgUserListModel | IndependentTgUserItem)=>void;
  onScrollToFolder:(folderId:string)=> () => void;
  handleUserClick:(item: TgUserListModel | IndependentTgUserItem,
    e?: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>;

  onChangeSearchQuery: (query: string) => void;
  onImageError: (item: MemberInfo, independent: boolean) => void;

  accordions:string[];
  tgUserGroupRef:React.RefObject<HTMLDivElement>;
  openAccordion:(folderId:string, isInit?:boolean)=>()=>void;
  folderTitleRefs:React.RefObject<Record<string, HTMLDivElement>>;
  isIndependentReady:boolean;

};

const BuildTgUsersListView: FC<OwnProps> = ({
  isIndependent, listImmobilization, workData, independentMemberInfo,
  content, tgUserListRef, handleKeyDown, tgUserList, getTgUserMore, className,
  loginUserTop,
  isOpenAutoReplyConfig,
  unReadGroupValue,
  tgUserSet,
  tgUsersGroupsFilter,
  tgUsersFilter,
  handleSwitchTgUsers,
  listImmobilizationClick,
  handleLoginUserTop,
  handleReadAll,
  unReadGroupClick,
  handleUnReadGroupType,
  handleUnReadOtherDataTimeChange,
  handleIsOpenAutoReplyConfigChange,
  getAutoReplyIsOpen,
  onMenuHandler,
  onScrollToFolder,
  handleUserClick,
  onChangeSearchQuery,
  onImageError,
  accordions,
  tgUserGroupRef,
  folderTitleRefs,
  openAccordion,
  isIndependentReady,
}) => {
  return (
    <InfiniteScroll
      ref={tgUserListRef}
      onKeyDown={handleKeyDown}
      items={tgUserList.list}
      onLoadMore={getTgUserMore}
      className={
        buildClassName(
          isIndependent ? 'IndependentTgUserList'
            : 'TgUserList',
          className,
          'chat-list',
          'custom-scroll',
          listImmobilization && 'list-spread',
        )
      }
    >
      {
        isIndependent && (
          <IndependentHeader
            key="independent-header"
            independent={isIndependent}
            workData={workData}
            content={content}
            isOpenAutoReplyConfig={isOpenAutoReplyConfig}
            listImmobilization={listImmobilization}
            loginUserTop={loginUserTop}
            unReadGroupValue={unReadGroupValue}
            independentMemberInfo={independentMemberInfo}
            listImmobilizationClick={listImmobilizationClick}
            handleSwitchTgUsers={handleSwitchTgUsers}
            handleLoginUserTop={handleLoginUserTop}
            handleReadAll={handleReadAll}
            unReadGroupClick={unReadGroupClick}
            handleUnReadGroupType={handleUnReadGroupType}
            handleUnReadOtherDataTimeChange={handleUnReadOtherDataTimeChange}
            onChangeSearchQuery={onChangeSearchQuery}
            handleIsOpenAutoReplyConfigChange={handleIsOpenAutoReplyConfigChange}
            getAutoReplyIsOpen={getAutoReplyIsOpen}
            onImageError={onImageError}
          />
        )
      }

      {
        tgUserList.list.length > 0
          ? (
            isIndependent
              ? (
                <BuildTgUserGroup
                  key="build-tg-user-group"
                  groups={tgUserList.groupedUsers}
                  accordions={accordions}
                  tgUserGroupRef={tgUserGroupRef}
                  folderTitleRefs={folderTitleRefs}
                  openAccordion={openAccordion}
                  independent={isIndependent}
                  workData={workData}
                  listImmobilization={listImmobilization}
                  content={content}
                  onMenuHandler={onMenuHandler}
                  handleUserClick={handleUserClick}
                />
              ) : (
                <BuildTgUserList
                  key="build-tg-user-list"
                  list={tgUserList.list}
                  independent={!!isIndependent}
                  workData={workData}
                  listImmobilization={listImmobilization}
                  content={content}
                  onMenuHandler={onMenuHandler}
                  handleUserClick={handleUserClick}
                />
              )
          ) : (
            <BuildLoadingView
              key="no-results"
              isShow={
                isIndependent
                  ? isIndependentReady
                  : !!tgUserSet.length && tgUserList && !tgUserList.list.length
              }
              content={content}
              tgUsersGroupsFilter={tgUsersGroupsFilter}
              tgUsersFilter={tgUsersFilter}
            />
          )

      }
      {
        isIndependent && (
          <GroupButton
            key="TgUsersListGroupButton"
            groups={tgUserList.groupedUsers}
            onScrollTo={onScrollToFolder}
          />
        )
      }
    </InfiniteScroll>

  );
};
export default memo(BuildTgUsersListView);
