import React, { memo } from '../../../lib/teact/teact';

import type { CustomPeer } from '../../../types';
import type { IconName } from '../../../types/icons';

import buildClassName from '../../../util/buildClassName';

import useOldLang from '../../../hooks/useOldLang';

import Icon from '../../common/icons/Icon';

import './SelectedChip.scss';

type OwnProps<T = undefined> = {
  customPeer?: CustomPeer;
  icon?: string | IconName;
  title?: string;
  isMinimized?: boolean;
  canClose?: boolean;
  className?: string;
  fluid?: boolean;
  clickArg?: T;
  onClick?: (arg: T) => void;
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
const SelectedChip = <T,>({
  icon,
  title,
  isMinimized,
  canClose,
  clickArg,
  customPeer,
  className,
  fluid,
  onClick,
}: OwnProps<T>) => {
  const lang = useOldLang();

  const fullClassName = buildClassName(
    'PickerSelectedItem',
    className,
    (customPeer?.isAvatarSquare) && 'square-avatar',
    isMinimized && 'minimized',
    canClose && 'closeable',
    fluid && 'fluid',
  );

  return (
    <div
      className={fullClassName}
      onClick={() => onClick?.(clickArg!)}
      title={title}
      dir={lang.isRtl ? 'rtl' : undefined}
    >
      <div className={buildClassName('Avatar', ' size-large ', 'peer-color-4')}>
        <div className="inner">
          {icon
            ? (
              <img
                src={icon}
                alt=""
                className="pick-image"
              />
            )
            : (title || '').trim().substring(0, 1)}
        </div>
      </div>
      {!isMinimized && (
        <div className="item-name" dir="auto">
          {title}
        </div>
      )}
      {canClose && (
        <div className="item-remove">
          <Icon name="close" />
        </div>
      )}
    </div>
  );
};

export default memo(SelectedChip);
