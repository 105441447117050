import type { FC } from '../../lib/teact/teact';
import React, { useCallback, useMemo } from '../../lib/teact/teact';

import { LeftColumnContent } from '../../types';

import { WORK_GRAM_HASH, WORK_GRAM_TOKEN } from '../../config';

import useHistoryBack from '../../hooks/useHistoryBack';

import TgUsersList from '../left/main/TgUsersList';
import Button from '../ui/Button';

import './AppInactive.scss';
import '../../assets/fonts/roboto.css';
import '../../styles/index.scss';

import appInactivePath from '../../assets/app-inactive.png';

export type AppInactiveProps = {
  isNotAuth?: boolean;
};

const AppInactive: FC<AppInactiveProps> = ({ isNotAuth }) => {
  const handleReload = useCallback(() => {
    if (isNotAuth) window.open(WORKGRAM_URL, '_blank');
    else window.location.reload();
  }, [isNotAuth]);

  useHistoryBack({
    isActive: true,
    onBack: handleReload,
    shouldResetUrlHash: true,
  });
  const isTgUserError = useMemo(() => {
    const workGramHash = sessionStorage.getItem(WORK_GRAM_HASH);
    const workGramToken = sessionStorage.getItem(WORK_GRAM_TOKEN);
    return !workGramHash && workGramToken;
  }, []);

  return (
    <div id="AppInactive">
      {isNotAuth && isTgUserError && (
        <TgUsersList
          isShowExpansion={false}
          className="TgUsersList"
          isIndependent
          content={LeftColumnContent.SwitchTgUser2}
        />
      )}
      <div className="content">
        <img src={appInactivePath} alt="" />
        {
          isTgUserError ? (
            <><h3 className="title">Such error, account exception</h3>
              <div className="description">
                This account is abnormal and cannot enter the chat room.
                <br />
                Please go to WorkGram to check the status of this account or
                switch to another account to continue using the chat room.
              </div>
              <div className="actions">
                <Button isText ripple onClick={handleReload}>
                  Go to WorkGram
                </Button>
              </div>
            </>
          )

            : isNotAuth ? (
              <><h3 className="title">Such error, access denied</h3>
                <div className="description">
                  This chat room is only supported by WorkGram.
                  <br />
                  Please reload this page to continue using this tab or close it.
                </div>
                <div className="actions">
                  <Button isText ripple onClick={handleReload}>
                    Go to WorkGram
                  </Button>
                </div>
              </>
            ) : (
              <><h3 className="title">Such error, many tabs</h3>
                <div className="description">
                  Telegram supports only one active tab with the app.
                  <br />
                  Please jump to WorkGram chat room to access this page.
                </div>
                <div className="actions">
                  <Button isText ripple onClick={handleReload}>
                    Reload app
                  </Button>
                </div>
              </>
            )
        }

      </div>
    </div>
  );
};

export default AppInactive;
