import type { TranslateReq, TranslateRes } from '../types';

import requestServer from './axios';

/**
 * 聊天室 消息翻译
 * @param data
 * @returns
 */

export const getTranslates = (data: TranslateRes) => {
  return requestServer.post<TranslateReq>('/api/translate', data)
    .then((res) => res);
};
