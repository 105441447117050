interface DateTimeFormatParts {
  YYYY: string;
  MM: string;
  DD: string;
  HH: string;
  mm: string;
  ss: string;
}

  type DateFormatString = 'YYYY' | 'MM' | 'DD' | 'HH' | 'mm' | 'ss';

export const formatDateTime = (dateInput: Date | number, format: string): string => {
  const date: Date = typeof dateInput === 'number' ? new Date(dateInput) : dateInput;

  const tokens: DateTimeFormatParts = {
    YYYY: date.getFullYear().toString(),
    MM: (date.getMonth() + 1).toString().padStart(2, '0'),
    DD: date.getDate().toString().padStart(2, '0'),
    HH: date.getHours().toString().padStart(2, '0'),
    mm: date.getMinutes().toString().padStart(2, '0'),
    ss: date.getSeconds().toString().padStart(2, '0'),
  };

  return format.replace(/YYYY|MM|DD|HH|mm|ss/g, (substring: string) => {
    const match = substring as DateFormatString;
    return tokens[match];
  });
};
