import type { GlobalState } from '../types';

export function updateWorkGramInfo<T extends GlobalState>(
  global: T, workGramUserCommonInfo: Partial<GlobalState['workGramUserCommonInfo']>,
):T {
  return {
    ...global,
    workGramUserCommonInfo: {
      ...global.workGramUserCommonInfo,
      ...workGramUserCommonInfo,
    },
  };
}
