import type {
  GroupScriptListParams, GroupScriptModal, ResponseData, ResponseListData, ScriptRes, ScriptResReq, SendLandingPageInp,
  TgDataListModel,
  TgDataListParams,
} from '../types';

import requestServer from './axios';

/**
 * 话术
 * @param data
 * @returns
 */

export const getAllScript = (
  type: string,
  params?: ScriptRes,
) => requestServer.get<ScriptResReq>(`/admin/${type}/sysScript/list`, {
  params,
});

/**
 * 发送落地页话术
 * @param data
 * @returns
 */
export const sendLandingPage = (data: SendLandingPageInp) => requestServer
  .post<ScriptResReq>('/tg/arts/sendLandingPage', data);

/**
 * 获取所有话术分组
 */
export const getScriptGroupList = (params:GroupScriptListParams) => requestServer
  .get<ResponseData<ResponseListData<GroupScriptModal>>>('/admin/1/scriptGroup/v3/list', { params });

/**
 * 获取所有话术分组
 */
export const getTgDataList = (params:TgDataListParams) => requestServer
  .get<ResponseData<ResponseListData<TgDataListModel>>>('/tg/tgData/list', { params });
