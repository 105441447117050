import type { FC } from '../../../../lib/teact/teact';
import React, {
  memo, useCallback,
} from '../../../../lib/teact/teact';
import { withGlobal } from '../../../../global';

import type { MemberInfo } from '../../../../api/types';
import type { WorkGramUserData } from '../../../../util/praseWorkGramHash';
import { LeftColumnContent } from '../../../../types';

import { selectTabState } from '../../../../global/selectors';
import buildClassName, { createClassNameBuilder } from '../../../../util/buildClassName';

import ListItem from '../../../ui/ListItem';
import RippleEffect from '../../../ui/RippleEffect';

import './styles/TgUsersListGroupButton.scss';

type OwnProps = {
  item:MemberInfo | null;
  independent:boolean;
  workData:WorkGramUserData | null;
  content:LeftColumnContent;
  onClick?:(e:React.MouseEvent<HTMLElement>)=>void;
  handleMemberClick?:(id:number)=>void;
  onImageError?:(item:MemberInfo, independent:boolean)=>void;
};
type StateProps = {
  memberId?:number;
};

const cn = createClassNameBuilder('Avatar');
cn.media = cn('media');
cn.icon = cn('icon');

const BuildMemberItem: FC<OwnProps & StateProps> = ({
  item,
  independent = false,
  workData,
  memberId: globalMemberId,
  content,
  onClick,
  handleMemberClick,
  onImageError,
}) => {
  const onClickItem = useCallback((e:React.MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick(e);
      return;
    }
    if (!independent && item?.id) {
      handleMemberClick?.(item?.id);
    }
  }, [handleMemberClick, independent, item, onClick]);

  return (
    <ListItem
      key={item?.id}
      className={
        buildClassName(
          'chat-item-clickable',
          'contact-list-item',
          independent && 'independent-item',
          !independent && (workData?.params?.memberId || globalMemberId) === item?.id && 'selected',
        )
      }
      buttonClassName={buildClassName(independent && 'no-focus')}
      isStatic={independent && content === LeftColumnContent.SwitchTgUser2}
      onClick={onClickItem}
    >
      <>
        <div className={buildClassName('status', 'status-clickable')}>
          <div className={buildClassName('Avatar', ' size-large ', 'peer-color-4')}>
            <div className="inner">
              {item?.avatar
                ? (
                  <img
                    src={item?.avatar}
                    className={buildClassName(cn.media, 'avatar-media')}
                    alt=""
                    onError={() => onImageError?.(item, independent)}
                  />
                )
                : (item?.realName || item?.firstName || item?.username || '').trim().substring(0, 2)}
            </div>
          </div>
        </div>
        <div className="info">
          <div className="info-header">
            <div className="name">
              {item?.realName || `${item?.firstName} ${item?.lastName}`}
            </div>
            {item?.roleName && <div className="role">{item?.roleName}</div>}
          </div>

          <div className="identification">
            {`@${item?.username}`}
          </div>
        </div>
        <RippleEffect />
      </>
    </ListItem>

  );
};
export default memo(withGlobal<OwnProps>(
  (global): StateProps => {
    const tabState = selectTabState(global);
    const {
      workGramSearch,
    } = tabState.globalSearch;

    return {
      memberId: workGramSearch?.memberId,

    };
  },
)(BuildMemberItem));
