import type { MemberInfo, ResponseData } from '../types';

import requestServer from './axios';

/**
 * 获取成员列表
 */
export const getMemberViewInfo = (id:number) => {
  return requestServer.get<ResponseData<MemberInfo>>('/admin/member/view', { params: { id } });
};
