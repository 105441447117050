import type { ResponseData, ResponseListData } from '../types';

import requestServer from './axios';

export interface TgFoldersEditParams {
  folderName:string;
  id?:string;
  accounts?:number[];
}
export interface TgFoldersListParams {
  page:number;
  pageSize:number;
}
export interface TgFolder {
  id: string;
  folderName: string;
  memberCount?: number;
}

export interface TgFoldersTransForm {
  label: string;
  title: string;
  value: string;
  memberCount: number;
}

/** 新增分组 */
export const PostTgFoldersEdit = (params:TgFoldersEditParams) => {
  return requestServer.post<ResponseData<any>>('tg/tgFolders/edit', params)
    .then((res) => res).catch((err) => err.response.data);
};

/** 获取分组列表 */
export const GetTgFoldersList = (params:TgFoldersListParams) => {
  return requestServer.get<ResponseData<ResponseListData<TgFolder>>>('/tg/tgFolders/list', { params })
    .then((res) => res);
};
