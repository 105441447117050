export interface ScriptResType {
  type: 1 | 2 | 3;
}
/**
 * hotgo.api.api.translate.TranslateReq
 */
export interface ScriptRes {
  /**
     * 话术内容
     */
  content?: string;
  /**
    * 创建时间
    */
  createdAt?: string[];
  /**
    * 分组
    */
  groupId?: number;
  /**
    * 排序字段
    */
  order?: string;
  /**
    * 当前页码
    */
  page?: number;
  /**
    * 每页数量
    */
  pageSize?: number;
  /**
    * 话术分类(1文本 2图片3剧本4视频)
    */
  scriptClass?: number;
  /**
    * 快捷指令
    */
  short?: string;
  /**
    * 分组类型
    */
  type?: number;
  [property: string]: any;
}
export interface ScriptResReq {
  /**
     * 状态码
     */
  code?: number;
  /**
     * 数据集
     */
  data?: ScriptData;
  error?: { [key: string]: any };
  /**
     * 提示消息
     */
  message?: string;
  /**
     * 业务编码
     */
  svcCode?: string;
  /**
     * 服务器时间戳
     */
  timestamp?: number;
  /**
     * 链路ID
     */
  traceID?: string;
  [property: string]: any;
}

/**
 * 数据集
 */
export interface ScriptData {
  /**
     * 数据列表
     */
  list?: SysScriptListItem[];
  /**
    * 排序字段
    */
  order?: string;
  /**
    * 当前页码
    */
  page?: number;
  /**
    * 分页个数
    */
  pageCount?: number;
  /**
    * 每页数量
    */
  pageSize?: number;
  /**
    * 数据总行数
    */
  totalCount?: number;
  [property: string]: any;
}
/**
 * hotgo.internal.model.input.scriptin.SysScriptListModel
 */
export interface SysScriptListItem {
  /**
    * 文件caption
    */
  caption?: string;
  /**
    * 备注
    */
  comment?: string;
  contactVcard?: { [key: string]: any };
  /**
    * 话术内容
    */
  content?: string;
  /**
    * 创建时间
    */
  createdAt?: string;
  /**
    * 分组
    */
  groupId?: number;
  /**
    * 分组名称
    */
  groupName?: string;
  /**
    * ID
    */
  id?: number;
  /**
    * 上次使用时间
    */
  lastUseAt?: string;
  /**
    * 用户ID
    */
  memberId?: number;
  /**
    * 用户名称
    */
  memberUserName?: string;
  /**
    * 组织ID
    */
  orgId?: number;
  /**
    * 话术分类
    */
  scriptClass?: ScriptClass;
  /**
    * 快捷指令
    */
  short?: string;
  /**
    * 类型
    */
  type?: number;
  /**
    * 修改时间
    */
  updatedAt?: string;
  [property: string]: any;
}

export interface SysScriptListItemVO extends SysScriptListItem {
  id: number;
}

export enum ScriptClass {
  TEXT = 1,
  CALLING_CARD = 2,
  SCRIPT = 3,
  FILE = 4,
  LANDING_PAGE = 5,
}

export interface SendLandingPageInp {
  scriptId: number;
  sendAccount: string;
  sendTgId?: number;
  receiverTgId?: string;
  receiverHash?: string;
}

export interface GroupScriptListParams {
  name?:string;
  page?: number;
  pageSize?: number;
}

export interface GroupScriptModal {
  /**
     * 创建时间
     */
  createdAt?: string;
  /**
     * ID
     */
  id?: number;
  /**
     * 用户ID
     */
  memberId?: number;
  /**
     * 用户名
     */
  memberUserName?: string;
  /**
     * 话术组名
     */
  name?: string;
  /**
     * 组织
     */
  orgId?: number;
  /**
     * 话术数量
     */
  scriptCount?: number;
  /**
     * 分组类型
     */
  type?: number;
  /**
     * 修改时间
     */
  updatedAt?: string;
}

export interface TgDataListParams {
  /**
     * 备注
     */
  comment?: string;
  /**
     * 创建时间
     */
  createdAt?: string[];
  /**
     * 名称
     */
  dataName?: string;
  /**
     * 数据类型
     */
  dataType?: number;
  /**
     * 是否倒序
     */
  desc?: boolean;
  /**
     * 排序字段
     */
  field?: string;
  /**
     * ID
     */
  id?: number;
  /**
     * memberId
     */
  memberId?: number;
  /**
     * 当前页码
     */
  page?: number;
  /**
     * 每页数量
     */
  pageSize?: number;
  /**
     * 数据来源
     */
  type?: number;
  /**
     * workGram邮箱
     */
  workGramEmail?: string;
}

export interface TgDataListModel {
  /**
     * 备注
     */
  comment?: string;
  /**
     * 创建时间
     */
  createdAt?: string;
  /**
     * 名称
     */
  dataName?: string;
  /**
     * 数据类型
     */
  dataType?: number;
  /**
     * ID
     */
  id?: number;
  /**
     * 我的仓库
     */
  is_mine?: number;
  /**
     * 是否工单共享
     */
  isTaskShare?: number;
  /**
     * 用户ID
     */
  memberId?: number;
  /**
     * 创建人名称
     */
  memberUsername?: string;
  /**
     * 组织ID
     */
  orgId?: number;
  /**
     * 共享公司名称
     */
  orgName?: string;
  /**
     * 共享账号名称
     */
  shareMemberEmail?: string;
  /**
     * 共享账号id
     */
  shareMemberId?: number;
  /**
     * 共享公司id
     */
  shareOrgId?: number;
  /**
     * 共享关联的工单id
     */
  shareTaskId?: number;
  /**
     * 共享工单名称
     */
  shareTaskName?: string;
  /**
     * 数据总数
     */
  totalData?: number;
  /**
     * 已使用数
     */
  totalUse?: number;
  /**
     * 数据来源
     */
  type?: number;
  /**
     * 修改时间
     */
  updatedAt?: string;
}
