import { v4 as uuidv4 } from 'uuid';
import type { FC } from '../../../lib/teact/teact';
import React, {
  memo, useCallback, useEffect, useState,
} from '../../../lib/teact/teact';

import type { HighModeDataProps } from './WrapperAutoModel';

import { cloneDeep } from '../../../util/iteratees';

import Button from '../Button';
import Select from '../Select';
import TextArea from '../TextArea';
import InputTextWrapper from './InputTextWrapper';

import '../Modal.scss';
import styles from './AutoResponseModel.module.scss';

type OwnProps = {
  replayContent?:HighModeDataProps[];
  onContentChange: (content: HighModeDataProps[]) => void;
  setHighReplayContentConfigStatus:(value: boolean) => void;
  highReplayContentConfigStatus?:boolean;
};

const ModeTypeOptions = [
  { value: 'includeKeywords', label: '包含关键词', subLabel: '包含关键词' },
  { value: 'notIncludeKeywords', label: '不包含关键词', subLabel: '不包含关键词' },
  // { value: 'read', label: '已读', subLabel: '已读' },
  // { value: 'unread', label: '未读', subLabel: '未读' },
];
const defaultKeyWordsContent = {
  keyWords: 'includeKeywords',
  keyContent: '',
  uuid: uuidv4(),
};
const defaultContent = {
  keyWordsContent: [
    defaultKeyWordsContent,
  ],
  content: '',
  uuid: uuidv4(),
};
const initContent = [
  defaultContent,
];
const AdvancedModeConfig: FC<OwnProps> = ({
  replayContent,
  onContentChange,
  highReplayContentConfigStatus,
  setHighReplayContentConfigStatus,
}) => {
  const [content, setContent] = useState<HighModeDataProps[]>(cloneDeep(initContent));
  useEffect(() => {
    if (replayContent && replayContent?.length > 0) {
      // eslint-disable-next-line no-console
      console.log('handleAddOrDeletekeyWordsContent', 'replayContent', replayContent);
      setContent(replayContent);
      setHighReplayContentConfigStatus(true);
    }
  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [replayContent]);

  const handleSelectChange = useCallback((
    e: React.ChangeEvent<HTMLSelectElement>,
    contentUUID :string,
    keyWordsUUID:string,
  ) => {
    // eslint-disable-next-line no-console
    console.log(e.target.value, 'select', 'handleAddOrDeletekeyWordsContent');
    setContent((prev) => {
      return prev.map((item) => {
        if (item.uuid === contentUUID) {
          const newKeyWordsContent = item.keyWordsContent.map((i) => {
            if (i.uuid === keyWordsUUID) {
              return {
                ...i,
                keyWords: e.target.value,
              };
            }
            return i;
          });
          return { ...item, keyWordsContent: newKeyWordsContent };
        }
        return item;
      });
    });
    setHighReplayContentConfigStatus(false);
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);
  const handleInputTextWrapperChange = useCallback((
    e: React.ChangeEvent<HTMLInputElement>,
    contentUUID :string,
    keyWordsUUID:string,
  ) => {
    // eslint-disable-next-line no-console
    console.log(e.target.value, 'input', 'handleAddOrDeletekeyWordsContent');
    setContent((prev) => {
      return prev.map((item) => {
        if (item.uuid === contentUUID) {
          const newKeyWordsContent = item.keyWordsContent.map((i) => {
            if (i.uuid === keyWordsUUID) {
              return {
                ...i,
                keyContent: e.target.value,
              };
            }
            return i;
          });
          return { ...item, keyWordsContent: newKeyWordsContent };
        }
        return item;
      });
    });
    if (!highReplayContentConfigStatus) {
      setHighReplayContentConfigStatus(false);
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [highReplayContentConfigStatus]);
  const handleAddkeyWordsContent = useCallback((contentUUID :string) => {
    // eslint-disable-next-line no-console
    console.log(contentUUID, 'add', 'handleAddOrDeletekeyWordsContent');
    setContent((prev) => prev?.map((item) => {
      if (item.uuid === contentUUID) {
        item.keyWordsContent = [
          ...item.keyWordsContent,
          { ...defaultKeyWordsContent, uuid: uuidv4() },
        ];
      }
      return item;
    }));
    setHighReplayContentConfigStatus(false);
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);
  const handleDeletekeyWordsContent = useCallback((contentUUID :string, keyWordsUUID:string) => {
    // eslint-disable-next-line no-console
    console.log(contentUUID, keyWordsUUID, 'delete', 'handleAddOrDeletekeyWordsContent');
    setContent((prev) => prev?.map((item) => {
      if (item.uuid === contentUUID) {
        const newKeyWordsContent = item.keyWordsContent?.filter((i) => i.uuid !== keyWordsUUID);
        return { ...item, keyWordsContent: newKeyWordsContent };
      }
      return item;
    }));
    setHighReplayContentConfigStatus(false);
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);
  const handleTextAreaChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>, contentUUID :string) => {
    setContent((prev) => {
      return prev.map((item) => {
        if (item.uuid === contentUUID) {
          return { ...item, content: e.target.value };
        }
        return item;
      });
    });
    if (!highReplayContentConfigStatus) {
      setHighReplayContentConfigStatus(false);
    }
  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [highReplayContentConfigStatus]);
  const handleDeleteContent = useCallback((contentUUID :string) => {
    setContent((prev) => {
      const newKeyWordsContent = prev?.filter((i) => i.uuid !== contentUUID);
      return newKeyWordsContent;
    });
    setHighReplayContentConfigStatus(false);
  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);
  const renderAutoConfig = useCallback((item:HighModeDataProps, count:number) => (
    <div className={styles.renderContent}>
      <div className={styles.renderTg}>
        {
          item.keyWordsContent.map((itemKey) => (
            <div className={styles.renderTime}>
              <Select
                label="关键词"
                value={itemKey.keyWords || ''}
                hasArrow={Boolean(false)}
                id="keyWords"
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(e) => handleSelectChange(e, item.uuid, itemKey.uuid)}
              >
                {
                  ModeTypeOptions.map(({ value, label }) => (
                    <option
                      key={value}
                      value={value}
                      className="keyWords"
                      selected={itemKey.keyWords === value}
                    >
                      {label}
                    </option>
                  ))
                }
              </Select>
              <InputTextWrapper
                value={itemKey?.keyContent || ''}
                label="关键词内容"
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(e) => handleInputTextWrapperChange(e, item.uuid, itemKey.uuid)}
              />
              <div className={styles.btns}>
                <Button
                  round
                  size="smaller"
                  color="translucent"
                  ariaLabel="新增"
                  className="extra-spacing"
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => handleAddkeyWordsContent(item.uuid)}
                >
                  <i className="icon icon-add" />
                </Button>
                <Button
                  round
                  size="smaller"
                  color="translucent"
                  ariaLabel="删除"
                  className="extra-spacing"
                  disabled={item.keyWordsContent?.length === 1}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => handleDeletekeyWordsContent(item.uuid, itemKey.uuid)}
                >
                  <i className="icon icon-delete" />
                </Button>
              </div>
            </div>
          ))
        }
        <TextArea
          value={item.content}
          label="回复内容"
          maxLength={255}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={(e) => handleTextAreaChange(e, item.uuid)}
          maxLengthIndicator={item.content ? (255 - item.content.length || 0).toString() : undefined}
        />
      </div>
      {count > 1 && (
        <div className={styles.renderDelete} onClick={() => handleDeleteContent(item.uuid)}>
          删除
        </div>
      )}
    </div>
  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  ), []);

  const handleAddContent = useCallback(() => {
    setContent((prev) => [...prev, { ...defaultContent, uuid: uuidv4() }]);
    setHighReplayContentConfigStatus(false);
  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);
  const handleSaveContent = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('handleAddOrDeletekeyWordsContent', 'replayContent', content);
    onContentChange(content);
    setHighReplayContentConfigStatus(true);
  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [content]);
  // const handleEditContent = useCallback(() => {
  //   // eslint-disable-next-line no-console
  //   console.log('handleAddOrDeletekeyWordsContent', 'handleEditContent', content);
  //   onContentChange(content);
  // // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  // }, [content]);
  return (
    <div>
      {
        content.map((item) => renderAutoConfig(item, content.length))
      }
      <div className={styles.btnGroup}>
        <Button
          type="button"
          color="secondary"
          className={`confirm-dialog-button active ${styles.addBtn}`}
          onClick={handleAddContent}
        >
          添加新回复内容
        </Button>
        {/* <Button
          type="button"
          color="secondary"
          className={`confirm-dialog-button active ${styles.addBtn}`}
          onClick={handleEditContent}
        >
          编辑关键词
        </Button> */}
        <Button
          type="button"
          color="secondary"
          className={`confirm-dialog-button active ${styles.addBtn}`}
          onClick={handleSaveContent}
          disabled={highReplayContentConfigStatus}
        >
          保存全部回复内容
        </Button>
      </div>
    </div>
  );
};

export default memo(AdvancedModeConfig);
