import { type FC } from 'react';
import {
  memo, useCallback, useEffect,
  useMemo, useState,
} from '../../../lib/teact/teact';
import React from '../../../lib/teact/teactn';
import { getActions, withGlobal } from '../../../global';

import type { ResponseListData } from '../../../api/types';
import type { WgMemberInfo } from '../../../api/types/info';
import type { MassTaskListRequest, TgMassTaskListModel } from '../../../api/types/massTexting';
import type { ListItemType } from '../../ui/PeerPickerModel/PeerPickerSelectContainer';
import type { HandlePeerPickerChangeParams } from '../../ui/PeerPickerModel/types';

import { debounce } from '../../../util/schedulers';
import { getMemberInfo } from '../../../api/request/info';
import { getShareLinkDomain, getTgMassTaskList, massTaskReset } from '../../../api/request/messTexting';

import useLastCallback from '../../../hooks/useLastCallback';

import Pagination from '../../ui/Pagination';
import PeerPickerModel from '../../ui/PeerPickerModel';

type OwnProps = {};

type StateProps = {
  isOpen: boolean;

};

const MassTextingDetailModal:FC<OwnProps & StateProps> = ({ isOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { updateMassTexting, showNotification } = getActions();
  const [shareLink, setShareLink] = useState('');
  const [requestParams, setRequestParams] = useState<MassTaskListRequest>({
    page: 1,
    pageSize: 20,
  });
  const [massTaskListData, setMassTaskListData] = useState<ResponseListData<TgMassTaskListModel>>();
  const [selectTgDataMap, setSelectTgDataMap] = useState< Map<string, ListItemType<TgMassTaskListModel>>>(new Map());
  const handleSelectedTgDataIdsChange = useLastCallback(
    (newSelectedIds:Map<string, ListItemType<TgMassTaskListModel>>) => {
      setSelectTgDataMap(newSelectedIds);
    },
  );

  const [memberInfo, setMemberInfo] = useState<WgMemberInfo>();

  const tgDataOptions = useMemo<ListItemType<TgMassTaskListModel>[]>(() => {
    return massTaskListData?.list?.map((item) => ({
      id: (item.id),
      name: item.taskName,
      data: item,

    })) as ListItemType<TgMassTaskListModel>[];
  }, [massTaskListData?.list]);

  const fetchData = debounce(async (params: MassTaskListRequest = requestParams) => {
    setIsLoading(true);
    try {
      let memberId = memberInfo?.id;
      if (!memberInfo) {
        const infoRes = await getMemberInfo();
        if (infoRes.code === 0 && infoRes.data) {
          memberId = infoRes.data.id;
          setMemberInfo(infoRes.data);
        }
      }
      const res = await getTgMassTaskList({ ...params, memberId });
      if (res.code === 0 && res.data) {
        setRequestParams(params);
        setMassTaskListData(res.data);
      }
    } catch (e) {
      showNotification({ message: '请求失败' });
    } finally {
      setIsLoading(false);
    }
  }, 300, false, true);

  useEffect(() => {
    if (isOpen) {
      if (!massTaskListData && !isLoading) {
        fetchData();
      }
      if (!shareLink) {
        getShareLinkDomain().then(
          (res) => {
            if (res.code === 0) {
              setShareLink(res?.data?.domain ?? '');
            }
          },
        );
      }
    }
  }, [fetchData, isLoading, isOpen, massTaskListData, shareLink]);

  const onChangePage = useCallback((page: number, pageSize: number) => {
    setRequestParams((pre) => {
      const newParams = { ...pre, page, pageSize };
      fetchData(newParams);
      return newParams;
    });
  }, [fetchData]);

  const onSearchTgDataList = useCallback((value: string) => {
    const newParams = {
      ...requestParams, page: 1, taskName: value,
    };
    fetchData(newParams);
  }, [fetchData, requestParams]);
  const onClose = useCallback(() => {
    setRequestParams({
      page: 1,
      pageSize: 20,
    });
    setMassTaskListData(undefined);
    updateMassTexting({ isShowDetailModal: false });
  }, []);
  const onItemClick = useCallback(async (
    item: HandlePeerPickerChangeParams<TgMassTaskListModel, TgMassTaskListModel>,
  ) => {
    if ('state' in item) return;
    await massTaskReset({ taskId: Number(item.data.id), password: '123456' });
    if (shareLink)window.open(`${shareLink}tgMassTaskData?taskId=${item.data.id}&password=123456`);
  }, [shareLink]);
  return (
    <PeerPickerModel
      isLoading={isLoading}
      title="群发任务详情列表"
      needChip={false}
      type="nothing"
      isOpen={isOpen}
      onClose={onClose}
      confirmButtonText={undefined}
      listData={tgDataOptions}
      initialSelectedMap={selectTgDataMap}
      onSelectedIdsConfirmed={handleSelectedTgDataIdsChange}
      onItemClick={onItemClick}
      // eslint-disable-next-line react/jsx-no-bind
      renderBaseItem={(item) => (
        <div className="script-item">
          <div className="script-name">{item.name} </div>
        </div>
      )}
      onSearchChange={onSearchTgDataList}
      FooterChildren={(
        <Pagination
          total={massTaskListData?.totalCount ?? 0}
          page={requestParams?.page ?? 1}
          pageSize={requestParams?.pageSize ?? 20}
          onPageChange={onChangePage}
        />
      )}
    />
  );
};

export default memo(withGlobal<OwnProps>(
  (global): StateProps => {
    return {
      isOpen: global.massTexting.isShowDetailModal,
    };
  },
)(MassTextingDetailModal));
