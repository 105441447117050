import type { ChangeEvent } from 'react';
import type { FC } from '../../../../lib/teact/teact';
import React, {
  memo, useCallback, useEffect, useState,
} from '../../../../lib/teact/teact';
import { getActions } from '../../../../lib/teact/teactn';

import type { TgFolder } from '../../../../api/request/Folder';

import buildClassName from '../../../../util/buildClassName';

import useLastCallback from '../../../../hooks/useLastCallback';
import useOldLang from '../../../../hooks/useOldLang';

import Button from '../../../ui/Button';
import Loading from '../../../ui/Loading';
import Modal from '../../../ui/Modal';
import Select from '../../../ui/Select';

export type OwnProps = {
  isOpen: boolean;
  folderList:TgFolder[];
  onClose: (folderInfo?:TgFolder) => Promise<void>;
  onCloseAnimationEnd?: () => void;
};

const MoveFolderModal: FC<OwnProps> = ({
  isOpen,
  folderList,
  onClose,
  onCloseAnimationEnd,
}) => {
  const { showNotification } = getActions();
  const [folderInfo, setFolderInfo] = useState<TgFolder>();
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = useLastCallback(async () => {
    if (!folderInfo?.id) {
      showNotification({
        message: '请选择分组',
      });
      return;
    }
    setIsLoading(true);
    await onClose(folderInfo);
    setIsLoading(false);
  });

  const lang = useOldLang();

  const handleSelectChange = useCallback((e:ChangeEvent<HTMLSelectElement>) => {
    const info = folderList.find((item) => item.id === e.target.value);
    setFolderInfo(info);
  }, [folderList]);

  useEffect(() => {
    if (isOpen) {
      setFolderInfo(folderList?.[0] || undefined);
    }
  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onEnter={isOpen ? handleConfirm : undefined}
      onCloseAnimationEnd={onCloseAnimationEnd}
      className={buildClassName('narrow')}
      title="移动账号"
    >
      <Select
        label="分组"
        onChange={handleSelectChange}
        value={folderInfo?.id || ''}
        hasArrow={Boolean(true)}
        id="billing-country"
        tabIndex={0}
      >
        {
          folderList.map(({ id, folderName }) => (
            <option
              key={id}
              value={id}
              className="county-item"
              selected={folderInfo?.id === id}
            >
              {folderName}
            </option>
          ))
        }
      </Select>

      <div className="dialog-buttons">

        {isLoading ? <Loading key="loading" />
          : (
            <Button
              color="danger"
              className="confirm-dialog-button"
              isText
              onClick={handleConfirm}
            >
              确认
            </Button>
          )}

        <Button
          className="confirm-dialog-button"
          isText
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => onClose()}
        >{lang('Cancel')}
        </Button>
      </div>
    </Modal>
  );
};

export default memo(MoveFolderModal);
