import { useCallback, useEffect, useState } from '../lib/teact/teact';

import type { EmitData } from '../lib/workGramClient/types';
import type { WorkGramWebSocketConfig } from '../util/praseWorkGramHash';

import {
  WORK_GRAM_HASH, WORK_GRAM_IV, WORK_GRAM_TOKEN, WORK_GRAM_WEB_SOCKET_CONFIG,
} from '../config';
import WorkGramClient from '../lib/workGramClient';
import praseWorkGramHash from '../util/praseWorkGramHash';

const useWorkGramClient = () => {
  // eslint-disable-next-line no-null/no-null
  const [workGramClient, setWorkGramClient] = useState<WorkGramClient | null>(null);
  const handleOpen = useCallback((data:EmitData<'ping', undefined>) => {
    if (data.code === 0) {
      setWorkGramClient(WorkGramClient.Instance);
    }
  }, []);

  WorkGramClient.connect();

  useEffect(() => {
    if (!workGramClient) {
      if (WorkGramClient.Instance && WorkGramClient.Socket) {
        setWorkGramClient(WorkGramClient.Instance);
        return;
      }
      try {
        const hash = sessionStorage.getItem(WORK_GRAM_HASH);
        const iv = sessionStorage.getItem(WORK_GRAM_IV);
        const workGramWebSocketConfig = sessionStorage.getItem(WORK_GRAM_WEB_SOCKET_CONFIG);

        // eslint-disable-next-line no-null/no-null
        const workData = iv && hash ? praseWorkGramHash(JSON.parse(iv), JSON.parse(hash)) : null;
        const socketConfig = workGramWebSocketConfig
          ? JSON.parse(workGramWebSocketConfig) as WorkGramWebSocketConfig
          // eslint-disable-next-line no-null/no-null
          : null;
        WorkGramClient.connect(
          process.env.WORKGRAM_WEB_SOCKET
          || workData?.workGramSocket
          || socketConfig?.workGramSocket,
          workData?.authorization
          || sessionStorage.getItem(WORK_GRAM_TOKEN) || '',
        );
        WorkGramClient.defaultSubscribeToEvent('ping', handleOpen);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('WorkGramClient initialization failed:', error);
      }
    }
  }, [handleOpen, workGramClient]);

  return workGramClient ?? WorkGramClient.Instance;
};

export default useWorkGramClient;
