import React, { type FC, memo, useCallback } from '../../../../lib/teact/teact';
import { getActions } from '../../../../global';

import type { TgUserGroupInfo } from '../../../../lib/workGramClient/tgUser';

import buildClassName from '../../../../util/buildClassName';

import Icon from '../../../common/icons/Icon';
import ListItem from '../../../ui/ListItem';
import RippleEffect from '../../../ui/RippleEffect';

type OwnProps = {
  item:TgUserGroupInfo;
  accordions:string[];
  openAccordion:(id:string)=>()=>void;

};

const FolderTitle: FC<OwnProps> = ({
  item, accordions, openAccordion,
}) => {
  const { updateMassTexting } = getActions();
  const openMassTextingModel = useCallback(() => {
    updateMassTexting({ isShowModal: true });
  }, []);
  return (
    <ListItem
      className={buildClassName(
        'contact-list-item',
        'tg-user-group-title-list-item',
      )}
      withPortalForMenu
      key={`${item.folderId}`}
      contextActions={
        [{
          title: '分组一键群发',
          handler: openMassTextingModel,
        }]
      }
      // eslint-disable-next-line react/jsx-no-bind
      onClick={openAccordion(item.folderId)}

    >
      <div
        className="tg-user-group-title"
      >
        <div className="tg-user-group-name">{item.folderName}({item.list.length || 0})</div>
        <Icon
          name={accordions.includes(item.folderId) ? 'remove' : 'add'}
          className={buildClassName('ListItem-main-icon')}
        />
        <RippleEffect />
      </div>
    </ListItem>
  );
};

export default memo(FolderTitle);
