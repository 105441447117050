import type { DictOptionReq, DictOptionRes } from '../types';

import requestServer from './axios';

/**
 * 字典
 * @param data
 * @returns
 */

export const getDictDataOptions = (data?: DictOptionRes) => {
  return requestServer.get<DictOptionReq>('/admin/dictData/options', {
    params: {
      ...data,
    },
  })
    .then((res) => res)
    .catch((error) => error);
};
