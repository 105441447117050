import type { FC } from '../../lib/teact/teact';
import React from '../../lib/teact/teactn';

import './Tooltip.scss';

type OwnProps = {
  title: string;
  showIcon?:boolean;
  children?: React.ReactNode;
};
const Tooltip:FC<OwnProps> = ({ title, showIcon = true, children }) => {
  return (
    <>
      <span title={title}>
        {children}
      </span>
      {showIcon && (
        <i
          className="icon iconfont icon-wg-tooltip tooltip-icon"
          title={title}
        />
      )}

    </>
  );
};

export default Tooltip;
