import type { FC } from '../../../../lib/teact/teact';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from '../../../../lib/teact/teact';

import type { WorkGramUserData } from '../../../../util/praseWorkGramHash';
import type { UnReadGroupProps, UnReadGroupTypeValues } from '../TgUserMenuItemSetting';
import { LeftColumnContent } from '../../../../types';

import buildClassName, { createClassNameBuilder } from '../../../../util/buildClassName';
import captureKeyboardListeners from '../../../../util/captureKeyboardListeners';

import useLastCallback from '../../../../hooks/useLastCallback';

import Button from '../../../ui/Button';
import DropdownMenu from '../../../ui/DropdownMenu';
import ListItem from '../../../ui/ListItem';
import SearchInput from '../../../ui/SearchInput';
import TgUserMenuItemSetting from '../TgUserMenuItemSetting';

import './styles/TgUsersListGroupButton.scss';

type OwnProps = {
  listImmobilization:boolean;
  independent:boolean;
  workData:WorkGramUserData | null;
  loginUserTop:boolean;
  content:LeftColumnContent;
  isOpenAutoReplyConfig:boolean;
  unReadGroupValue:UnReadGroupProps;
  handleMemberClick?:(id:number)=>void;
  handleSwitchTgUsers?:()=>void;
  listImmobilizationClick:()=>void;
  handleLoginUserTop:(e:React.ChangeEvent<HTMLInputElement>)=>void;
  handleReadAll:()=>void;
  unReadGroupClick:()=>void;
  handleUnReadGroupType:(type:UnReadGroupTypeValues, value:UnReadGroupTypeValues | undefined)=>void;
  handleUnReadOtherDataTimeChange:(date?:Date | number)=>void;

  onChangeSearchQuery?: (query: string) => void;
  handleIsOpenAutoReplyConfigChange:(e:React.ChangeEvent<HTMLInputElement>)=>void;
  getAutoReplyIsOpen?:()=>void;
};
type StateProps = {
};

const cn = createClassNameBuilder('Avatar');
cn.media = cn('media');
cn.icon = cn('icon');

const BuildSearchItem: FC<OwnProps & StateProps> = ({
  isOpenAutoReplyConfig,
  loginUserTop,
  independent,
  content,
  listImmobilization,
  unReadGroupValue,
  workData,
  listImmobilizationClick,
  handleLoginUserTop,
  handleReadAll,
  unReadGroupClick,
  handleUnReadGroupType,
  handleUnReadOtherDataTimeChange,
  onChangeSearchQuery,
  handleIsOpenAutoReplyConfigChange,
  getAutoReplyIsOpen,
}) => {
  // eslint-disable-next-line no-null/no-null
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleEscKey = useLastCallback((e: KeyboardEvent) => {
    if (independent && content === LeftColumnContent.SwitchTgUser2) {
      e.preventDefault();
      searchInputRef.current?.blur();
      setSearchQuery('');
    }
  });

  useEffect(() => {
    return independent && searchQuery
      ? captureKeyboardListeners({
        onEsc: handleEscKey,
      })
      : undefined;
  }, [independent, searchQuery]);

  useEffect(() => {
    onChangeSearchQuery?.(searchQuery);
  }, [onChangeSearchQuery, searchQuery]);

  const MenuButton: FC<{ onTrigger: () => void; isOpen?: boolean }> = useMemo(() => {
    return ({ onTrigger, isOpen }) => (
      <Button
        round
        size="smaller"
        color="translucent"
        className={isOpen ? 'active' : undefined}
        onClick={onTrigger}
        ariaLabel="More actions"
      >
        <div className={buildClassName('animated-menu-icon', 'active')} />
      </Button>
    );
  }, []);

  const searchQueryChange = useCallback((value:string) => {
    setSearchQuery(value);
  }, []);

  const resetSearchQuery = useCallback(() => {
    setSearchQuery('');
  }, []);
  const oneOpen = useRef(0);
  const onMenuOpen = useCallback(() => {
    if (oneOpen.current === 0) {
      getAutoReplyIsOpen?.();
      oneOpen.current = 1;
    }
  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [oneOpen.current]);

  return (
    <ListItem
      key="search"
      isStatic
      className="search-item"
      buttonClassName={buildClassName('no-focus')}
    >
      <div>
        <DropdownMenu
          trigger={MenuButton}
          positionX="left"
          positionY="top"
          transformOriginY={0}
          onOpen={onMenuOpen}
        >
          <TgUserMenuItemSetting
            baseSetting={{
              listImmobilizationClick,
              listImmobilization,
              handleLoginUserTop,
              loginUserTop,
            }}
            otherSetting={{
              handleReadAll,
              isOpenAutoReplyConfig,
              handleIsOpenAutoReplyConfigChange,
            }}
            unReadGroups={{
              unReadGroupValue,
              unReadGroupClick,
              handleUnReadGroupType,
              handleUnReadOtherDataTimeChange,
            }}
            workData={workData}
          />
        </DropdownMenu>
      </div>
      <SearchInput
        ref={searchInputRef}
        resultsItemSelector="IndependentTgUserList"
        canClose={!!searchQuery}
        value={searchQuery}
        onChange={searchQueryChange}
        onReset={resetSearchQuery}
      />

    </ListItem>

  );
};
export default memo(BuildSearchItem);
