import type { ListItemType } from './PeerPickerSelectContainer';

/** 全选按钮状态 */
export enum AllCheckStateEnum {
  Checked,
  Unchecked,
  Indeterminate,
}
export type HandlePeerPickerChangeParams<T, K> = {
  isChecked:boolean;
  data:ListItemType<T, K> | ListItemType<K>;
  mapKey?:string;
  isScrollBottom?:boolean;
} |
{
  state:AllCheckStateEnum;
  isScrollBottom?:boolean;
};
