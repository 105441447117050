import type { TTgUserLastMessageEnum } from '../../components/left/main/TgUserList/LastMessage';
import type { WorkGramUserData } from '../../util/praseWorkGramHash';

export interface MemberListRequest {
  /**
     * 岗位编码
     */
  code?: string;
  /**
     * 创建时间
     */
  createdAt?: string[];
  /**
     * 邮箱
     */
  email?: string;
  /**
     * First Name
     */
  firstName?: string;
  /**
     * Last Name
     */
  lastName?: string;
  /**
     * 手机号
     */
  mobile?: number;
  /**
     * 岗位名称
     */
  name?: string;
  /**
     * 排序字段
     */
  order?: string;
  /**
     * 公司ID
     */
  OrgId?: number;
  /**
     * 所属公司
     */
  orgName?: string;
  /**
     * 当前页码
     */
  page?: number;
  /**
     * 每页数量
     */
  pageSize?: number;
  /**
     * 真实姓名
     */
  realName?: string;
  /**
     * 角色ID
     */
  roleId?: number;
  /**
     * 可查询自己
     */
  selectSelf?: boolean;
  /**
     * 状态
     */
  status?: number;
  /**
     * 用户名
     */
  username?: string;
}

/**
 * 成员信息
 */
export interface MemberInfo {
  /**
     * 联系地址
     */
  address?: string;
  /**
     * 头像
     */
  avatar?: string;
  /**
     * 余额
     */
  balance?: number;
  /**
     * 生日
     */
  birthday?: string;
  cash?: { [key: string]: any };
  /**
     * 城市编码
     */
  cityId?: number;
  /**
     * 创建时间
     */
  createdAt?: string;
  /**
     * 邮箱
     */
  email?: string;
  /**
     * First Name
     */
  firstName?: string;
  /**
     * 管理员ID
     */
  id: number;
  /**
     * 积分
     */
  integral?: number;
  /**
     * 邀请码
     */
  inviteCode?: string;
  /**
     * 最后活跃时间
     */
  lastActiveAt?: string;
  /**
     * Last Name
     */
  lastName?: string;
  /**
     * 关系树等级
     */
  level?: number;
  /**
     * 手机号码
     */
  mobile?: string;
  /**
     * 组织ID
     */
  orgId?: number;
  /**
     * 所属公司
     */
  orgName?: string;
  /**
     * 密码
     */
  passwordHash?: string;
  /**
     * 密码重置令牌
     */
  passwordResetToken?: string;
  /**
     * 上级管理员ID
     */
  pid?: number;
  /**
     * 真实姓名
     */
  realName?: string;
  /**
     * 备注
     */
  remark?: string;
  /**
     * 角色ID
     */
  roleId?: number;
  /**
     * 所属角色
     */
  roleName?: string;
  /**
     * 密码盐
     */
  salt?: string;
  /**
     * 性别
     */
  sex?: number;
  /**
     * 状态
     */
  status?: number;
  /**
     * 关系树
     */
  tree?: string;
  /**
     * 修改时间
     */
  updatedAt?: string;
  /**
     * 账号
     */
  username?: string;

}

export interface TgUserListRequest {
  /**
     * 账号状态
     */
  accountStatus?: number;

  /**
     * 根据未读消息排序
     * */
  orderUnread?: boolean;
  /**
     * 状态集合
     */
  accountStatusList?: string[];
  /**
     * 是否验证码登录
     */
  codeLogin?: number;
  /**
     * 创建时间
     */
  createdAt?: string[];
  /**
     * 养号时间
     */
  durationTime?: number;
  /**
     * First Name
     */
  firstName?: string;
  /**
     * 分组ID
     */
  folderId?: number;
  /**
     * 是否在线
     */
  isOnline?: number;
  /**
     * 养号任务id(用于养号任务跳转到tg_user)
     */
  keepTaskId?: number;
  /**
     * 标签
     */
  labels?: string[];
  /**
     * Last Name
     */
  lastName?: string;
  /**
     * 所属用户ID
     */
  memberId?: number;
  /**
     * 所属用户名
     */
  memberUsername?: string;
  /**
     * 排序字段
     */
  order?: string;
  /**
     * 当前页码
     */
  page?: number;
  /**
     * 每页数量
     */
  pageSize?: number;
  /**
     * 手机号
     */
  phone?: string;
  /**
     * 手机号s
     */
  phones?: string;
  /**
     * 代理地址
     */
  proxyAddress?: string;
  /**
     * 手机地区
     */
  region?: string;
  /**
     * 账号号码
     */
  username?: string;
}

export interface TgUserListModel {
  /**
     * 账号状态
     */
  accountStatus?: number;
  actionLabel?: { [key: string]: any };
  /**
     * appHash
     */
  appHash?: string;
  /**
     * appId
     */
  appId?: string;
  /**
     * 头像url
     */
  avatar?: string;
  /**
     * 封号时间
     */
  banTime?: string;
  /**
     * 个性签名
     */
  bio?: string;
  /**
     * 是否验证码登录:1是，2否
     */
  codeLogin?: number;
  /**
     * 备注
     */
  comment?: string;
  /**
     * 联系人数量
     */
  contactCount?: number;
  ContactIncreaseInfo?: ContactIncreaseInfo;
  /**
     * 创建时间
     */
  createdAt?: string;
  /**
     * 养号时间
     */
  durationTime?: number;
  /**
     * 图片url
     */
  fileUrl?: string;
  /**
     * First Name
     */
  firstName?: string;
  /**
     * id
     */
  id: number;
  /**
     * 是否在线
     */
  isOnline?: number;
  /**
     * 是否发消息限制
     */
  isSendMsgRestricted?: number;
  /**
     * 养号状态
     */
  keepTaskStatus?: number;
  /**
     * 上次登录时间
     */
  lastLoginTime?: string;
  /**
     * Last Name
     */
  lastName?: string;
  /**
     * 所属用户ID
     */
  memberId?: number;
  /**
     * 所属用户名
     */
  memberUsername?: string;
  /**
     * 公司ID
     */
  orgId?: number;
  /**
     * 手机号
     */
  phone?: string;
  /**
     * 手机区号(展示用)
     */
  phoneRegion?: string;
  /**
     * 账号头像
     */
  photo?: string;
  /**
     * 代理地址
     */
  proxyAddress?: string;
  /**
     * 代理地址
     */
  proxyRegion?: string;
  /**
     * 真实地址如ipv6
     */
  realAddress?: string;
  /**
     * 手机地区
     */
  region?: string;
  /**
     * 群发限制时间
     */
  sendMsgRestrictedTime?: string;
  /**
     * tg id
     */
  tgId: number;
  /**
     * 更新时间
     */
  updatedAt?: string;
  /**
     * 账号号码
     */
  username?: string;
  /** 是否置顶 1置顶 0取消置顶 */
  topUp?:number;
  /** 设置置顶的时间  */
  setTopUpTime?:string | null;

  lastMsgContent?:string;
  lastMsgType?:TTgUserLastMessageEnum;
  lastMsgUserNickName?:string;
  folderId?:string;
  folderName?:string;
}

export interface ContactIncreaseInfo {
  /**
     * 活跃人数
     */
  active?: number;
  /**
     * 每日使用话术次数(当日)
     */
  dailyScript?: number;
  /**
     * 每日联系人互动数(当日)
     */
  dailySendContact?: number;
  /**
     * 每日发消息数(当日)
     */
  dailySendMsg?: number;
  /**
     * 添加总量
     */
  increase?: number;
  /**
     * 流失总量
     */
  loss?: number;
  /**
     * 消息已读数(当日)
     */
  read?: number;
  /**
     * 消息回复数(当日)
     */
  reply?: number;
  /**
     * 累计发消息数
     */
  sendMsgCount?: number;
  /**
     * tg账号使用话术次数
     */
  tgUserScriptCount?: number;
  /**
     * 消息未读数
     */
  unreadMsgCount?: number;
}

export interface ExcludeAuthRequest {
  phone: string;
  params?: WorkGramUserData['params'];
}

export interface ExportAuthModel {
  auth: string;
  iv: string;
}
