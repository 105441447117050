const StorageSession = require('./StorageSession');

class LocalStorageSession extends StorageSession {
    _delete() {
        return sessionStorage.removeItem(this._storageKey);
    }

    _fetchFromCache() {
        return sessionStorage.getItem(this._storageKey);
    }

    _saveToCache(data) {
        return sessionStorage.setItem(this._storageKey, data);
    }
}

module.exports = LocalStorageSession;
