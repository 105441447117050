import type { FC } from '../../../../lib/teact/teact';
import React, {
  memo,
} from '../../../../lib/teact/teact';

import { LeftColumnContent } from '../../../../types';

import { createClassNameBuilder } from '../../../../util/buildClassName';

import Loading from '../../../ui/Loading';

import './styles/TgUsersListGroupButton.scss';

type OwnProps = {
  isShow:boolean;
  content:LeftColumnContent;
  tgUsersGroupsFilter?:string;
  tgUsersFilter?:string;
};

const cn = createClassNameBuilder('Avatar');
cn.media = cn('media');
cn.icon = cn('icon');

const BuildLoadingView: FC<OwnProps> = ({
  isShow,
  content,
  tgUsersGroupsFilter,
  tgUsersFilter,
}) => {
  const text = content === LeftColumnContent.SwitchTgUser1 ? 'members' : 'telegram users';
  const filterLength = content === LeftColumnContent.SwitchTgUser1
    ? tgUsersGroupsFilter?.length
    : tgUsersFilter?.length;
  return isShow ? (
    <p className="no-results" dir="auto">
      {filterLength ? `No ${text} matched your search.`
        : `${text.charAt(0).toUpperCase() + text.slice(1)} list is empty.`}
    </p>
  ) : (
    <Loading key="loading" />
  );
};
export default memo(BuildLoadingView);
