import type { MassTextingFormData } from '../../api/types/massTexting';
import type { IndependentTgUserItem } from '../../lib/workGramClient/tgUser';
import type { GlobalState } from '../types';

export function updateTgUsersById<T extends GlobalState>(
  global: T, tgUsers?:IndependentTgUserItem[],
):T {
  return {
    ...global,
    tgUsers: {
      ...global.tgUsers,
      byId: tgUsers ? {
        ...tgUsers.reduce((acc, tgUser) => ({ ...acc, [tgUser.phone]: tgUser }), {}),
      } : {},
    },
  };
}

export function updateTgUser<T extends GlobalState>(
  global: T, phone?:IndependentTgUserItem['phone'],
):T {
  return {
    ...global,
    tgUsers: {
      ...global.tgUsers,
      user: phone ? global.tgUsers.byId[phone] : undefined,
    },
  };
}

export function updateMassTextingFormData<T extends GlobalState>(
  global: T, formData?: MassTextingFormData,
): T {
  return {
    ...global,
    massTexting: {
      ...global.massTexting,
      formData,
    },
  };
}

export function updateMassTextingIsShowModal<T extends GlobalState>(global: T, isShowModal: boolean): T {
  return {
    ...global,
    massTexting: {
      ...global.massTexting,
      isShowModal,
    },
  };
}

export function updateMassTextingIsShowDetailModal<T extends GlobalState>(global: T, isShowDetailModal: boolean): T {
  return {
    ...global,
    massTexting: {
      ...global.massTexting,
      isShowDetailModal,
    },
  };
}
