import type { FC } from '../../lib/teact/teact';
import React, { memo, useCallback } from '../../lib/teact/teact';

import CalendarModal from '../common/CalendarModal';

export type OwnProps = {
  isOpen: boolean;
  selectedAt?: number;
  onClose?: (date?: Date) => void;
};

const HistoryCalendarWrapper: FC<OwnProps> = ({
  isOpen, selectedAt, onClose,
}) => {
  const handleJumpToDate = useCallback((date: Date) => {
    // searchMessagesByDate({ timestamp: date.valueOf() / 1000 });
    // closeHistoryCalendar();
    onClose?.(date);
  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);
  const close = useCallback(() => {
    onClose?.();
  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);
  return (
    <CalendarModal
      isOpen={isOpen}
      selectedAt={selectedAt}
      isPastMode
      submitButtonLabel="查看选中日期未读消息"
      onClose={close}
      onSubmit={handleJumpToDate}
    />
  );
};

export default memo(HistoryCalendarWrapper);
