import type { FC } from '../../lib/teact/teact';
import React from '../../lib/teact/teact';

import buildClassName from '../../util/buildClassName';

import './ErrorContainer.scss';

type OwnProps = {
  id?: string;
  error?: string;
  children: React.ReactNode;
};

const ErrorContainer:FC<OwnProps> = ({ error, id, children }) => {
  return (
    <div
      id={id}
      className={buildClassName(
        'input-group',
        error && 'error',
        error && 'with-label',
        error && 'container-error',
      )}
    >
      {children}
      {error && (
        <label htmlFor={id}>{error}</label>
      )}

    </div>
  );
};

export default ErrorContainer;
