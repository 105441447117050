import { type ChangeEvent } from 'react';
import React, {
  memo, useCallback, useEffect, useState,
} from '../../lib/teact/teact';

import buildClassName from '../../util/buildClassName';

import Button from './Button';

import './Pagination.scss';

interface PaginationProps {
  total: number;
  pageSize?: number;
  page:number;
  itemsPerPageOptions?: number[];
  onPageChange?: (page: number, pagesize: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  total,
  pageSize = 20,
  page,
  itemsPerPageOptions = [20, 40, 60, 80, 100],
  onPageChange,
}) => {
  // const [itemsPerPage, setItemsPerPage] = useState<number>(pageSize);
  const [toPage, setToPage] = useState<number>(page);

  const totalPages = Math.ceil(total / pageSize);

  useEffect(() => {
    setToPage(page);
  }, [page]);

  const handlePageChange = useCallback((newPage: number) => {
    if (newPage < 1 || newPage > totalPages) return;
    onPageChange?.(newPage, pageSize);
  }, [onPageChange, pageSize, totalPages]);

  const handleItemsPerPageChange = useCallback((event:ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = Number(event.target.value);
    // setItemsPerPage(newItemsPerPage);
    onPageChange?.(1, newItemsPerPage);
  }, [onPageChange]);

  const onChangeToPage = useCallback((event:React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      handlePageChange(Number(event.currentTarget.value));
    }
  }, [handlePageChange]);

  const onBlurToPage = useCallback(() => {
    setToPage(page);
  }, [page]);

  return (
    <div className="Pagination">
      <select
        className="page-size-select"
        value={String(pageSize)}
        onChange={handleItemsPerPageChange}
      >
        {itemsPerPageOptions.map((option) => (
          <option key={String(option)} value={String(option)}>
            {option}
          </option>
        ))}
      </select>
      <Button
        round
        size="smaller"
        color="translucent"
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => handlePageChange(1)}
        disabled={page === 1}
      >
        <i className={buildClassName('icon', 'iconfont', 'icon-wg-arrow-double', 'left-arrow')} />
      </Button>

      <Button
        size="smaller"
        color="translucent"
        round
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => handlePageChange(page - 1)}
        disabled={page === 1}
      >
        <i className={buildClassName('icon', 'iconfont', 'icon-wg-arrow', 'left-arrow')} />
      </Button>
      <span>
        <input
          className="page-input"
          type="number"
          min={1}
          max={totalPages}
          value={toPage}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={(e) => setToPage(Number(e.target.value))}
          onKeyDown={onChangeToPage}
          onBlur={onBlurToPage}
        /> / {totalPages}
      </span>
      <Button
        size="smaller"
        color="translucent"
        round
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => handlePageChange(page + 1)}
        disabled={page === totalPages}
      >
        <i className={buildClassName('icon', 'iconfont', 'icon-wg-arrow')} />

      </Button>
      <Button
        size="smaller"
        color="translucent"
        round
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => handlePageChange(totalPages)}
        disabled={page === totalPages}
      >
        <i className={buildClassName('icon', 'iconfont', 'icon-wg-arrow-double')} />
      </Button>
    </div>
  );
};

export default memo(Pagination);
