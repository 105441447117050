import type { ChangeEvent } from 'react';
import type { FC } from '../../lib/teact/teact';
import React, { memo, useState } from '../../lib/teact/teact';

import buildClassName from '../../util/buildClassName';

import useLastCallback from '../../hooks/useLastCallback';
import useOldLang from '../../hooks/useOldLang';

import Button from '../ui/Button';
import InputText from '../ui/InputText';
import Modal from '../ui/Modal';

export type OwnProps = {
  isOpen: boolean;
  oldComment:string;
  onClose: (comment?:string) => void;
  onCloseAnimationEnd?: () => void;
};

const CommentModal: FC<OwnProps> = ({
  isOpen,
  oldComment = '',
  onClose,
  onCloseAnimationEnd,
}) => {
  const [comment, setComment] = useState(oldComment);

  const handleComment = useLastCallback(() => {
    onClose(comment);
  });

  const handleCommentChange = useLastCallback((e: ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  });

  const lang = useOldLang();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onEnter={isOpen ? handleComment : undefined}
      onCloseAnimationEnd={onCloseAnimationEnd}
      className={buildClassName('narrow')}
      title="备注"
    >

      <InputText
        label="请输入备注"
        value={comment}
        onChange={handleCommentChange}
      />
      <div className="dialog-buttons">
        <Button color="danger" className="confirm-dialog-button" isText onClick={handleComment}>
          确认
        </Button>

        <Button
          className="confirm-dialog-button"
          isText
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => onClose()}
        >{lang('Cancel')}
        </Button>
      </div>
    </Modal>
  );
};

export default memo(CommentModal);
