import type { ResponseData } from '../types';
import type { TgAutomaticReplyEditRequest, TgAutomaticReplyViewResponse } from '../types/autoReply';

import requestServer from './axios';

/**
 * 自动回复编辑修改
 * @param data
 * @returns
 */

export const tgAutomaticReplyEdit = (data?: TgAutomaticReplyEditRequest) => {
  return requestServer.post<ResponseData<any>>('/tg/tgAutomaticReply/edit', data)
    .then((res) => res);
};

/**
 * 自动回复 开始结束
 * @param data
 * @returns
 */

export const tgAutomaticReplyStartStop = (type: 'start' | 'stop', data?: { memberId:number }) => {
  return requestServer.post<ResponseData<any>>(`/tg/tgAutomaticReply/${type}`, data)
    .then((res) => res);
};

/**
 * 自动回复 回显
 * @param data
 * @returns
 */
export const tgAutomaticReplyView = (data?: { memberId:number }) => {
  return requestServer.get<ResponseData<TgAutomaticReplyViewResponse>>('/tg/tgAutomaticReply/view', {
    params: data,
  })
    .then((res) => res);
};
