import type { ChangeEvent } from 'react';
import React, {
  type FC, memo, useCallback, useState,
} from '../../../lib/teact/teact';
import { getActions } from '../../../global';

import type { ISettings } from '../../../types';
import type { WorkGramUserData } from '../../../util/praseWorkGramHash';
import type { AutoReplyFormDataProps, EchoExtend } from '../../ui/AutoResponseModel/WrapperAutoModel';
import { UnReadGroupType } from '../../../lib/workGramClient/types';

import { getDayStartAt } from '../../../util/dates/dateFormat';
import { getLocalizationData, setLocalizationData } from '../../../util/initLocalization';
import { tgAutomaticReplyView } from '../../../api/request';

import useLastCallback from '../../../hooks/useLastCallback';

import HistoryCalendarWrapper from '../../main/HistoryCalendarWrapper';
import MassTextingDetailModal from '../../modals/massTexting/MassTextingDetailModal';
import MassTextingModal from '../../modals/massTexting/MassTextingModal';
import WrapperAutoModel from '../../ui/AutoResponseModel/WrapperAutoModel';
import Link from '../../ui/Link';
import MenuItem from '../../ui/MenuItem';
import Switcher from '../../ui/Switcher';

import './TgUsersList.scss';

type OwnProps = {
  baseSetting:{
    listImmobilization?: boolean;
    loginUserTop?: boolean;
    listImmobilizationClick:()=>void;
    handleLoginUserTop:(e:React.ChangeEvent<HTMLInputElement>)=>void;
  };
  otherSetting?:{
    handleReadAll:()=>void;
    isOpenAutoReplyConfig?:boolean;
    handleIsOpenAutoReplyConfigChange?:(e:React.ChangeEvent<HTMLInputElement>)=>void;
  };
  unReadGroups?:{
    unReadGroupValue?:UnReadGroupProps;
    unReadGroupClick:()=>void;
    handleUnReadGroupType:(type:UnReadGroupTypeValues, value:UnReadGroupTypeValues | undefined)=>void;
    handleUnReadOtherDataTimeChange:(date:Date | number | undefined)=>void;
  };
  workData?:WorkGramUserData | null;
};
export type UnReadGroupTypeValues = typeof UnReadGroupType[keyof typeof UnReadGroupType];
export interface UnReadGroupProps {
  totalUnread:UnReadGroupTypeValues | undefined;
  todayUnread:UnReadGroupTypeValues | undefined;
  otherUnread:UnReadGroupTypeValues | undefined;
}

const SORT = 'sort';
const TgUserMenuItemSetting: FC<OwnProps> = ({
  baseSetting, otherSetting, unReadGroups, workData,
}) => {
  const [sortValue, setSortValue] = useState<ISettings['tgUserListSort']>(
    getLocalizationData(SORT) as ISettings['tgUserListSort'],
  );
  const [otherDayUnReadObj, setOtherDayUnReadObj] = useState<{ open:boolean;selectedAt:number }>({
    open: false,
    selectedAt: 0,
  });
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [echo, setEcho] = useState<AutoReplyFormDataProps & EchoExtend>();
  const {
    setSettingOption,
    showNotification,
    updateMassTexting,
  } = getActions();
  const menuItemClick = useLastCallback((e: React.SyntheticEvent<HTMLElement>) => e.stopPropagation());
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSortValue(value as ISettings['tgUserListSort']);
    setLocalizationData(SORT, value as ISettings['tgUserListSort']);
    setSettingOption({ tgUserListSort: value as ISettings['tgUserListSort'] });
  }, []);
  const menuItemOtherDateTimeClick = useCallback((e: React.SyntheticEvent<HTMLElement>) => {
    e?.stopPropagation?.();
    setOtherDayUnReadObj(() => ({ open: true, selectedAt: getDayStartAt(Date.now()) }));
  }, []);
  const menuItemOtherDateTimeClose = useCallback((date?: Date) => {
    setOtherDayUnReadObj(() => ({ open: false, selectedAt: 0 }));
    // eslint-disable-next-line no-console
    console.log(date, date?.valueOf?.(), 'date');
    unReadGroups?.handleUnReadOtherDataTimeChange(date?.valueOf?.() || getDayStartAt(Date.now()));
    // setUnReadGroupValue((pre) => ({ ...pre, otherUnread: UnReadGroupType.OTHERUNREAD }));
    unReadGroups?.handleUnReadGroupType('otherUnread' as UnReadGroupTypeValues, UnReadGroupType.OTHERUNREAD);
    // unReadGroups?.unReadGroupClick();
  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);
  const handleAutoResponseModel = useCallback(async (isOpen = true) => {
    if (isOpen) {
      try {
        const res = await tgAutomaticReplyView({ memberId: workData?.params?.memberId! });
        setEcho(res?.data as AutoReplyFormDataProps & EchoExtend || {});
      } catch (error) {
        if (typeof error === 'object' && error && 'message' in error) {
          showNotification({ message: (error as { message: string }).message || '添加自动回复任务失败' });
        } else {
          // 处理不是对象或没有 message 属性的错误
          showNotification({ message: '发生未知错误' });
        }
      }
    }
    setIsOpenModel(isOpen);
  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);

  const openMassTextingModel = useCallback(() => {
    // 打开群发弹框并设置默认值
    updateMassTexting({
      isShowModal: true,
      formData: {
        action: 1,
        triggerLimit: 1,
        sendLimit: 0,
        executeLimit: 1,
        taskName: '',
        isActivity: 1,
        inputType: 1,
        scriptType: 1,
        tagetsType: 2,
        isExpression: 1,
        isSplit: 1,
        isRandomEdit: 1,
        isGreet: 1,
        isSensitiveHandle: 1,
        delAllOrOnceMsg: 1,
        selectSensitiveWay: 1,
        scriptMode: 1,
        greetMode: 1,
        sendMode: 3,
        id: undefined,
        accounts: [],
        intervalTime: 1,
        tagets: [],
        scriptGroupName: '',
        tailMessage: '--此消息发自 @WorkGram888',
        isTailMessage: 2,
        isHello: 1,
        delayTime: 0,
        isImmediateExecu: true,
      },
    });
  }, []);

  const openMassTextingDetailModel = useCallback(() => {
    updateMassTexting({ isShowDetailModal: true });
  }, []);

  const handleUnReadGroupChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.currentTarget;
    if (checked) {
      if (value as UnReadGroupTypeValues === 'otherUnread') {
        menuItemOtherDateTimeClick(event);
        return;
      }
      // setUnReadGroupValue(value as UnReadGroupTypeValues);
      // setUnReadGroupValue((pre) => ({ ...pre, [value as UnReadGroupTypeValues]: UnReadGroupType.OTHERUNREAD }));
      unReadGroups?.handleUnReadGroupType(value as UnReadGroupTypeValues, value as UnReadGroupTypeValues);
      // unReadGroups?.unReadGroupClick();
    } else {
      // setUnReadGroupValue(undefined);
      // setUnReadGroupValue((pre) => ({ ...pre, [value]: undefined }));
      unReadGroups?.handleUnReadGroupType(value as UnReadGroupTypeValues, undefined);
    }

  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);
  return (
    <div>
      <span className="menus-item-mini-title">基本配置</span>
      <MenuItem onClick={menuItemClick}>
        <span className="menus-item-mini-lable">登录用户置顶</span>
        <Switcher
          id="darkmode"
          label="lng_settings_disable_night_theme"
          checked={baseSetting?.loginUserTop}
          onChange={baseSetting?.handleLoginUserTop}
        />
      </MenuItem>
      <MenuItem onClick={menuItemClick}>
        <span className="menus-item-mini-lable">TG列表展开固定</span>
        <Switcher
          id="darkmode"
          label="lng_settings_disable_night_theme"
          checked={baseSetting?.listImmobilization}
          noAnimation
          onChange={baseSetting?.listImmobilizationClick}
        />
      </MenuItem>
      <span className="menus-item-mini-title">未读分组配置</span>
      <MenuItem className="menus-item-radio" onClick={menuItemClick}>
        <span className="menus-item-mini-lable">未读分组</span>
        <input
          type="checkbox"
          value="totalUnread"
          id="totalUnread"
          checked={unReadGroups?.unReadGroupValue?.totalUnread === UnReadGroupType.TOTALUNREAD}
          onChange={handleUnReadGroupChange}
        />
      </MenuItem>
      <MenuItem className="menus-item-radio" onClick={menuItemClick}>
        <span className="menus-item-mini-lable">当天未读分组</span>
        <input
          type="checkbox"
          value="todayUnread"
          id="todayUnread"
          checked={unReadGroups?.unReadGroupValue?.todayUnread === UnReadGroupType.TODAYUNREAD}
          onChange={handleUnReadGroupChange}
        />
      </MenuItem>
      <MenuItem className="menus-item-radio" onClick={menuItemClick}>
        <span className="menus-item-mini-lable">其他天未读分组</span>
        <input
          type="checkbox"
          value="otherUnread"
          id="otherUnread"
          checked={unReadGroups?.unReadGroupValue?.otherUnread === UnReadGroupType.OTHERUNREAD}
          onChange={handleUnReadGroupChange}
        />
      </MenuItem>
      <span className="menus-item-mini-title">排序方法配置</span>
      <MenuItem className="menus-item-radio" onClick={menuItemClick}>
        <span className="menus-item-mini-lable">默认排序</span>
        <input
          type="radio"
          value="default"
          id="default"
          checked={sortValue === 'default'}
          onChange={handleChange}
        />
      </MenuItem>
      <MenuItem className="menus-item-radio" onClick={menuItemClick}>
        <span className="menus-item-mini-lable">未读数量排序</span>
        <input
          type="radio"
          value="unreadMsgCount"
          id="unreadMsgCount"
          checked={sortValue === 'unreadMsgCount'}
          onChange={handleChange}
        />
      </MenuItem>
      <MenuItem className="menus-item-radio" onClick={menuItemClick}>
        <span className="menus-item-mini-lable">未读时间排序</span>
        <input
          type="radio"
          value="dateTime"
          id="dateTime"
          checked={sortValue === 'dateTime'}
          onChange={handleChange}
        />
      </MenuItem>
      <MenuItem className="menus-item-radio" onClick={menuItemClick}>
        <span className="menus-item-mini-lable">fistName排序</span>
        <input
          type="radio"
          value="firstName"
          id="firstName"
          checked={sortValue === 'firstName'}
          onChange={handleChange}
        />
      </MenuItem>
      <span className="menus-item-mini-title">其他配置</span>
      <MenuItem onClick={menuItemClick}>
        <span className="menus-item-mini-lable">自动回复</span>
        <Switcher
          id="darkmode"
          label="开启自动回复"
          checked={otherSetting?.isOpenAutoReplyConfig}
          onChange={otherSetting?.handleIsOpenAutoReplyConfigChange}
        />
        <Link onClick={handleAutoResponseModel} className="auto-reply">配置</Link>
      </MenuItem>

      <span className="menus-item-mini-title">功能</span>
      <MenuItem onClick={otherSetting?.handleReadAll}>
        <span className="menus-item-mini-lable">一键已读</span>
      </MenuItem>

      <MenuItem>
        <span className="menus-item-mini-lable">一键群发</span>
        <div className="qunfa">
          <Link onClick={openMassTextingModel} className="auto-reply">配置群发</Link>
          <Link onClick={openMassTextingDetailModel} className="auto-reply">查看</Link>
        </div>
      </MenuItem>

      <HistoryCalendarWrapper
        isOpen={otherDayUnReadObj.open}
        selectedAt={otherDayUnReadObj.selectedAt}
        onClose={menuItemOtherDateTimeClose}
      />
      <WrapperAutoModel isOpen={isOpenModel} onChangeModel={handleAutoResponseModel} workData={workData} echo={echo} />
      <MassTextingModal />
      <MassTextingDetailModal />
    </div>
  );
};

export default memo(TgUserMenuItemSetting);
