import type { TeactNode } from '../../../lib/teact/teact';
import React, {
  memo, useCallback, useEffect, useRef,
} from '../../../lib/teact/teact';

import type { CustomPeerType } from '../../../types';
import type { HandlePeerPickerChangeParams } from './types';

import { requestMeasure } from '../../../lib/fasterdom/fasterdom';
import buildClassName from '../../../util/buildClassName';

import useLastCallback from '../../../hooks/useLastCallback';
import useOldLang from '../../../hooks/useOldLang';

import InputText from '../InputText';
import Loading from '../Loading';
import PeerPickerSelectList from './PeerPickerSelectList';
import SelectedChip from './SelectedChip';

import styles from './PeerPickerStyles.module.scss';

type SingleModeProps = {
  itemInputType?: 'radio';
  selectedId?: string;
  selectedIds?: never;
  selectedCategory?: CustomPeerType;
  selectedCategories?: never;
  onSelectedCategoryChange?: (category: CustomPeerType) => void;
  onSelectedIdChange?: (id: string) => void;
};

type MultipleModeProps = {
  itemInputType: 'checkbox' | 'radio' | 'nothing';
  selectedId?: never;
  selectedIds?: string[];
  lockedSelectedIds?: string[];
  lockedUnselectedIds?: string[];
  selectedCategory?: never;
  selectedCategories?: CustomPeerType[];
  onSelectedCategoriesChange?: (categories: CustomPeerType[]) => void;
  onSelectedIdsChange?: (Ids: string[]) => void;
};

export type ListItemType<T=any, K = any> = {
  id: string | number;
  name:string;
  avatar?:string;
  subtitle?:string;
  disabled?:boolean;
  /** 一级数据 */
  data:T;
  /** 二级数据 */
  children?:ListItemType<K>[];

};

type OwnProps<T, K> = {
  isLoading?: boolean;
  className?: string;
  filterValue?: string;
  filterPlaceholder?: string;
  searchInputId?: string;
  itemClassName?: string;
  noScrollRestore?: boolean;
  isSearchable?: boolean;
  isViewOnly?: boolean;
  selectDataMap: Map<string, ListItemType<K>>;
  listData:ListItemType<T, K>[];
  originListData:ListItemType<T, K>[];
  withDefaultPadding?: boolean;
  needChip:boolean;
  needSearch:boolean;
  HeaderChildren?:React.ReactNode;
  FooterChildren?:React.ReactNode;
  isOpenAllFolder?:boolean;
  renderBaseItem?: (item:ListItemType<T, K>) => TeactNode;
  renderChildrenItem?: (item:ListItemType<K>) => TeactNode;
  onFilterChange?: (value: string) => void;
  onPeerPickerChange: (params:HandlePeerPickerChangeParams<T, K>) => void;
} & (SingleModeProps | MultipleModeProps);

export interface PeerPickerModelDefaultT {
  id:number | string;
}
// Focus slows down animation, also it breaks transition layout in Chrome
const FOCUS_DELAY_MS = 500;

const PeerPickerSelectContainer = <T extends PeerPickerModelDefaultT, K = T>({
  isLoading,
  className,
  filterValue,
  filterPlaceholder,
  searchInputId,
  itemClassName,
  noScrollRestore,
  isSearchable,
  isViewOnly,
  itemInputType,
  withDefaultPadding,
  listData,
  originListData,
  selectDataMap,
  needChip,
  needSearch,
  HeaderChildren,
  FooterChildren,
  isOpenAllFolder,
  renderBaseItem,
  renderChildrenItem,
  onFilterChange,
  onPeerPickerChange,
}: OwnProps<T, K>) => {
  const lang = useOldLang();

  // eslint-disable-next-line no-null/no-null
  const inputRef = useRef<HTMLInputElement>(null);
  // eslint-disable-next-line no-null/no-null
  const searchInputContainerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!isSearchable) return undefined;
    const timeoutId = window.setTimeout(() => {
      requestMeasure(() => {
        inputRef.current?.focus();
      });
    }, FOCUS_DELAY_MS);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isSearchable]);

  const handleFilterChange = useLastCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    onFilterChange?.(value);
  });
  const handlePeerPickerChange = useCallback((params:HandlePeerPickerChangeParams<T, K>) => {
    const { isScrollBottom } = params;
    onPeerPickerChange(params);
    if (searchInputContainerRef.current && isScrollBottom) {
      const observer = new MutationObserver(() => {
        requestAnimationFrame(() => {
          searchInputContainerRef.current?.scrollTo(0, searchInputContainerRef.current?.scrollHeight || 0);
          observer.disconnect();
        });
      });
      observer.observe(searchInputContainerRef.current, { childList: true, subtree: true });
    }
  }, [onPeerPickerChange]);
  return (
    <div className={buildClassName(styles.container, className)}>
      {HeaderChildren}
      {
        (needChip || needSearch) && (
          <div
            className={buildClassName(styles.header, 'custom-scroll', styles.searchInputContainer)}
            dir={lang.isRtl ? 'rtl' : undefined}
            key="searchInputId"
            ref={searchInputContainerRef}
          >
            {needChip && Array.from(selectDataMap.entries()).map(([key, values]) => (
              <SelectedChip
                icon={values.avatar}
                key={`${values.id}`}
                title={values?.name || values.subtitle}
                canClose
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => handlePeerPickerChange({
                  isChecked: true,
                  mapKey: key,
                  data: values,
                })}
                clickArg={`${values.id}`}
              />
            ))}
            {
              needSearch && (
                <InputText
                  id={searchInputId}
                  ref={inputRef}
                  value={filterValue}
                  onChange={handleFilterChange}
                  placeholder={filterPlaceholder || lang('SelectChat')}
                />
              )
            }
          </div>
        )
      }
      {isLoading ? <Loading /> : (
        <PeerPickerSelectList<T, K>
          listData={listData}
          originListData={originListData}
          itemClassName={itemClassName}
          isViewOnly={isViewOnly}
          noScrollRestore={noScrollRestore}
          selectDataMap={selectDataMap}
          withDefaultPadding={withDefaultPadding}
          itemInputType={itemInputType}
          isOpenAllFolder={isOpenAllFolder}
          onPeerPickerChange={handlePeerPickerChange}
          renderBaseItem={renderBaseItem}
          renderChildrenItem={renderChildrenItem}
        />
      )}

      {FooterChildren}
    </div>
  );
};

export default memo(PeerPickerSelectContainer);
