export interface ResponseData<T = any> {
  /**
     * 状态码
     */
  code?: number;
  /**
     * 数据集
     */
  data?: T;
  error?: { [key: string]: any };
  /**
     * 提示消息
     */
  message?: string;
  /**
     * 业务编码
     */
  svcCode?: string;
  /**
     * 服务器时间戳
     */
  timestamp?: number;
  /**
     * 链路ID
     */
  traceID?: string;
}

export interface ResponseListData<T = any> {
  /**
     * 数据列表
     */
  list?: T[];
  /**
     * 排序字段
     */
  order?: string;
  /**
     * 当前页码
     */
  page?: number;
  /**
     * 分页个数
     */
  pageCount?: number;
  /**
     * 每页数量
     */
  pageSize?: number;
  /**
     * 数据总行数
     */
  totalCount?: number;
}
