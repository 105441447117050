import type { ChangeEvent } from 'react';
import type { FC } from '../../../../lib/teact/teact';
import React, { memo, useState } from '../../../../lib/teact/teact';
import { getActions } from '../../../../lib/teact/teactn';

import buildClassName from '../../../../util/buildClassName';

import useLastCallback from '../../../../hooks/useLastCallback';
import useOldLang from '../../../../hooks/useOldLang';

import Button from '../../../ui/Button';
import InputText from '../../../ui/InputText';
import Loading from '../../../ui/Loading';
import Modal from '../../../ui/Modal';

export type OwnProps = {
  isOpen: boolean;
  onClose: (folderName?:string) => Promise<void>;
  onCloseAnimationEnd?: () => void;
};

const AddFolderModal: FC<OwnProps> = ({
  isOpen,
  onClose,
  onCloseAnimationEnd,
}) => {
  const { showNotification } = getActions();
  const [folderName, setFolderName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const handleConfirm = useLastCallback(async () => {
    if (!folderName) {
      showNotification({
        message: '请输入分组名称',
      });
      return;
    }
    setIsLoading(true);
    await onClose(folderName);
    setIsLoading(false);
  });

  const handleChange = useLastCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFolderName(e.target.value);
  });

  const lang = useOldLang();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onEnter={isOpen ? handleConfirm : undefined}
      onCloseAnimationEnd={onCloseAnimationEnd}
      className={buildClassName('narrow')}
      title="新增分组"
    >

      <InputText
        label="请输入分组名称"
        value={folderName}
        onChange={handleChange}
      />
      <div className="dialog-buttons">
        {
          isLoading ? <Loading key="loading" />
            : (
              <Button
                color="danger"
                className="confirm-dialog-button"
                isText
                onClick={handleConfirm}
              >
                确认
              </Button>
            )
        }

        <Button
          className="confirm-dialog-button"
          isText
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => onClose()}
        >{lang('Cancel')}
        </Button>
      </div>
    </Modal>
  );
};

export default memo(AddFolderModal);
