import type { TeactNode } from '../../../lib/teact/teact';
import React, {
  memo, useCallback, useMemo,
  useState,
} from '../../../lib/teact/teact';

import type { ListItemType, PeerPickerModelDefaultT } from './PeerPickerSelectContainer';
import type { HandlePeerPickerChangeParams } from './types';
import { AllCheckStateEnum } from './types';

import buildClassName from '../../../util/buildClassName';

import Icon from '../../common/icons/Icon';
import PickerItem from '../../common/pickers/PickerItem';
import Checkbox from '../Checkbox';
import InfiniteScroll from '../InfiniteScroll';
import Radio from '../Radio';

import styles from './PeerPickerStyles.module.scss';

type OwnProps<T, K> = {
  listData:ListItemType<T, K>[];
  originListData:ListItemType<T, K>[];
  itemClassName?: string;
  isViewOnly?: boolean;
  noScrollRestore?: boolean;
  selectDataMap: Map<string, ListItemType<K>>;
  onPeerPickerChange: (params:HandlePeerPickerChangeParams<T, K>) => void;
  withDefaultPadding?: boolean;
  itemInputType?:'radio' | 'checkbox' | 'nothing';
  isOpenAllFolder?:boolean;
  renderBaseItem?: (item:ListItemType<T, K>) => TeactNode;
  renderChildrenItem?: (item:ListItemType<K>) => TeactNode;
};

const ITEM_CLASS_NAME = 'PeerPickerItem';

const PeerPickerSelectList = <T extends PeerPickerModelDefaultT, K=T>({
  listData,
  originListData,
  withDefaultPadding,
  noScrollRestore,
  selectDataMap,
  itemClassName,
  isViewOnly,
  itemInputType,
  isOpenAllFolder,
  renderBaseItem,
  renderChildrenItem,
  onPeerPickerChange,
}: OwnProps<T, K>) => {
  const [openFolderIds, setOpenFolderIds] = useState<string[]>([]);

  const getInputElement = useCallback((isChecked: boolean, indeterminate?: boolean, disabled?:boolean) => {
    if (itemInputType === 'nothing') {
      return undefined;
    }
    if (itemInputType === 'radio') {
      return <Radio onlyInput checked={isChecked} disabled={disabled} />;
    }
    if (itemInputType === 'checkbox') {
      return (
        <Checkbox
          checked={isChecked}
          disabled={disabled}
          onlyInput
          indeterminate={indeterminate}
        />
      );
    }
    return undefined;
  }, [itemInputType]);

  const beforeChildren = useMemo(() => {
    if (listData.length > 0) return undefined;
    return (
      <div key="categories">
        <div className={styles.pickerCategoryTitle}>无数据</div>
      </div>
    );
  }, [listData?.length]);

  const avatarElement = useCallback((item: ListItemType<T, K> | ListItemType<K>) => {
    return (
      <div className={buildClassName(styles.Avatar, ' size-large ', 'peer-color-4')}>
        <div className={styles.inner}>
          {
            item?.avatar
              ? (
                <img
                  src={item?.avatar}
                  className={styles.pickImage}
                  alt=""
                />
              )
              : item?.name?.trim().substring(0, 2)
          }
        </div>
      </div>
    );
  }, []);

  const onChangeOpenFolderIds = useCallback(
    (folderId: string, isInit?: boolean) => {
      if (isInit) {
        setOpenFolderIds(folderId.split(','));
        return;
      }
      setOpenFolderIds(
        (pre) => (pre.includes(folderId) ? pre.filter((item) => item !== folderId) : [...pre, folderId]),
      );
    },
    [],
  );
  const selectDataMapKeys = useMemo(() => Array.from(selectDataMap.keys()), [selectDataMap]);
  const ChildrenSelectArray = useCallback((children:ListItemType<K>[]) => {
    return children.filter((child) => selectDataMapKeys.includes(child.id.toString()));
  }, [selectDataMapKeys]);

  const allCheckBoxState = useMemo(() => {
    let hasChecked = false;
    let hasNotChecked = false;
    let state = AllCheckStateEnum.Unchecked;
    try {
      originListData.forEach((item) => {
        if (hasChecked && hasNotChecked) {
          throw new Error('Indeterminate');
        }
        if (item.children) {
          const checkedArr = ChildrenSelectArray(item.children);
          if (checkedArr.length > 0) {
            hasChecked = true;
          }
          if (checkedArr?.length !== item?.children?.length) {
            hasNotChecked = true;
          }
        } else if (!item.children) {
          if (selectDataMap.has(`${item.id}`)) {
            hasChecked = true;
          } else {
            hasNotChecked = true;
          }
        }
      });
    } catch (err) {
      if (err instanceof Error && err.message === 'Indeterminate') {
        state = AllCheckStateEnum.Indeterminate;
      }
    }
    if (!hasNotChecked && hasChecked) {
      state = AllCheckStateEnum.Checked;
    }
    if (hasNotChecked && !hasChecked && selectDataMap.size > 0) {
      state = AllCheckStateEnum.Indeterminate;
    }
    return state;
  }, [ChildrenSelectArray, originListData, selectDataMap]);

  return (
    <>
      {
        listData.length > 0 && itemInputType === 'checkbox' && (
          <div className={buildClassName(styles['all-checked'])}>
            <div className={buildClassName('custom-scroll', styles['padded-all-checked'])}>
              <PickerItem
                key="all-select-item"
                className={buildClassName(ITEM_CLASS_NAME, itemClassName)}
                title="全选"
                subtitleClassName=""
                disabled={false}
                inactive={isViewOnly}
                ripple
                // inputElement={getInputElement(
                //   [AllCheckStateEnum.Checked, AllCheckStateEnum.Indeterminate].includes(allCheckBoxState),
                //   allCheckBoxState === AllCheckStateEnum.Indeterminate,
                // )}
                inputElement={(
                  <div className={styles['folder-title-input-element']}>
                    <div className={buildClassName(styles['checkbox-element'])}>
                      {getInputElement(
                        [AllCheckStateEnum.Checked, AllCheckStateEnum.Indeterminate].includes(allCheckBoxState),
                        allCheckBoxState === AllCheckStateEnum.Indeterminate,
                      )}
                    </div>
                    {
                      listData[0]?.children
                    && (
                      <Icon
                        name="add"
                        className={buildClassName('ListItem-main-icon', styles['add-disable'])}
                      />
                    )
                    }
                  </div>
                )}
                inputPosition="end"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => onPeerPickerChange({ state: allCheckBoxState, isScrollBottom: true })}
              />
            </div>

          </div>
        )
      }
      <InfiniteScroll
        key="selectTgUserList"
        className={buildClassName(styles.pickerList, withDefaultPadding && styles.padded, 'custom-scroll')}
        items={listData}
        itemSelector={`.${ITEM_CLASS_NAME}`}
        beforeChildren={beforeChildren}
        noScrollRestore={noScrollRestore}
      >
        <div key="select-tg-user-list">
          {listData?.map((item) => {
            const itemKey = `${item.id}`;
            let isFolderChecked;
            let hasChecked;
            if (item.children) {
              const checkedArr = ChildrenSelectArray(item.children);
              hasChecked = checkedArr?.length > 0;
              const originChildrenLength = originListData.find(
                (originItem) => originItem.id === item.id,
              )?.children?.length || 0;
              isFolderChecked = checkedArr?.length === originChildrenLength;
            } else {
              isFolderChecked = selectDataMap.has(itemKey);
            }
            const isOpen = item.children && openFolderIds.includes(itemKey);
            return (
              <>
                <PickerItem
                  key={`select-list-${itemKey}`}
                  className={buildClassName(ITEM_CLASS_NAME, itemClassName)}
                  title={item.name}
                  subtitle={item.subtitle}
                  subtitleClassName=""
                  disabled={item.disabled}
                  inactive={isViewOnly}
                  ripple
                  renderElement={renderBaseItem?.(item)}
                  avatarElement={item.children ? undefined : avatarElement(item)}
                  inputElement={(
                    <div className={styles['folder-title-input-element']}>
                      <div
                        className={styles['checkbox-element']}
                        onClick={(e) => {
                          if (item.children) {
                            onPeerPickerChange({
                              isChecked: isFolderChecked,
                              data: item,
                              mapKey: itemKey,
                              isScrollBottom: true,
                            });
                            e.stopPropagation();
                          }
                        }}
                      >
                        {getInputElement(isFolderChecked,
                          item.children && hasChecked && !isFolderChecked,
                          item.disabled)}
                      </div>
                      {
                        item.children
                      && (
                        <Icon
                          name={isOpen ? 'remove' : 'add'}
                          className={buildClassName('ListItem-main-icon')}
                        />
                      )
                      }
                    </div>
                  )}
                  inputPosition="end"
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => {
                    if (item.disabled) {
                      return;
                    }
                    if (item.children) {
                      onChangeOpenFolderIds(itemKey);
                    } else {
                      onPeerPickerChange({
                        isChecked: isFolderChecked,
                        data: item,
                        mapKey: itemKey,
                        isScrollBottom: true,
                      });
                    }
                  }}
                />
                {
                  item.children && (isOpenAllFolder || isOpen) && (
                    item.children.map((child) => {
                      const selectMapKey = `${child.id}`;
                      const childKey = `${itemKey}-${selectMapKey}`;
                      const isChecked = selectDataMap.has(selectMapKey);
                      return (
                        <PickerItem
                          key={childKey}
                          className={buildClassName(ITEM_CLASS_NAME, itemClassName)}
                          title={child.name}
                          avatarElement={avatarElement(child)}
                          subtitle={child.subtitle}
                          subtitleClassName=""
                          disabled={child.disabled}
                          inactive={isViewOnly}
                          ripple
                          renderElement={renderChildrenItem?.(child)}
                          inputElement={getInputElement(isChecked, undefined, child.disabled)}
                          inputPosition="end"
                          // eslint-disable-next-line react/jsx-no-bind
                          onClick={() => !child?.disabled
                             && onPeerPickerChange({
                               isChecked, data: child, mapKey: selectMapKey, isScrollBottom: true,
                             })}

                        />
                      );
                    })
                  )
                }
              </>
            );
          })}

        </div>
      </InfiniteScroll>
    </>
  );
};

export default memo(PeerPickerSelectList);
