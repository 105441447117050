import type { FC } from '../../../../lib/teact/teact';
import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from '../../../../lib/teact/teact';
import { withGlobal } from '../../../../global';

import type { ApiCountryCode, TgUserListModel } from '../../../../api/types';
import type { LangFn } from '../../../../hooks/useOldLang';
import type { IndependentTgUserItem } from '../../../../lib/workGramClient/tgUser';
import type { WorkGramUserData } from '../../../../util/praseWorkGramHash';
import { LeftColumnContent } from '../../../../types';

import buildClassName, { createClassNameBuilder } from '../../../../util/buildClassName';
import { isoToEmoji } from '../../../../util/emoji/emoji';
import { formatPhoneNumberWithCode } from '../../../../util/phoneNumber';
import renderText from '../../../common/helpers/renderText';

import useOldLang from '../../../../hooks/useOldLang';

import Icon from '../../../common/icons/Icon';
import LastMessageMeta from '../../../common/LastMessageMeta';
import Button from '../../../ui/Button';
import ListItem from '../../../ui/ListItem';
import RippleEffect from '../../../ui/RippleEffect';
import BuildChatBadge from './BuildChatBadge';
import TgUserLastMessage from './LastMessage';

import './styles/TgUsersListGroupButton.scss';

const cn = createClassNameBuilder('Avatar');
cn.media = cn('media');
cn.icon = cn('icon');

type OwnProps = {
  list:(IndependentTgUserItem | TgUserListModel)[];
  folderId?:string;
  content:LeftColumnContent;
  workData:WorkGramUserData | null;
  independent:boolean;
  listImmobilization:boolean;
  listItemRefs?:Record<string, HTMLDivElement | null>;
  onMenuHandler:(type:'single' | 'all' | 'unpinTop' | 'pinTop' | 'comment' | 'addGroup' | 'moveFolder',
    item?:TgUserListModel | IndependentTgUserItem,)=>void;
  handleUserClick:(item: TgUserListModel | IndependentTgUserItem,
    e?: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>;
  onChangeListItemRefs?:(key:string, listItemRef:HTMLDivElement)=>void;
};
type StateProps = {
  phoneCodeList:ApiCountryCode[];
};

interface BuildTgUserListItemProps extends Omit<OwnProps, 'list'>, StateProps {
  item:(IndependentTgUserItem | TgUserListModel);
  lang:LangFn;
  isFirst:boolean;
  onChangeIsFirst:(state:boolean)=>void;
}

const BuildTgUserListItem : FC<BuildTgUserListItemProps> = ({
  item, folderId, content, phoneCodeList, workData, independent, listImmobilization,
  onMenuHandler, handleUserClick, onChangeListItemRefs, lang, isFirst, onChangeIsFirst, listItemRefs,
}) => {
  // eslint-disable-next-line no-null/no-null
  const listItemRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!isFirst) { return; }
    const el = listItemRefs?.[`list-item-${workData?.phone}`];
    if (item?.phone === workData?.phone && el) {
      el.scrollIntoView({ block: 'end', inline: 'nearest' });
      onChangeIsFirst(false);
    }
  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [isFirst, item?.phone, listItemRefs, workData?.phone]);
  useEffect(() => {
    if (listItemRef.current) {
      onChangeListItemRefs?.(`list-item-${item?.phone}`, listItemRef.current);
    }
  }, [item?.phone, onChangeListItemRefs]);
  return (
    <ListItem
      ref={listItemRef}
      key={`${folderId}-${item.id}`}
      withPortalForMenu
      contextActions={independent && content === LeftColumnContent.SwitchTgUser2
        ? [{
          title: lang('MarkAsRead'),
          icon: 'readchats',
          handler: () => onMenuHandler('single', item),
        }, {
          title: 'All account marks have been read',
          icon: 'readchats',
          // className: 'all-read',
          handler: () => onMenuHandler('all'),
        },
        {
          ...item?.topUp
            ? {
              title: lang('UnpinFromTop'),
              icon: 'unpin',
              handler: () => onMenuHandler('unpinTop', item),
            } : {
              title: lang('PinToTop'),
              icon: 'pin',
              handler: () => onMenuHandler('pinTop', item),
            },
        },
        {
          title: '备注',
          icon: 'readchats',
          handler: () => onMenuHandler('comment', item),
        },
        {
          title: '新建分组',
          icon: 'grouped',
          handler: () => onMenuHandler('addGroup'),
        },
        {
          title: '将账号添加到分组',
          icon: 'group-filled',
          handler: () => onMenuHandler('moveFolder', item),
        }] : undefined}
      className={buildClassName('chat-item',
        'contact-list-item',
        workData?.phone === item?.phone && 'selected')}
      buttonClassName={buildClassName(
        workData?.phone === item?.phone && 'selected',
      )}
      // eslint-disable-next-line react/jsx-no-bind
      onClick={(e) => handleUserClick(item, e)}
    >
      <>
        <div
          className={buildClassName('status', 'status-clickable')}
        >
          <div className={buildClassName('Avatar', ' size-large ', 'peer-color-4')}>
            <div className="inner">
              {item?.avatar
                ? (
                  <img
                    src={item?.avatar}
                    className={buildClassName(cn.media, 'avatar-media')}
                    alt=""
                  />
                )
                : ((item?.firstName ?? item?.lastName) || '').trim().substring(0, 2)}

            </div>
            { item?.isOnline === 1 && <div className={buildClassName('avatar-online', 'avatar-online-shown')} />}
            {independent && (
              <BuildChatBadge
                count={item?.ContactIncreaseInfo?.unreadMsgCount}
                isIndependent={independent}
              />
            )}
          </div>
        </div>

        <div className="info">
          <div className="info-header">
            <div className="name">
              {item.comment}
              {item.comment && '('}
              {`${item?.firstName} ${item?.lastName}`}
              {item.comment && ')'}
            </div>
            {independent && (item as IndependentTgUserItem)?.date > 0
        && <LastMessageMeta message={{ date: (item as IndependentTgUserItem).date }} />}
          </div>

          <div className="info-content">
            <div className="identification">
              {item.region && (
                <span className="country-flag">{
                  renderText(isoToEmoji(item.region?.split('(')[1]?.slice(0, -1)?.toLowerCase()), ['hq_emoji'])
                }
                </span>
              )}
              {item?.phone && formatPhoneNumberWithCode(phoneCodeList, item?.phone)}
            </div>
            {!independent && (
              <BuildChatBadge
                count={item?.ContactIncreaseInfo?.unreadMsgCount}
                isIndependent={independent}
              />
            )}

            {item.topUp === 1 && (
              <div className="pin-icon">
                <Icon name="pin" className={buildClassName('mark-as-read')} />
              </div>
            )}
          </div>
          {
            independent && (
              <div className={buildClassName('info-new-msg', listImmobilization && 'show-msg')}>
                <TgUserLastMessage
                  lastMsgUserNickName={item?.lastMsgUserNickName || ''}
                  lastMsgContent={item?.lastMsgContent || ''}
                  lastMsgType={item?.lastMsgType}
                />
                {
                  (item?.ContactIncreaseInfo?.unreadMsgCount || 0) > 0 && (
                    <Button
                      round
                      ripple
                      size="smaller"
                      color="translucent"
                      ariaLabel="一键已读"
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={(e) => {
                        e.stopPropagation();
                        onMenuHandler('single', item);
                      }}
                      className={buildClassName('mark-as-read-btn')}
                    >
                      <Icon name="readchats" className={buildClassName('mark-as-read')} />
                    </Button>
                  )
                }

              </div>
            )
          }

        </div>
        <RippleEffect />
      </>
    </ListItem>
  );
};

const BuildTgUserList: FC<OwnProps & StateProps> = (props) => {
  const { list } = props;
  const lang = useOldLang();

  const [isFirst, setIsFirst] = useState(true);

  const onChangeIsFirst = useCallback((state:boolean) => {
    setIsFirst(state);
  }, []);
  return (list.map((item) => (
    <BuildTgUserListItem
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      item={item}
      lang={lang}
      isFirst={isFirst}
      onChangeIsFirst={onChangeIsFirst}
    />
  )));
};

export default memo(withGlobal<OwnProps>(
  (global): StateProps => {
    const {
      countryList: {
        phoneCodes: phoneCodeList,
      },
    } = global;
    return {
      phoneCodeList,
    };
  },
)(BuildTgUserList));
