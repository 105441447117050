import type { FC } from '../../../lib/teact/teact';
import React, {
  memo, useCallback, useEffect, useMemo, useRef, useState,
} from '../../../lib/teact/teact';
import { getActions, withGlobal } from '../../../global';

import type { TgFolder, TgFoldersTransForm } from '../../../api/request/Folder';
import type { IndependentTgUserItem } from '../../../lib/workGramClient/tgUser';
import type { WorkGramUserData } from '../../../util/praseWorkGramHash';
import type { ListItemType } from '../PeerPickerModel/PeerPickerSelectContainer';

import { selectTabState } from '../../../global/selectors';
import buildClassName from '../../../util/buildClassName';
import { formatDateTimeToString } from '../../../util/dates/dateFormat';
import { formatDateTime } from '../../../util/dates/formatDateCustom';
import { tgAutomaticReplyEdit } from '../../../api/request';

import useFlag from '../../../hooks/useFlag';
import useLastCallback from '../../../hooks/useLastCallback';
import useOldLang from '../../../hooks/useOldLang';

import CalendarModal from '../../common/CalendarModal';
import Icon from '../../common/icons/Icon';
import PickerItem from '../../common/pickers/PickerItem';
import Button from '../Button';
import ListItem from '../ListItem';
import Modal from '../Modal';
import PeerPickerModel from '../PeerPickerModel';
import RadioGroup from '../RadioGroup';
import TextArea from '../TextArea';
import AdvancedModeConfig from './AdvancedModeConfig';
import InputTextWrapper from './InputTextWrapper';

// import InputTextWrapper from './InputTextWrapper';
import '../Modal.scss';
import styless from '../../main/premium/GiveawayModal.module.scss';
import styles from './AutoResponseModel.module.scss';

type OwnProps = {
  isOpen: boolean;
  onChangeModel: (isOpen: boolean) => void;
  workData?: WorkGramUserData | null;
  echo?: (AutoReplyFormDataProps & EchoExtend) | null;
};
type StateProps = {
  tgUserList?: IndependentTgUserItem[];
  tgFolderList:TgFolder[];
};

const replyOptions = [
  { value: 'alway', label: '总是发送', subLabel: '总是发送' },
  { value: 'customTime', label: '自定义时间', subLabel: '设置一个自定义时间' },
];
const replyTypeoptions = [
  { value: 'yes', label: '只自动回复第一条消息', subLabel: '只自动回复第一条消息' },
  { value: 'no', label: '自动回复所有消息', subLabel: '自动回复所有消息' },
];
const replyModeoptions = [
  { value: 'ordinary', label: '普通模式', subLabel: '普通模式，直接设置一条回复内容' },
  { value: 'high', label: '高级模式', subLabel: '高级模式，可以设置回复条件' },
];
type HighModeConfigProps = {
  keyWords: string;
  keyContent: string | undefined;
  uuid: string;
};
export interface HighModeDataProps {
  content: string;
  keyWordsContent: HighModeConfigProps[];
  uuid: string;
}
export interface AutoReplyFormDataProps {
  accounts?: string[];
  tgFolders?: TgFoldersTransForm[];
  replayRole?: 'alway' | 'customTime';
  replayType?: 'yes' | 'no';
  startTime?: number | Date;
  endTime?: number | Date;
  replayMode?: 'ordinary' | 'high';
  replayContent?: string;
  highReplayContent?: HighModeDataProps[];
}
export interface EchoExtend {
  id?: number;
  memberId?: number;
  orgId?: number;
  status?: number;
  updatedAt?: string;
  createdAt?: string;
}

const initAutoReplyFormData: AutoReplyFormDataProps = {
  accounts: [],
  replayRole: 'alway',
  replayType: 'yes',
  startTime: new Date(),
  endTime: new Date(),
  replayMode: 'ordinary',
  replayContent: '',
  highReplayContent: [],
};
const WrapperAutoModel: FC<OwnProps & StateProps> = ({
  isOpen, onChangeModel, workData, echo, tgUserList, tgFolderList,
}) => {
  const [autoReplyFormData, setAutoReplyFormData] = useState<AutoReplyFormDataProps>(initAutoReplyFormData);
  const {
    dismissDialog,
    showNotification,
  } = getActions();
  const lang = useOldLang();
  const [isCalendarOpened, openCalendar, closeCalendar] = useFlag();
  const [isTgUserModalOpen, openTgUserModal, closeTgUserModal] = useFlag();
  // eslint-disable-next-line max-len
  const [selectTgUserMap, setSelectTgUserMap] = useState<Map<string, ListItemType<IndependentTgUserItem>>>(new Map());
  const [highReplayContentConfigStatus, setHighReplayContentConfigStatus] = useState(false);
  const handlehighReplayContentConfigStatusChange = useCallback((value: boolean) => {
    setHighReplayContentConfigStatus(value);
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);
  const handleCloseModel = useCallback(() => {
    onChangeModel(false);
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);
  useEffect(() => {
    if (echo && Reflect.ownKeys(echo)?.length !== 0) {
      // eslint-disable-next-line no-console
      console.log('handleAddOrDeletekeyWordsContent', 'echo', echo);
      setAutoReplyFormData(echo);
    }
  }, [echo]);
  useEffect(() => {
    if (echo) {
      const tgUserListFilter = tgUserList?.filter((tgUser) => echo.accounts?.includes(tgUser.phone));
      const newMap:Map<string, ListItemType<IndependentTgUserItem>> = new Map();
      tgUserListFilter?.forEach((item) => newMap.set(`${item.id}`,
        { data: item, id: item.id, name: item?.firstName || item?.username || item?.phone }));
      setSelectTgUserMap(newMap);
    }
  }, [tgUserList, echo]);

  const tgUserListData = useMemo(() => {
    const list = tgUserList || [];
    const groupedUsers = tgFolderList?.reduce(
      (groups: { [key:string]:ListItemType<TgFolder, IndependentTgUserItem> }, folder) => {
        const folderId = String(folder.id);
        if (!folderId) {
          return groups;
        }
        const groupsList = list.filter((item) => item.folderId === folderId);
        if (groupsList.length === 0) {
          return groups;
        }
        if (!groups[folderId]) {
          groups[folderId] = {
            children: [], id: folder.id, name: folder.folderName, data: folder,
          };
        }

        groups[folderId].children = groupsList
          .map((item) => ({
            id: item.id, name: item?.firstName || item?.username, subtitle: item.phone, data: item,
          }));
        return groups;
      }, {},
    ) || {};
    const resList = list.map(
      (item) => ({
        id: item.id, name: item?.firstName || item?.username, subtitle: item.phone, data: item,
      }),
    );
    const all = {
      id: 'all', name: '全部', data: { id: 'all', folderName: '全部' }, children: resList,
    };
    return { list: resList, folderList: [all, ...Object.values(groupedUsers)] };
  }, [tgFolderList, tgUserList]);

  const handleDoneModel = useCallback(async () => {
    // eslint-disable-next-line no-console
    console.log(autoReplyFormData, 'handleAddOrDeletekeyWordsContent');

    try {
      const newObj = {
        ...autoReplyFormData,
        memberId: workData?.params?.memberId,
        startTime: typeof autoReplyFormData.startTime! === 'string'
          ? autoReplyFormData.startTime
          : formatDateTime(autoReplyFormData.startTime!, 'YYYY-MM-DD HH:mm:ss'),
        endTime: typeof autoReplyFormData.endTime! === 'string'
          ? autoReplyFormData.endTime
          : formatDateTime(autoReplyFormData.endTime!, 'YYYY-MM-DD HH:mm:ss'),
      };

      if ((newObj.tgFolders?.length || 0) > 0) {
        const tgFolderUser = tgUserList
          ?.filter((item) => newObj.tgFolders?.find((folder) => folder.value === item.folderId)) || [];
        newObj.accounts = Array.from(new Set([...(newObj.accounts || []), ...tgFolderUser.map((item) => item.phone)]));
        delete newObj?.tgFolders;
      }

      newObj.accounts = newObj.accounts?.filter((item) => tgUserList?.find((tgUser) => tgUser.phone === item));
      // eslint-disable-next-line no-console
      if (newObj.replayMode === 'high' && !highReplayContentConfigStatus) {
        showNotification({ message: '请保存高级模式配置的全部回复内容' });
        return;
      }
      if (newObj.replayMode === 'ordinary' && newObj.replayContent === '') {
        showNotification({ message: '请设置普通模式配置的回复内容，回复内容不能为空' });
        return;
      }
      if (newObj.accounts?.length === 0) {
        showNotification({ message: '请选择 Telegram 账号,账号不能为空' });
        return;
      }
      const res = await tgAutomaticReplyEdit(newObj);
      // eslint-disable-next-line no-console
      console.log(res, 'res', 'handleAddOrDeletekeyWordsContent');
      showNotification({ message: res.message || '添加自动回复任务成功' });
      handleCloseModel();
      setAutoReplyFormData(initAutoReplyFormData);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error, 'handleAddOrDeletekeyWordsContent');
      if (typeof error === 'object' && error && 'message' in error) {
        showNotification({ message: (error as { message: string }).message || '添加自动回复任务失败' });
      } else {
        // 处理不是对象或没有 message 属性的错误
        showNotification({ message: '发生未知错误' });
      }
    }

    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [autoReplyFormData, highReplayContentConfigStatus]);
  const renderHeader = useCallback(() => (
    <div>
      <h3>自动回复设置</h3>
    </div>
  ), []);

  const removeTgAccount = useCallback((id: string, phone: string) => {
    setSelectTgUserMap((prev) => {
      const newMap = new Map(prev);
      newMap.delete(id);
      return newMap;
    });
    setAutoReplyFormData((prev) => {
      const newObj = { ...prev };
      newObj.accounts = newObj.accounts?.filter((item) => item !== phone);
      return newObj;
    });
  }, []);

  const renderAddAccount = useCallback(() => (
    <div className={styles.renderTg}>
      <div>
        <h4>Telegram 账号:</h4>
        {/* <br className={styles.renderBr} /> */}
        {selectTgUserMap.size <= 0 && (
          <InputTextWrapper
            label="Telegram 帐号选择"
            tabIndex={0}
            readOnly
          />
        )}
        {Array.from(selectTgUserMap.entries())?.map(([key, items]) => (
          <ListItem
            ripple
            key="ssss"
            className="chat-item-clickable contact-list-item"
            rightElement={(<Icon name="close" className={`${styless.removeChannel} ${styles.removeTgIcon}`} />)}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => removeTgAccount(key, items.data.phone)}
          >
            <PickerItem
              key={`${items.id}-${items.data.folderId}`}
              title={items.data.firstName}
              avatarElement={(
                <div className={buildClassName(styles.Avatar, ' size-large ', 'peer-color-4')}>
                  <div className={styles.inner}>
                    {items?.avatar
                      ? (
                        <img
                          src={items?.avatar}
                          className={styles.pickImage}
                          alt=""
                        />
                      )
                      : (items?.data.firstName || items?.data.username || '').trim().substring(0, 2)}
                  </div>
                </div>
              )}
              subtitle={items.data.phone}
              subtitleClassName=""
              disabled={false}
              inactive
              ripple
              inputPosition="end"
            />
          </ListItem>
        ))}

        <ListItem
          icon="add"
          ripple
          onClick={openTgUserModal}
          className={styles.addButton}
          iconClassName={styles.addChannel}
        >
          添加账号
        </ListItem>

      </div>
    </div>
  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  ), [selectTgUserMap]);
  const handleReplayRoleChange = useLastCallback((value: string, event: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-console
    console.log('handleReplayModeChange', value, event);
    setAutoReplyFormData((prev) => ({ ...prev, replayRole: value as 'alway' | 'customTime' }));
  });
  const handleReplayTypeChange = useLastCallback((value: string, event: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-console
    console.log('handleReplayModeChange', value, event);
    setAutoReplyFormData((prev) => ({ ...prev, replayType: value as 'yes' | 'no' }));
  });
  const isSelectStartTime = useRef<string>('');
  const handleSelectDateTime = useCallback((type: string) => {
    isSelectStartTime.current = type;
    openCalendar();
  }, []);

  const handleExpireDateChange = useLastCallback((date?: Date) => {
    // eslint-disable-next-line no-console
    console.log('handleExpireDateChange', date);
    if (isSelectStartTime.current === 'start') {
      if (autoReplyFormData.endTime && date && date > autoReplyFormData.endTime) {
        showNotification({
          message: '开始时间不能超过结束时间',
        });
        return;
      }
      autoReplyFormData.startTime = date;
    }
    if (isSelectStartTime.current === 'end') {
      if (autoReplyFormData.startTime && date && date < autoReplyFormData.startTime) {
        showNotification({
          message: '开始时间不能超过结束时间',
        });
        return;
      }
      autoReplyFormData.endTime = date;
    }
    closeCalendar();
  });
  const renderSettingConfig = useCallback(() => (
    <div className={`${styles.renderTg}`}>
      <h4>回复规则:</h4>
      {/* <br /> */}
      <RadioGroup
        selected={autoReplyFormData.replayRole}
        name="replayRole"
        options={replyOptions}
        onChange={handleReplayRoleChange}
      />
      <h4>消息回复类型:</h4>
      {/* <br /> */}
      <RadioGroup
        selected={autoReplyFormData.replayType}
        name="replayType"
        options={replyTypeoptions}
        onChange={handleReplayTypeChange}
      />
      {autoReplyFormData.replayRole === 'customTime' && (
        <div>
          <h4>自定义时间:</h4>
          {/* <br /> */}
          <Button
            ariaLabel="开始时间"
            className={buildClassName(styless.dateButton, 'expire-limit')}
            isText
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => handleSelectDateTime('start')}
          >
            <h3 className={styless.title}>
              开始时间
            </h3>
            {formatDateTimeToString(
              typeof autoReplyFormData.startTime! === 'string'
                ? new Date(autoReplyFormData.startTime!)
                : autoReplyFormData.startTime!, lang.code,
            )}
          </Button>
          <Button
            ariaLabel={lang('BoostGift.DateEnds')}
            className={buildClassName(styless.dateButton, 'expire-limit')}
            isText
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => handleSelectDateTime('end')}
          >
            <h3 className={styless.title}>
              结束时间
            </h3>
            {formatDateTimeToString(typeof autoReplyFormData.endTime! === 'string'
              ? new Date(autoReplyFormData.endTime!)
              : autoReplyFormData.endTime!, lang.code)}
          </Button>
          {/* <InputTextWrapper
            value={autoReplyFormData.startTime}
            label="开始时间"
            tabIndex={0}
            readOnly
          />
          <InputTextWrapper
            value={autoReplyFormData.endTime}
            label="结束时间"
            tabIndex={0}
            readOnly
          /> */}
        </div>
      )}
    </div>
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps, max-len
  ), [autoReplyFormData.replayRole, autoReplyFormData.replayType, autoReplyFormData.endTime, autoReplyFormData.startTime]);

  const handleReplayModeChange = useLastCallback((value: string) => {
    setAutoReplyFormData((prev) => ({ ...prev, replayMode: value as 'ordinary' | 'high' }));
  });
  const handleTextAreaChange = useLastCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // eslint-disable-next-line no-console
    console.log('handleReplayModeChange', e.target.value);
    setAutoReplyFormData((prev) => ({ ...prev, replayContent: e.target.value }));
  });
  const handleAdvanceReplayContentChange = useCallback((value: HighModeDataProps[]) => {
    setAutoReplyFormData((prev) => ({ ...prev, highReplayContent: value }));
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [autoReplyFormData.highReplayContent]);
  const renderAutoConfig = useCallback(() => (
    <div className={styles.renderTg}>
      <h4>自动回复内容配置:</h4>
      {/* <br /> */}
      <RadioGroup
        selected={autoReplyFormData.replayMode}
        name="replayMode"
        options={replyModeoptions}
        onChange={handleReplayModeChange}
      />
      {
        autoReplyFormData.replayMode === 'high'
          ? (
            <AdvancedModeConfig
              replayContent={autoReplyFormData.highReplayContent}
              onContentChange={handleAdvanceReplayContentChange}
              setHighReplayContentConfigStatus={handlehighReplayContentConfigStatusChange}
              highReplayContentConfigStatus={highReplayContentConfigStatus}
            />
          )
          : (
            <TextArea
              value={autoReplyFormData.replayContent}
              label="自动回复内容"
              maxLength={1000}
              onChange={handleTextAreaChange}
            />
          )
      }

    </div>
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps, max-len
  ), [autoReplyFormData.replayMode, autoReplyFormData.replayContent, autoReplyFormData.highReplayContent, highReplayContentConfigStatus, handleAdvanceReplayContentChange]);

  const handleSelectedChannelIdsChange = useLastCallback(
    (newSelectedIds: Map<string, ListItemType<IndependentTgUserItem>>) => {
      setSelectTgUserMap(newSelectedIds);
      setAutoReplyFormData(
        (pre) => ({ ...pre, accounts: Array.from(newSelectedIds.values()).map((i) => i.data?.phone) }),
      );
    },
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModel}
      // className="NewContactModal"
      className="add"
      onCloseAnimationEnd={dismissDialog}
    >
      <div>
        {renderHeader()}
        {renderAddAccount()}
        {renderSettingConfig()}
        {renderAutoConfig()}

        <div className="dialog-buttons mt-2">
          <Button
            isText
            className="confirm-dialog-button"
            onClick={handleDoneModel}
          >
            {lang('Done')}
          </Button>
          <Button isText className="confirm-dialog-button" onClick={handleCloseModel}>{lang('Cancel')}</Button>
        </div>
      </div>
      <CalendarModal
        isOpen={isCalendarOpened}
        isFutureMode
        withTimePicker
        onClose={closeCalendar}
        onSubmit={handleExpireDateChange}
        selectedAt={Date.now()}
        submitButtonLabel={lang('Save')}
      />

      <PeerPickerModel< TgFolder, IndependentTgUserItem>
        title="选择telegram账号"
        searchKey={['folderName', 'firstName', 'lastName', 'phone', 'username']}
        listData={tgUserListData.folderList}
        isOpen={isTgUserModalOpen}
        onClose={closeTgUserModal}
        initialSelectedMap={selectTgUserMap}
        onSelectedIdsConfirmed={handleSelectedChannelIdsChange}
      />
    </Modal>
  );
};

export default memo(withGlobal<OwnProps>(
  (global): StateProps => {
    const tabState = selectTabState(global);
    const { tgUserList, tgFolderList = [] } = tabState;
    return { tgUserList, tgFolderList };
  },
)(WrapperAutoModel));
