import type { FC } from '../../../../lib/teact/teact';
import React, {
  memo,
} from '../../../../lib/teact/teact';

import buildClassName from '../../../../util/buildClassName';

import './styles/TgUsersListGroupButton.scss';

type OwnProps = {
  count?:number;
  isIndependent?:boolean;
};

const BuildChatBadge: FC<OwnProps> = ({
  count,
  isIndependent,
}) => {
  return Boolean(count) && (
    <div className={buildClassName('ChatBadge unread', isIndependent && 'Independent-badge')}>
      {count > 999 ? '999+' : count}
    </div>
  );
};
export default memo(BuildChatBadge);
