import type { FC } from '../../../../lib/teact/teact';
import React, { memo, useCallback } from '../../../../lib/teact/teact';
import { withGlobal } from '../../../../global';

import type { ObserveFn } from '../../../../hooks/useIntersectionObserver';

// import buildClassName from '../../../util/buildClassName';
import '../Chat.scss';

/** 消息类型 */
export enum TTgUserLastMessageEnum {
  Unsupported = -1, // 当前客户端不支持类型
  TEXT = 1, // 文本
  PHOTO = 2, // 图片
  FILE = 3, // 文件
  VIDEO = 4, // 视频
  AUDIO = 5, // 音频
  EMOJIS = 6, // 表情
  Card = 7, // 名片
  TextPhoto = 8, // 文本加图文
  TextFile = 9, // 文本加文件
  Geo = 10, // 地图
  WebPage = 11, // 网页
  TextWebPage = 12, // 文本加网页
  Venue = 13, // 地点
  Game = 14, // 游戏
  Poll = 15, // 投票
  TextPoll = 16, // 文本加投票
  Dice = 17, // 骰子
  Story = 18, // 故事
  Giveaway = 19, // 赠品
}

export type OwnProps = {
  lastMsgType?:TTgUserLastMessageEnum;
  lastMsgContent:string;
  lastMsgUserNickName:string;
  observeIntersection?: ObserveFn;
  onDragEnter?: (chatId: string, folderId?:number) => void;
};

type StateProps = {
};

const TgUserLastMessage: FC<OwnProps & StateProps> = ({
  lastMsgType,
  lastMsgContent,
  lastMsgUserNickName,
}) => {
  const lastMsgContentRender = useCallback(() => {
    if (lastMsgType === TTgUserLastMessageEnum.TEXT) {
      return lastMsgContent;
    } else {
      return lastMsgType;
    }
  }, [lastMsgType, lastMsgContent]);
  return (
    <div className="content">
      {/* {
        lastMsgContent && (
          <>
            {lastMsgUserNickName.length > 8 ? `${lastMsgUserNickName.slice(0, 8)}...` : lastMsgUserNickName}：
            {lastMsgContentRender()}
          </>
        )
      } */}
      <div>
        {/* <img
          src="https://tgcloud.oss-ap-southeast-1.aliyuncs.com/tgcloud/attachment/2024-08-21/d3lhlit7dejvbijsrd.png"
          alt=""
          width={15}
          className={
            buildClassName('media-preview--image')
          }
          draggable={false}
        />
        &nbsp; */}
        {lastMsgUserNickName}
      </div>
      <div>{lastMsgContentRender()}</div>
    </div>
  );
};

export default memo(withGlobal<OwnProps>(
  (global, {
    lastMsgType = TTgUserLastMessageEnum.Unsupported,
    lastMsgContent,
    lastMsgUserNickName,
  }): StateProps => {
    return {
      lastMsgType,
      lastMsgContent,
      lastMsgUserNickName,
    };
  },
)(TgUserLastMessage));
