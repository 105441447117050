import type { FC } from '../../../../lib/teact/teact';
import React, {
  memo,
  useCallback,
} from '../../../../lib/teact/teact';

import type { MemberInfo } from '../../../../api/types';
import type { LeftColumnContent } from '../../../../types';
import type { WorkGramUserData } from '../../../../util/praseWorkGramHash';
import type { UnReadGroupProps, UnReadGroupTypeValues } from '../TgUserMenuItemSetting';

import { createClassNameBuilder } from '../../../../util/buildClassName';

import BuildMemberItem from './BuildMemberItem';
import BuildSearchItem from './BuildSearchItem';

import './styles/TgUsersListGroupButton.scss';

type OwnProps = {
  independent:boolean;
  workData:WorkGramUserData | null;
  content:LeftColumnContent;
  listImmobilization:boolean;
  loginUserTop:boolean;
  isOpenAutoReplyConfig:boolean;
  unReadGroupValue:UnReadGroupProps;
  independentMemberInfo:MemberInfo | null;
  handleMemberClick?:(id:number)=>void;
  handleSwitchTgUsers?:()=>void;
  listImmobilizationClick:()=>void;
  handleLoginUserTop:(e:React.ChangeEvent<HTMLInputElement>)=>void;
  handleReadAll:()=>void;
  unReadGroupClick:()=>void;
  handleUnReadGroupType:(type:UnReadGroupTypeValues, value:UnReadGroupTypeValues | undefined)=>void;
  handleUnReadOtherDataTimeChange:(date?:Date | number)=>void;
  onChangeSearchQuery?: (query: string) => void;
  handleIsOpenAutoReplyConfigChange:(e:React.ChangeEvent<HTMLInputElement>)=>void;
  getAutoReplyIsOpen?:()=>void;
  onImageError: (item: MemberInfo, independent: boolean) => void;
};
type StateProps = {
};

const cn = createClassNameBuilder('Avatar');
cn.media = cn('media');
cn.icon = cn('icon');

const IndependentHeader: FC<OwnProps & StateProps> = ({
  independent = false,
  workData,
  content,
  listImmobilization,
  loginUserTop,
  isOpenAutoReplyConfig,
  unReadGroupValue,
  independentMemberInfo,
  handleMemberClick,
  handleSwitchTgUsers,
  listImmobilizationClick,
  handleLoginUserTop,
  handleReadAll,
  unReadGroupClick,
  handleUnReadGroupType,
  handleUnReadOtherDataTimeChange,
  onChangeSearchQuery,
  handleIsOpenAutoReplyConfigChange,
  getAutoReplyIsOpen,
  onImageError,
}) => {
  const onIndependentMemberItemClick = useCallback((e:React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    handleSwitchTgUsers?.();
  }, [handleSwitchTgUsers]);

  return (
    <div className="independent-header">
      <BuildMemberItem
        item={independentMemberInfo}
        independent
        workData={workData}
        content={content}
        onClick={onIndependentMemberItemClick}
        handleMemberClick={handleMemberClick}
        onImageError={onImageError}
      />
      <BuildSearchItem
        listImmobilization={listImmobilization}
        independent={independent}
        workData={workData}
        loginUserTop={loginUserTop}
        content={content}
        isOpenAutoReplyConfig={isOpenAutoReplyConfig}
        unReadGroupValue={unReadGroupValue}
        listImmobilizationClick={listImmobilizationClick}
        handleLoginUserTop={handleLoginUserTop}
        handleReadAll={handleReadAll}
        unReadGroupClick={unReadGroupClick}
        handleUnReadGroupType={handleUnReadGroupType}
        handleUnReadOtherDataTimeChange={handleUnReadOtherDataTimeChange}
        onChangeSearchQuery={onChangeSearchQuery}
        handleIsOpenAutoReplyConfigChange={handleIsOpenAutoReplyConfigChange}
        getAutoReplyIsOpen={getAutoReplyIsOpen}
      />
    </div>
  );
};
export default memo(IndependentHeader);
