/**
 * hotgo.api.api.translate.TranslateReq
 */
export interface TranslateRes {
  model?: 'google' | 'qwen2:7b';
  /**
     * 原文
     */
  query?: string;
  /**
     * 原文语言,不传则自动识别
     */
  sl?: string;
  /**
     * 译文语言
     */
  tl?: string;
  [property: string]: any;
}
export interface TranslateReq {
  /**
     * 状态码
     */
  code?: number;
  /**
     * 数据集
     */
  data?: Data;
  error?: { [key: string]: any };
  /**
     * 提示消息
     */
  message?: string;
  /**
     * 业务编码
     */
  svcCode?: string;
  /**
     * 服务器时间戳
     */
  timestamp?: number;
  /**
     * 链路ID
     */
  traceID?: string;
  [property: string]: any;
}

/**
 * 数据集
 */
export interface Data {
  /**
     * 译文
     */
  trans?: string;
  [property: string]: any;
}
