import type { IndependentTgUserItem, TgUserUnread, TTgUserMsgNotice } from './tgUser';

export interface EventConfig<D = any, R = any> {
  data?:D;
  response?:R;
}
export enum UnReadGroupType {
  TOTALUNREAD = 'totalUnread',
  TODAYUNREAD = 'todayUnread',
  OTHERUNREAD = 'otherUnread',
}
export interface TgUserUnReadGroupInfo {
  [UnReadGroupType.TOTALUNREAD]:IndependentTgUserItem[];
  [UnReadGroupType.TODAYUNREAD]:IndependentTgUserItem[];
  [UnReadGroupType.OTHERUNREAD]:IndependentTgUserItem[];
}
export interface EmitEvent {
  'ping':EventConfig<undefined, undefined>;
  'error':EventConfig<undefined, undefined>;
  'tg/users':EventConfig<{ memberId?:number | string | null;phone:string | null }, IndependentTgUserItem[]>;
  'tg/usersUnread':EventConfig<{ memberId?:number | string | null;phone:string | null }, TgUserUnReadGroupInfo>;
  'tg/unread':EventConfig<undefined, TgUserUnread>;
  'tg/setTopUp':EventConfig<{ id:number;topUp:number }, IndependentTgUserItem>;
  'tg/setComment':EventConfig<{ id:number;comment:string }, { id:number;comment:string }>;
  'msgNotice':EventConfig<undefined, TTgUserMsgNotice>;
  'tg/portCount':EventConfig<undefined, { portCount:number }>;
}

export interface EmitData<E, D> {event:E;code:number;timestamp:number;data?:D}

export type EmitCallback = <E extends keyof EmitEvent>(event:E, data?:EmitEvent[E]['data']) => any;

/** 重连类型 */
export type ReconnectType = 'error' | 'ping' | undefined;
