import type { ChangeEvent } from 'react';
import type { FC } from '../../../lib/teact/teact';
import React, {
  memo, useCallback, useEffect, useMemo, useRef, useState,
} from '../../../lib/teact/teact';
import { getActions, withGlobal } from '../../../global';

import type { TgFolder } from '../../../api/request/Folder';
import type {
  GroupScriptListParams,
  GroupScriptModal,
  ResponseListData, ScriptRes, SysScriptListItem, SysScriptListItemVO,
  TgDataListModel,
  TgDataListParams,
} from '../../../api/types';
import type {
  MassTextingFormData,
  RequestContactsListParams,
  TgContactsListModel,
} from '../../../api/types/massTexting';
import type { IndependentTgUserItem } from '../../../lib/workGramClient/tgUser';
import type { ListItemType } from '../../ui/PeerPickerModel/PeerPickerSelectContainer';

import { selectTabState } from '../../../global/selectors/tabs';
import buildClassName from '../../../util/buildClassName';
import { debounce } from '../../../util/schedulers';
import {
  getAllScript, getDictDataOptions, getScriptGroupList, getTgDataList,
} from '../../../api/request';
import { getTgContactsList, tgMassTaskEdit } from '../../../api/request/messTexting';
import useMassTextingCheck from './useMassTextingCheck';

import useFlag from '../../../hooks/useFlag';
import useLastCallback from '../../../hooks/useLastCallback';
import useOldLang from '../../../hooks/useOldLang';

import Icon from '../../common/icons/Icon';
import PickerItem from '../../common/pickers/PickerItem';
import Button from '../../ui/Button';
import ErrorContainer from '../../ui/ErrorContainer';
import InputNumber from '../../ui/InputNumber';
import InputText from '../../ui/InputText';
import ListItem from '../../ui/ListItem';
import Modal from '../../ui/Modal';
import Pagination from '../../ui/Pagination';
import PeerPickerModel from '../../ui/PeerPickerModel';
import RadioGroup from '../../ui/RadioGroup';
import Select from '../../ui/Select';
import TextArea from '../../ui/TextArea';
import Tooltip from '../../ui/Tooltip';

import './MassTextingModal.scss';
import styless from '../../main/premium/GiveawayModal.module.scss';

type OwnProps = {
};

type StateProps = {
  isOpen: boolean;
  initFormData?:MassTextingFormData;
  tgUserList?: IndependentTgUserItem[];
  tgFolderList:TgFolder[];
};

const restrictList = ['已被限制', '新养号中', '养号中', '已双向'];

const MassTextingModal:FC<OwnProps & StateProps> = ({
  isOpen, initFormData, tgUserList, tgFolderList,
}) => {
  const {
    dismissDialog,
    updateMassTexting,
    showNotification,
  } = getActions();
  const lang = useOldLang();
  const [formData, setFormData] = useState<MassTextingFormData>({});

  const [isLoading, setIsLoading] = useState(false);

  const { formDataError, checkFormData } = useMassTextingCheck(formData);
  const [selectTgUserMap, setSelectTgUserMap] = useState< Map<string, ListItemType<IndependentTgUserItem>>>(new Map());
  const [selectTgDataMap, setSelectTgDataMap] = useState< Map<string, ListItemType<TgDataListModel>>>(new Map());
  const [selectScriptMap, setSelectScriptMap] = useState<
  {
    groupScript: Map<string, ListItemType<GroupScriptModal>>;
    greetScript: Map<string, ListItemType<SysScriptListItemVO>>;
    script: Map<string, ListItemType<SysScriptListItemVO>>; }
  >({ greetScript: new Map(), script: new Map(), groupScript: new Map() });

  // eslint-disable-next-line no-null/no-null
  const fileInputRef = useRef<HTMLInputElement>(null);

  // const [groupScriptMap, setGroupScriptMap] = useState<Map<string, ListItemType<GroupScriptModal>>>(new Map());

  const [
    selectTgContactsMap,
    setSelectTgContactsMap,
  ] = useState< Map<string, ListItemType<TgContactsListModel>>>(new Map());
  const [scriptModalOpen, openScriptModal, closeScriptModal] = useFlag();

  const [tgDataModalOpen, openTgDataModal, closeTgDataModal] = useFlag();
  const [accountModalOpen, openAccountModal, closeAccountModal] = useFlag();
  const [contactModalOpen, openContactModal, closeContactModal] = useFlag();
  const [scriptClass, setScriptClass] = useState<{ [key: string]: any }[]>([]);

  const [scriptModalType, setScriptModalType] = useState<'greetScript' | 'groupScript' | 'script'>('script');

  const [tgDataListParams, setTgDataListParams] = useState<TgDataListParams>({
    page: 1,
    pageSize: 20,
  });

  const [requestScriptParams, setRequestScriptParams] = useState<ScriptRes>({
    page: 1,
    pageSize: 20, // 每页条数
  });

  const [requestContactsListParams, setRequestContactsListParams] = useState<RequestContactsListParams>({
    page: 1,
    pageSize: 20,
  });

  const [groupScriptListParams, setGroupScriptListParams] = useState<GroupScriptListParams>({
    page: 1,
    pageSize: 20,
  });

  const [contactsData, setContactsData] = useState<ResponseListData<TgContactsListModel>>({});
  const [tgDataListData, setTgDataListData] = useState<ResponseListData<TgDataListModel>>({});
  const [scriptData, setScriptData] = useState<ResponseListData<SysScriptListItem>>({});
  const [groupScriptData, setGroupScriptData] = useState<ResponseListData<GroupScriptModal>>({});

  const fetchTgContacts = debounce(async (params:RequestContactsListParams = requestContactsListParams) => {
    setIsLoading(true);
    try {
      const res = await getTgContactsList({ ...params, userNameIsNotFlag: false });
      if (res.code === 0 && res.data) {
        setContactsData(res.data);
        setRequestContactsListParams({ ...params });
      }
    } catch (e) {
      showNotification({ message: '请求失败' });
    } finally {
      setIsLoading(false);
    }
  }, 300, false, true);

  const fetchTgDataList = debounce(async (params:TgDataListParams = tgDataListParams) => {
    setIsLoading(true);
    try {
      const res = await getTgDataList({ ...params });
      if (res.code === 0 && res.data) {
        setTgDataListParams({ ...params });
        setTgDataListData(res.data);
      }
    } catch (e) {
      showNotification({ message: '请求失败' });
    } finally {
      setIsLoading(false);
    }
  }, 300, false, true);

  const fetchScript = useCallback(async (type: string, params?: ScriptRes) => {
    setIsLoading(true);
    try {
      const res = await getAllScript(type, params);
      if (res.code === 0 && res.data) {
        setRequestScriptParams({ ...params });
        setScriptData(res.data);
      }
    } catch (e) {
      showNotification({ message: '请求失败' });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchGroupScript = useCallback(async (params:GroupScriptListParams = groupScriptListParams) => {
    setIsLoading(true);
    try {
      const res = await getScriptGroupList(params);
      if (res.code === 0 && res.data) {
        setGroupScriptListParams({ ...params });
        setGroupScriptData(res.data);
      }
    } catch (e) {
      showNotification({ message: '请求失败' });
    } finally {
      setIsLoading(false);
    }
  }, [groupScriptListParams]);

  const tgUserListData = useMemo(() => {
    const list = tgUserList || [];
    const groupedUsers = tgFolderList?.reduce(
      (groups: { [key:string]:ListItemType<TgFolder, IndependentTgUserItem> }, folder) => {
        const folderId = String(folder.id);
        if (!folderId) {
          return groups;
        }
        const groupsList = list.filter((item) => item.folderId === folderId);
        if (groupsList.length === 0) {
          return groups;
        }
        if (!groups[folderId]) {
          groups[folderId] = {
            children: [], id: folder.id, name: folder.folderName, data: folder,
          };
        }

        groups[folderId].children = groupsList
          .map((item) => ({
            id: item.id,
            name: item?.firstName || item?.username,
            subtitle: item.phone,
            data: item,
            avatar: item?.avatar,
            disabled: item?.actionLabel?.length > 0
              && item?.actionLabel?.some(
                (type) => restrictList.some(
                  (restrict) => type.indexOf(restrict) !== -1,
                ),
              ),
          }));
        return groups;
      }, {},
    ) || {};
    const resList = list.map(
      (item) => ({
        id: item.id,
        name: item?.firstName || item?.username,
        subtitle: item.phone,
        data: item,
        avatar: item?.avatar,
        disabled: item?.actionLabel?.length > 0
              && item?.actionLabel?.some(
                (type) => restrictList.some(
                  (restrict) => type.indexOf(restrict) !== -1,
                ),
              ),
      }),
    );

    const all = {
      id: 'all', name: '全部', data: { id: 'all', folderName: '全部' }, children: resList,
    };

    return { list: resList, folderList: [all, ...Object.values(groupedUsers)] };
  }, [tgFolderList, tgUserList]);

  const getAllScripts = useCallback(() => {
    if (!scriptClass.length) {
      getDictDataOptions({ types: ['script_class'] }).then((scriptClassRes) => {
        if (scriptClassRes?.data?.script_class?.length) {
          setScriptClass(scriptClassRes.data.script_class);
        }
      });
    }
  }, [scriptClass]);
  useEffect(() => {
    if (isOpen) {
      getAllScripts();
      if (initFormData) {
        setFormData(() => {
          updateMassTexting({ formData: undefined });
          return initFormData;
        });
      }
    }
  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [isOpen]);

  const handleCloseModel = useCallback(() => {
    setSelectScriptMap({ greetScript: new Map(), script: new Map(), groupScript: new Map() });
    setSelectTgUserMap(new Map());
    setSelectTgContactsMap(new Map());
    setSelectTgDataMap(new Map());
    updateMassTexting({ isShowModal: false, formData: undefined });
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!checkFormData()) {
      showNotification({ message: '表单校验失败，请检查表单是否填写正确' });
      return;
    }
    const formParams = { ...formData };
    if (typeof formData.tagets === 'string') {
      formData.tagets = formData.tagets.replace(/\s+/g, '');
      formParams.tagets = formData.tagets.replace(/[\r\n，]+/g, ',');
      formParams.tagets = formData.tagets.split(',');
    }
    try {
      const res = await tgMassTaskEdit(formParams);
      if (res.code === 0) {
        showNotification({
          message: '配置成功,立即生效执行',
        });
        updateMassTexting({ isShowModal: false, formData: undefined });
      }
    } catch (e) {
      showNotification({ message: (e as Error).message });
    }
  }, [checkFormData, formData]);

  const tgContactsListData = useMemo<ListItemType<TgContactsListModel>[]>(() => {
    if (contactsData?.list?.length) {
      return contactsData.list.map((item) => ({
        id: String(item.username),
        name: item?.firstName || item?.username || '',
        subtitle: `@${item.username}`,
        data: item,
        avatar: item?.avatar,
      }));
    }
    return [];
  }, [contactsData]);

  const onOpenScriptModal = useCallback(async () => {
    openScriptModal();
    if (formData.scriptMode === 1) {
      await fetchScript(formData?.scriptType ? String(formData?.scriptType) : '1', { pageSize: 20, page: 1 });
      setScriptModalType('script');
    } else {
      await fetchGroupScript({ pageSize: 20, page: 1 });
      setScriptModalType('groupScript');
    }
  }, [fetchGroupScript, fetchScript, formData.scriptMode, formData?.scriptType]);

  const onOpenTgDataModal = useCallback(async () => {
    openTgDataModal();
    await fetchTgDataList({ pageSize: 20, page: 1 });
  }, [fetchTgDataList]);

  const onOpenGreetScriptModal = useCallback(async () => {
    openScriptModal();
    await fetchScript('1', {
      pageSize: 20, page: 1, all: true, scriptClass: 1,
    });
    setScriptModalType('greetScript');
  }, [fetchScript]);

  const onOpenContactModal = useCallback(async () => {
    openContactModal();

    await fetchTgContacts({ pageSize: 20, page: 1 });
  }, [fetchTgContacts, openContactModal]);

  const removeTgAccount = useCallback((id: string, type:'accounts' | 'contacts') => {
    const newFormData = { ...formData };
    if (type === 'accounts') {
      setSelectTgUserMap((prev) => {
        const account = prev.get(id);
        newFormData.accounts = (newFormData?.accounts ?? []).filter((i) => i !== String(account?.data.id));
        prev.delete(id);
        return new Map(prev);
      });
    }
    if (type === 'contacts') {
      setSelectTgContactsMap((prev) => {
        const account = prev.get(id);
        newFormData.tagets = ((newFormData?.tagets ?? []) as string[]).filter((i) => i !== account?.data.username);
        prev.delete(id);
        return new Map(prev);
      });
    }
    setFormData(newFormData);
  }, [formData]);

  const handleSelectedAccountIdsChange = useLastCallback(
    (newSelectedIds:Map<string, ListItemType<IndependentTgUserItem>>) => {
      setSelectTgUserMap(newSelectedIds);
      const accounts = Array.from(newSelectedIds.values())?.map((i) => String(i.data.id)) || [];
      setFormData((pre) => (
        { ...pre, accounts, executeLimit: accounts.length }
      ));
    },
  );
  const handleSelectedTgDataIdsChange = useLastCallback(
    (newSelectedIds:Map<string, ListItemType<TgDataListModel>>) => {
      setSelectTgDataMap(newSelectedIds);
      setFormData((pre) => (
        { ...pre, dataId: Array.from(newSelectedIds.values())?.map((i) => i.data.id)[0] }
      ));
    },
  );
  const handleSelectedContactIdsChange = useLastCallback(
    (newSelectedIds:Map<string, ListItemType<TgContactsListModel>>) => {
      setSelectTgContactsMap(newSelectedIds);
      setFormData((pre) => (
        { ...pre, tagets: Array.from(newSelectedIds.values())?.map((i) => i.data.username as string) || [] }
      ));
    },
  );
  const handleSelectedScriptIdsChange = useCallback((
    newSelectedIds:Map<string, ListItemType<SysScriptListItemVO>> | Map<string, ListItemType<GroupScriptModal>>,
  ) => {
    if (scriptModalType === 'groupScript') {
      setSelectScriptMap((pre) => {
        const newPre = { ...pre, groupScript: newSelectedIds };
        return newPre;
      });
      setFormData((pre) => (
        {
          ...pre,
          groupScriptId: Array.from((newSelectedIds as Map<string, ListItemType<GroupScriptModal>>)
            .values())?.map((i) => i.data.id)[0] || undefined,
        }
      ));

      return;
    }
    if (scriptModalType === 'greetScript') {
      setSelectScriptMap((pre) => {
        const newPre = { ...pre, greetScript: newSelectedIds as Map<string, ListItemType<SysScriptListItemVO>> };
        return newPre;
      });
      setFormData((pre) => (
        {
          ...pre,
          greetScriptId: Array.from((newSelectedIds as Map<string, ListItemType<SysScriptListItemVO>>

          ).values())?.map((i) => i.data.id)[0] || undefined,
        }
      ));
    } else {
      setSelectScriptMap((pre) => {
        const newPre = { ...pre, script: newSelectedIds as Map<string, ListItemType<SysScriptListItemVO>> };
        return newPre;
      });
      setFormData((pre) => (
        { ...pre, scriptId: Array.from(newSelectedIds.values())?.map((i) => i.data.id)[0] || undefined }
      ));
    }
  }, [scriptModalType]);
  const onSearchScript = debounce(async (value: string) => {
    if (scriptModalType === 'groupScript') {
      const newParams:GroupScriptListParams = {
        ...groupScriptListParams, page: 1, name: value,
      };
      await fetchGroupScript(newParams);
    } else {
      const newParams = {
        ...requestScriptParams, page: 1, short: value, all: scriptModalType === 'greetScript',
      };
      await fetchScript(formData?.scriptType ? String(formData?.scriptType) : '', newParams);
    }
  }, 300, false, true);

  const onSearchTgDataList = useCallback((value: string) => {
    const newParams = {
      ...tgDataListParams, page: 1, name: value,
    };
    fetchTgDataList(newParams);
  }, [fetchTgDataList, tgDataListParams]);

  const onChangeTgDataPage = useCallback((page: number, pageSize: number) => {
    setTgDataListParams((pre) => {
      const newParams = { ...pre, page, pageSize };
      fetchTgDataList(newParams);
      return newParams;
    });
  }, [fetchTgDataList]);

  const onChangeScriptPage = useCallback((page: number, pageSize: number) => {
    if (scriptModalType === 'greetScript') {
      setGroupScriptListParams((pre) => {
        const newParams = { ...pre, page, pageSize };
        fetchGroupScript(newParams);
        return newParams;
      });
    } else {
      setRequestScriptParams((pre) => {
        const newParams = { ...pre, page, pageSize };
        fetchScript(formData?.scriptType ? String(formData?.scriptType) : '', newParams);
        return newParams;
      });
    }
  }, [fetchGroupScript, fetchScript, formData?.scriptType, scriptModalType]);

  const onChangeContactPage = useCallback((page: number, pageSize: number) => {
    setRequestContactsListParams((pre) => {
      const newParams = { ...pre, page, pageSize };
      fetchTgContacts(newParams);
      return newParams;
    });
  }, [fetchTgContacts]);

  const actionChange = useCallback((e:ChangeEvent<HTMLSelectElement>) => {
    const action = Number(e.target.value);
    setFormData((pre) => ({ ...pre, action, intervalTime: action === 2 ? 1 : undefined }));
  }, []);

  const getMapOnlyValue = useCallback((map:Map<string, ListItemType>) => {
    return Array.from(map.values())[0];
  }, []);

  const handleFileUpload = useCallback< React.ChangeEventHandler<HTMLInputElement>>((file) => {
    const input = file.target; // 获取input元素的引用
    const files = input.files;
    if (files?.length === 0) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      let text = event?.target?.result ?? '';
      text = (text as string).replace(/https:\/\/t\.me\/|@|t\.me|\+/g, '');
      if (formData.inputType === 2 && formData.scriptType === 3) {
        // eslint-disable-next-line no-control-regex
        const cleanedStr = (text as string)?.replace(/[^\x00-\xff]/g, '')?.replace(/[a-zA-Z]/g, '') || '';
        const splitArray = cleanedStr.replace(/[\r\n\s]+/g, ',');
        setFormData((pre) => ({ ...pre, tagets: splitArray }));
        return;
      }
      const cleanedValue = (text as string)?.replace(/[\r\n\s]+/g, ',');
      setFormData((pre) => ({ ...pre, tagets: cleanedValue }));
      input.value = '';
    };
    reader.readAsText(files![0], 'UTF-8');
  }, [formData.inputType, formData.scriptType]);

  const renderAddAccount = (type:'contacts' | 'accounts') => {
    const list = type === 'accounts' ? Array.from(selectTgUserMap.values()) : Array.from(selectTgContactsMap.values());
    const length = list.length;

    return (
      <div className="renderTg">
        <div>
          {type === 'accounts' && <h4>Telegram 账号</h4>}
          {type === 'contacts' && <h4>目标联系人</h4>}
          {length > 0 && (
            <div className="list-box">
              { list?.map((items) => {
                const title = items.data.firstName || items.data.username || '';
                return (
                  <ListItem
                    ripple
                    key={`${items.id}`}
                    className="chat-item-clickable contact-list-item"
                    rightElement={(
                      <Icon
                        name="close"
                        className={buildClassName(styless.removeChannel, 'removeTgIcon')}
                      />
                    )}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => removeTgAccount(String(items.id), type)}
                  >
                    <PickerItem
                      key={`${items.id}`}
                      title={title}
                      avatarElement={(
                        <div className={buildClassName('Avatar', ' size-large ', 'peer-color-4')}>
                          <div className="inner">
                            {items?.avatar
                              ? (
                                <img
                                  src={items?.avatar}
                                  className="pickImage"
                                  alt=""
                                />
                              )
                              : (title).trim().substring(0, 2)}
                          </div>
                        </div>
                      )}
                      subtitle={items.subtitle}
                      subtitleClassName=""
                      disabled={items.disabled}
                      inactive
                      ripple
                      inputPosition="end"
                    />
                  </ListItem>
                );
              })}
            </div>
          )}

          <ErrorContainer error={type === 'contacts' ? formDataError.tagets : formDataError.accounts}>
            <ListItem
              icon="add"
              ripple
              onClick={type === 'contacts' ? onOpenContactModal : openAccountModal}
              className="addButton"
              iconClassName="addChannel"
            >
              {type === 'contacts' ? '选择联系人' : '添加账号'}
            </ListItem>
          </ErrorContainer>
        </div>
      </div>
    );
  };
  /** 账号配置 */
  const renderAccountConfig = () => {
    const actionOptions = [
      { value: '1', label: '模式一:此模式为不设置时间间隔发送,可以快速发给联系人,触达率比较低' },
      { value: '2', label: '模式二:此模式可以设置时间间隔发送消息,发送时间较长，触达率较高' },
    ];
    return (
      <>
        <InputText
          value={formData.taskName}
          label="群发任务名称"
          maxLength={40}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={(e) => setFormData((pre) => ({ ...pre, taskName: e.target.value }))}
          error={formDataError.taskName}
        />
        {renderAddAccount('accounts')}
        <Select
          label="群发方案"
          value={formData?.action ? String(formData.action) : undefined}
          hasArrow={false}
          id="action"
          onChange={actionChange}
          error={formDataError.action}
        >
          {
            actionOptions.map(({ value, label }) => (
              <option
                key={value}
                value={value}
                selected={formData.action === Number(value)}
              >
                {label}
              </option>
            ))
          }
        </Select>
        {formData.action === 2
               && (
                 <InputNumber
                   id="executeLimit"
                   label="定时间隔(秒)"
                   value={formData.intervalTime}
                   min={1}
                   max={999}
                   error={formDataError.intervalTime}
                   // eslint-disable-next-line react/jsx-no-bind
                   onChange={(e) => setFormData((pre) => ({
                     ...pre,
                     intervalTime: e.target.value ? Number(e.target.value) : undefined,
                   }))}
                 />
               )}
        <InputNumber
          id="executeLimit"
          label="同时执行账号数量"
          value={formData.executeLimit}
          min={1}
          max={formData?.accounts?.length || 1}
          error={formDataError.executeLimit}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={(e) => setFormData((pre) => ({
            ...pre,
            executeLimit: e.target.value ? Number(e.target.value) : undefined,
          }))}
        />
        <InputNumber
          id="triggerLimit"
          label="账号限制重试次数"
          value={formData.triggerLimit}
          min={1}
          max={5}
          error={formDataError.triggerLimit}
          placeholder="次数越大，限制状态越精准，建议3-5次，避免风控"
          // eslint-disable-next-line react/jsx-no-bind
          onChange={(e) => setFormData((pre) => ({
            ...pre,
            triggerLimit: e.target.value
              ? Number(e.target.value)
              : undefined,
          }))}
        />
        <InputNumber
          id="sendLimit"
          label="每个账号发送上限数量"
          value={formData.sendLimit}
          min={0}
          error={formDataError.sendLimit}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={(e) => setFormData((pre) => ({
            ...pre,
            sendLimit: e.target.value ? Number(e.target.value) : undefined,
          }))}

        />
      </>
    );
  };
  const onInputTypeChange = useCallback(
    (value) => setFormData((pre) => {
      const newPre = { ...pre };

      if ((pre.inputType === 1 || pre.inputType === 2) && value === '3') {
        newPre.tagets = undefined;
      }
      if ((value === '1' || value === '2') && pre.inputType === 3) {
        newPre.dataId = undefined;
        setSelectTgDataMap(new Map());
      }
      newPre.inputType = Number(value);
      return newPre;
    }), [setFormData],
  );
  const renderInputType = () => {
    return (
      formData.tagetsType === 1 ? renderAddAccount('contacts')
        : formData.tagetsType === 2 || formData.tagetsType === 3 ? (
          <>
            <h4>选择录入方式</h4>
            <RadioGroup
              name="inputType"
              selected={String(formData.inputType)}
              options={[{ value: '1', label: '手动录入' },
                { value: '2', label: '上传文本录入' },
                { value: '3', label: '从数据仓库获取' }]}
              inlineBlock
              // eslint-disable-next-line react/jsx-no-bind
              onChange={onInputTypeChange}
            />

            {formData.inputType === 2 && (
              <>
                <ListItem
                  icon="document"
                  ripple
                  className="upload-file-button"
                  iconClassName="addChannel"
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => fileInputRef.current?.click()}
                >
                  导入文件
                </ListItem>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".txt"
                  hidden
                  onChange={handleFileUpload}
                />
              </>
            )}

            {(formData.inputType === 1 || formData.inputType === 2) && (
              <TextArea
                error={formDataError.tagets}
                placeholder="通过英文逗号（,）进行分隔"
                value={typeof (formData?.tagets ?? '') === 'string'
                  ? formData?.tagets as string ?? ''
                  : (formData.tagets as string[]).join(',')}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(e) => setFormData((pre) => ({ ...pre, tagets: e.target.value }))}
              />
            )}
            {formData.inputType === 3 && (
              <ErrorContainer error={formDataError.dataId}>
                <ListItem
                  icon={selectTgDataMap.size ? 'edit' : 'add'}
                  ripple
                  onClick={onOpenTgDataModal}
                  className="addButton"
                  iconClassName="addChannel"
                >
                  { getMapOnlyValue(selectTgDataMap)?.name || ' 选择数据仓库'}
                </ListItem>
              </ErrorContainer>

            )}
          </>
        ) : undefined
    );
  };

  const onTagetsTypeChange = useCallback(
    (value) => setFormData((pre) => {
      const newPre = { ...pre, tagetsType: Number(value) };
      newPre.tagets = undefined;
      setSelectTgContactsMap(new Map());
      return newPre;
    }), [setFormData],
  );

  /** 消息对象配置 */
  const renderMessageObjectConfig = () => {
    return (
      <>
        <h4>
          <Tooltip title="七天内登录过的用户为活跃用户">
            是否只给活跃的用户发送消息
          </Tooltip>
        </h4>
        <RadioGroup
          selected={String(formData.isActivity)}
          name="replayRole"
          inlineBlock
          options={[{ value: '1', label: '是' },
            { value: '0', label: '否' }]}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={(value) => setFormData((pre) => ({ ...pre, isActivity: Number(value) }))}
        />
        <h4>选择消息发送方式</h4>
        <RadioGroup
          selected={String(formData.tagetsType)}
          name="tagetsType"
          inlineBlock
          options={[
            // { value: '1', label: '联系人选择' }, //先注释掉
            { value: '2', label: '用户名' },
            { value: '3', label: '手机号码' },
          ]}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={onTagetsTypeChange}
        />
        {renderInputType()}
      </>
    );
  };

  const scriptOptions = useMemo<ListItemType<SysScriptListItemVO>[]>(() => {
    return scriptData?.list?.map((item) => ({
      id: item.id,
      name: item.short,
      data: item,

    })) as ListItemType<SysScriptListItemVO>[];
  }, [scriptData?.list]);

  const tgDataOptions = useMemo<ListItemType<TgContactsListModel>[]>(() => {
    return tgDataListData?.list?.map((item) => ({
      id: item.id,
      name: item.dataName,
      data: item,
      disabled: item?.dataType !== formData.tagetsType || item?.totalData === item?.totalUse,
    })) as ListItemType<TgContactsListModel>[];
  }, [formData.tagetsType, tgDataListData?.list]);

  const groupScriptOptions = useMemo<ListItemType<GroupScriptModal>[]>(() => {
    return groupScriptData?.list?.map((item) => ({
      id: item.id,
      name: item.name,
      data: item,

    })) as ListItemType<GroupScriptModal>[];
  }, [groupScriptData?.list]);

  const onSearchContact = useCallback((e:ChangeEvent<HTMLInputElement>) => {
    setRequestContactsListParams((pre) => {
      if (!e.target.id) return pre;
      const key = e.target.id as keyof RequestContactsListParams;
      pre[key] = e.target.value as any;
      fetchTgContacts({ ...pre, page: 1 });
      return pre;
    });
  }, [fetchTgContacts]);

  const onChangeScriptType = useCallback((value: string) => {
    setFormData((pre) => ({ ...pre, scriptType: Number(value) }));
    setSelectScriptMap((pre) => ({ ...pre, script: new Map() }));
  }, []);

  /** 群发内容配置 */
  const renderContentConfig = () => {
    const contentOptions: { name: keyof MassTextingFormData; label: string;tooltip?:string }[] = [
      { name: 'isExpression', label: '是否添加随机表情', tooltip: '在文本消息内容前面或后面加入一个随机表情' },
      { name: 'isSplit', label: '是否通过标点切割话术', tooltip: '随机通过逗号或句号切割文本话术，一句内容分多次发送' },
      { name: 'isRandomEdit', label: '是否随机伪编辑内容', tooltip: '随机先发送错误内容，再编辑发送话术内容' },
      { name: 'isGreet', label: '是否打招呼' },
      {
        name: 'isSensitiveHandle',
        label: '是否启用判断敏感消息并删除对话',
        tooltip: '启动此功能会判断联系人回复的消息是否带有厌恶，生气的情况，如果有会双向删除整个对话，避免联系人举报群发账号',
      },
    ];

    const map = formData.scriptMode === 1
      ? getMapOnlyValue(selectScriptMap.script)
      : getMapOnlyValue(selectScriptMap.groupScript);
    return (
      <>
        { contentOptions.map(({ name, label, tooltip }) => (
          <>
            <h4>
              <Tooltip title={tooltip || ''} showIcon={!!tooltip}>
                {label}
              </Tooltip>
            </h4>
            <RadioGroup
              selected={String(formData?.[name] ?? '')}
              name={name}
              inlineBlock
              options={[
                { value: '1', label: '开启' },
                { value: '2', label: '关闭' },
              ]}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={(value) => setFormData((pre) => ({ ...pre, [name]: Number(value) }))}
            />
            {name === 'isGreet' && formData.isGreet === 1 && (
              <>
                <h4>请选择打招呼方式</h4>
                <RadioGroup
                  selected={String(formData?.greetMode ?? '')}
                  name="greetMode"
                  inlineBlock
                  options={[
                    { value: '1', label: '使用官方表情' },
                    { value: '2', label: '使用具体话术' },
                  ]}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={(value) => setFormData((pre) => ({ ...pre, greetMode: Number(value) }))}
                />

                {formData?.greetMode === 2 && (
                  // greetScriptId
                  <ListItem
                    icon={selectScriptMap.greetScript.size ? 'edit' : 'add'}
                    ripple
                    onClick={onOpenGreetScriptModal}
                    className="addButton"
                    iconClassName="addChannel"
                  >
                    {getMapOnlyValue(selectScriptMap.greetScript)?.name ?? '请选择打招呼话术'}
                  </ListItem>
                )}
                <h4>营销话术发送条件</h4>
                <RadioGroup
                  selected={String(formData?.sendMode ?? '')}
                  name="sendMode"
                  inlineBlock
                  options={[
                    { value: '1', label: '延迟直接发送' },
                    { value: '2', label: '对方已读发送' },
                    { value: '3', label: '对方回复发送' },
                  ]}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={(value) => setFormData((pre) => ({ ...pre, sendMode: Number(value) }))}
                />
                {formData?.sendMode === 1 && (
                  <InputNumber
                    label="延时发送时间设置（秒）"
                    value={formData.delayTime}
                    min={0}
                    max={3600000}
                    error={formDataError.delayTime}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(e) => setFormData((pre) => ({
                      ...pre,
                      delayTime: e.target.value
                        ? Number(e.target.value) : undefined,
                    }))}
                  />
                )}
              </>
            )}
            {name === 'isSensitiveHandle' && formData.isSensitiveHandle === 1 && (
              <>
                <h4>启动判断敏感词并删除</h4>
                <RadioGroup
                  selected={String(formData?.delAllOrOnceMsg ?? '')}
                  name="delAllOrOnceMsg"
                  inlineBlock
                  options={[
                    { value: '1', label: '双向删除敏感词' },
                    { value: '2', label: '双向删除整个对话' },
                  ]}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={(value) => setFormData((pre) => ({ ...pre, delAllOrOnceMsg: Number(value) }))}
                />
                <h4>
                  <Tooltip title="敏感词判断方式">
                    敏感词判断方式
                  </Tooltip>

                </h4>
                <RadioGroup
                  selected={String(formData?.selectSensitiveWay ?? '')}
                  name="selectSensitiveWay"
                  inlineBlock
                  options={[
                    {
                      label: '个人敏感词库',
                      value: '1',
                    },
                    {
                      label: '系统敏感词库',
                      value: '2',
                    },
                    {
                      label: 'AI敏感词库',
                      value: '3',
                    },
                    {
                      label: '手动输入',
                      value: '4',
                    },
                  ]}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={(value) => setFormData((pre) => ({ ...pre, selectSensitiveWay: Number(value) }))}
                />
                {formData.selectSensitiveWay === 4 && (
                  <InputText
                    value={formData.sensitiveWords}
                    label="配置敏感词"
                    error={formDataError.sensitiveWords}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(e) => setFormData((pre) => ({ ...pre, sensitiveWords: e.target.value }))}
                    placeholder="每个敏感词用逗号隔开"
                  />
                )}

              </>
            )}
          </>
        ))}
        <h4>请选择营销话术库方式</h4>
        <RadioGroup
          selected={String(formData?.scriptMode ?? '')}
          name="scriptMode"
          inlineBlock
          options={[
            { value: '1', label: '具体话术选择' },
            { value: '2', label: '从话术组中随机选取' },
          ]}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={(value) => setFormData((pre) => ({ ...pre, scriptMode: Number(value) }))}
        />
        {formData.scriptMode === 1 && (
          <>
            <h4>请选择营销话术类型</h4>
            <RadioGroup
              selected={String(formData?.scriptType ?? '')}
              name="scriptType"
              inlineBlock
              options={[
                { value: '1', label: '个人话术管理' },
                { value: '2', label: '公司话术管理' },
                { value: '3', label: '系统话术管理' },
              ]}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={onChangeScriptType}
            />
          </>
        )}
        <ErrorContainer error={formData.scriptMode === 1 ? formDataError.scriptId : formDataError.scriptGroupId}>
          <ListItem
            icon={map ? 'edit' : 'add'}
            ripple
            onClick={onOpenScriptModal}
            className="addButton"
            iconClassName="addChannel"
          >
            {formData.scriptMode === 1
              ? map?.name ?? '选择营销话术'
              : map?.name ?? '选择营销话术组'}
          </ListItem>
        </ErrorContainer>
      </>
    );
  };
  const renderScriptItem = useLastCallback((item:
  ListItemType<SysScriptListItemVO> | ListItemType<GroupScriptModal>) => {
    if (scriptModalType === 'groupScript') {
      return (
        <div className="script-item">
          <div className="script-name">{item.name} </div>
        </div>
      );
    }
    const newScriptClass = scriptClass.find(
      (v) => v.key === (item as ListItemType<SysScriptListItemVO>)
        .data
        .scriptClass,
    )?.label;
    return (
      <div className="script-item" title={(item.data as SysScriptListItemVO).content}>
        <div className="script-name">{item.name} </div>
        <div className="script-class-tag">{newScriptClass}
        </div>
      </div>
    );
  });

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleCloseModel}
        className="MassTextingModal"
        onCloseAnimationEnd={dismissDialog}
        title="新建群发"
        noBackdropClose
        footer={(
          <div className="dialog-buttons mt-2">
            <Button
              isText
              className="confirm-dialog-button"
              onClick={handleSubmit}
            >
              {lang('Done')}
            </Button>
            <Button isText className="confirm-dialog-button" onClick={handleCloseModel}>{lang('Cancel')}</Button>
          </div>
        )}
      >
        {renderAccountConfig()}
        {renderMessageObjectConfig()}
        {renderContentConfig()}
      </Modal>
      <PeerPickerModel
        isLoading={isLoading}
        title="选择数据仓库"
        needChip={false}
        type="radio"
        isOpen={tgDataModalOpen}
        onClose={closeTgDataModal}
        listData={tgDataOptions}
        initialSelectedMap={selectTgDataMap}
        onSelectedIdsConfirmed={handleSelectedTgDataIdsChange}
        // eslint-disable-next-line react/jsx-no-bind
        renderBaseItem={(item) => (
          <div className={buildClassName('script-item')}>
            <div className="script-name">{item.name} </div>
          </div>
        )}
        onSearchChange={onSearchTgDataList}
        FooterChildren={(
          <Pagination
            total={tgDataListData?.totalCount ?? 0}
            page={tgDataListParams?.page ?? 1}
            pageSize={tgDataListParams?.pageSize ?? 20}
            onPageChange={onChangeTgDataPage}
          />
        )}
      />
      <PeerPickerModel
        isLoading={isLoading}
        title={scriptModalType === 'script' ? '选择营销话术' : scriptModalType === 'greetScript' ? '选择打招呼话术' : '选择话术组'}
        needChip={false}
        type="radio"
        isOpen={scriptModalOpen}
        onClose={closeScriptModal}
        listData={(scriptModalType === 'groupScript' ? groupScriptOptions : scriptOptions) as any ?? []}
        initialSelectedMap={(scriptModalType === 'script' ? selectScriptMap.script
          : scriptModalType === 'greetScript' ? selectScriptMap.greetScript : selectScriptMap.groupScript as any)}
        onSelectedIdsConfirmed={handleSelectedScriptIdsChange}
        renderBaseItem={renderScriptItem as any}
        onSearchChange={onSearchScript}
        FooterChildren={(
          <Pagination
            total={scriptData?.totalCount ?? 0}
            page={requestScriptParams?.page ?? 1}
            pageSize={requestScriptParams?.pageSize ?? 20}
            onPageChange={onChangeScriptPage}
          />
        )}
      />
      <PeerPickerModel
        isLoading={isLoading}
        title="选择telegram账号"
        searchKey={['folderName', 'firstName', 'lastName', 'phone', 'username']}
        isOpen={accountModalOpen}
        onClose={closeAccountModal}
        listData={tgUserListData.folderList}
        initialSelectedMap={selectTgUserMap}
        onSelectedIdsConfirmed={handleSelectedAccountIdsChange}
      />
      <PeerPickerModel
        isLoading={isLoading}
        type="checkbox"
        isOpen={contactModalOpen}
        onClose={closeContactModal}
        listData={tgContactsListData}
        initialSelectedMap={selectTgContactsMap}
        onSelectedIdsConfirmed={handleSelectedContactIdsChange}
        needSearch={false}
        title="选择联系人"
        HeaderChildren={(
          <div className="contact-search-container">
            <InputText
              id="userName"
              className="contact-search"
              label="请输入账号"
              maxLength={100}
              value={requestContactsListParams.userName}
              onChange={onSearchContact}
            />
            <InputText
              id="memberName"
              className="contact-search"
              label="请输入所属员工"
              maxLength={100}
              value={requestContactsListParams.memberName}
              onChange={onSearchContact}
            />
            <InputText
              id="tgAccount"
              className="contact-search"
              label="请输入所属账号"
              maxLength={100}
              value={requestContactsListParams.tgAccount}
              onChange={onSearchContact}
            />
          </div>
        )}
        FooterChildren={(
          <Pagination
            total={contactsData?.totalCount ?? 0}
            page={requestContactsListParams?.page ?? 1}
            pageSize={requestContactsListParams?.pageSize ?? 20}
            onPageChange={onChangeContactPage}
          />
        )}
      />
    </>
  );
};

export default memo(withGlobal<OwnProps>(
  (global): StateProps => {
    const tabState = selectTabState(global);
    const { tgUserList, tgFolderList = [] } = tabState;
    return {
      isOpen: global.massTexting.isShowModal,
      initFormData: global.massTexting.formData,
      tgUserList,
      tgFolderList,
    };
  },
)(MassTextingModal));
