import type {
  ExcludeAuthRequest,
  ExportAuthModel,
  MemberInfo, MemberListRequest, ResponseData, ResponseListData,
  TgUserListModel,
  TgUserListRequest,
} from '../types';

import requestServer from './axios';

/**
 * 获取成员列表
 */
export const getMemberList = (params: MemberListRequest) => {
  return requestServer.get<ResponseData<ResponseListData<MemberInfo>>>('/admin/member/list', { params });
};

/**
 * 获取成员列表
 */
export const getTgUserList = (params: TgUserListRequest) => {
  return requestServer.get<ResponseData<ResponseListData<TgUserListModel>>>('/tg/tgUser/list', { params });
};

/**
 * 获取成员列表
 */
export const tgUserExportAuth = (data: ExcludeAuthRequest) => {
  return requestServer.post<ResponseData<ExportAuthModel>>('/tg/tgUser/exportAuth', data);
};

/**
 * 获取未读数
 */
export const getUnreadCountReq = (data:{ phones:string[] }) => {
  return requestServer
    .post<ResponseData<{ unreadMap:{ [key:string]:number } }>>('/tg/tgUser/getUnreadCountReq', data);
};

/**
 * 全部已读
 */
export const tgUserReadAll = (
  data:{
    phones:string[];
    all:boolean; },
) => {
  return requestServer
    .post<ResponseData<{ unreadMap:{ [key:string]:number } }>>('/tg/tgUser/readAll', data);
};
