import type { FC } from '../../../../lib/teact/teact';
import React, {
  memo,
} from '../../../../lib/teact/teact';
import { withGlobal } from '../../../../global';

import type { MemberInfo } from '../../../../api/types';
import type { LeftColumnContent, LoadMoreDirection } from '../../../../types';
import type { WorkGramUserData } from '../../../../util/praseWorkGramHash';

import { selectTabState } from '../../../../global/selectors';
import buildClassName, { createClassNameBuilder } from '../../../../util/buildClassName';

import InfiniteScroll from '../../../ui/InfiniteScroll';
import BuildLoadingView from './BuildLoadingView';
import BuildMemberItem from './BuildMemberItem';

import './styles/TgUsersListGroupButton.scss';

type OwnProps = {
  className?:string;
  workData:WorkGramUserData | null;
  content:LeftColumnContent;
  memberListRef: React.RefObject<HTMLDivElement>;
  memberList: MemberInfo[];
  memberSet:MemberInfo[][];
  tgUsersGroupsFilter?:string;
  tgUsersFilter?:string;
  getMemberMore:({ direction }: { direction: LoadMoreDirection; noScroll?: boolean }) => void;
  handleKeyDown:(e: React.KeyboardEvent<any>) => void;
  handleMemberClick:(id:number)=>void;
};
type StateProps = {
  memberId?:number;
};

const cn = createClassNameBuilder('Avatar');
cn.media = cn('media');
cn.icon = cn('icon');

const BuildMemberListView: FC<OwnProps & StateProps> = ({
  className,
  workData,
  memberListRef,
  memberList,
  content,
  memberSet,
  tgUsersGroupsFilter,
  tgUsersFilter,
  getMemberMore,
  handleKeyDown,
  handleMemberClick,
}) => {
  // const onIndependentMemberItemClick = useCallback((e:React.MouseEvent<HTMLElement>) => {
  //   e.preventDefault();
  //   handleSwitchTgUsers?.();
  // }, [handleSwitchTgUsers]);

  return (
    <InfiniteScroll
      ref={memberListRef}
      items={memberList}
      onLoadMore={getMemberMore}
      className={buildClassName('MemberList', 'chat-list', 'custom-scroll', className)}
      onKeyDown={handleKeyDown}
    >
      {memberList?.length ? (
        memberList.map((item) => (
          item?.id && (
            <BuildMemberItem
              key={item.id}
              item={item}
              independent={false}
              workData={workData}
              content={content}
              handleMemberClick={handleMemberClick}
            />
          )
        ))
      ) : (
        <BuildLoadingView
          key="no-results"
          isShow={
            !!memberSet.length && memberList && !memberList.length
          }
          content={content}
          tgUsersGroupsFilter={tgUsersGroupsFilter}
          tgUsersFilter={tgUsersFilter}
        />
      ) }
    </InfiniteScroll>

  );
};
export default memo(withGlobal<OwnProps>(
  (global): StateProps => {
    const tabState = selectTabState(global);
    const {
      workGramSearch,
    } = tabState.globalSearch;

    return {
      memberId: workGramSearch?.memberId,

    };
  },
)(BuildMemberListView));
