import type { FC } from '../../../../lib/teact/teact';
import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from '../../../../lib/teact/teact';

import type { TgUserListModel } from '../../../../api/types';
import type { IndependentTgUserItem, TgUserGroupInfo } from '../../../../lib/workGramClient/tgUser';
import type { LeftColumnContent } from '../../../../types';
import type { WorkGramUserData } from '../../../../util/praseWorkGramHash';

import buildClassName from '../../../../util/buildClassName';

import BuildTgUserList from './BuildTgUserList';
import FolderTitle from './FolderTitle';

import './styles/TgUsersListGroupButton.scss';

type OwnProps = {
  groups:TgUserGroupInfo[];
  accordions:string[];
  tgUserGroupRef:React.RefObject<HTMLDivElement>;
  openAccordion:(folderId:string, isInit?:boolean)=>()=>void;
  folderTitleRefs:React.RefObject<Record<string, HTMLDivElement>>;
  independent:boolean;
  workData:WorkGramUserData | null;
  listImmobilization:boolean;
  content:LeftColumnContent;
  onMenuHandler:(type:'single' | 'all' | 'unpinTop' | 'pinTop' | 'comment' | 'addGroup' | 'moveFolder',
    item?:TgUserListModel | IndependentTgUserItem,)=>void;
  handleUserClick:(item: TgUserListModel | IndependentTgUserItem,
    e?: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>;
};

const BuildTgUserGroup: FC<OwnProps> = ({
  groups,
  accordions,
  tgUserGroupRef,
  folderTitleRefs,
  openAccordion,
  independent,
  workData,
  listImmobilization,
  content,
  onMenuHandler,
  handleUserClick,

}) => {
  const isFirst = useRef(false);

  const [listItemRefs, setListItemRefs] = useState<Record<string, HTMLDivElement | null>>({});

  /** 切换账号记录返回上一次的位置和打开的分组 */
  const init = useCallback(() => {
    if (tgUserGroupRef.current) {
      const openFolderIds = window.sessionStorage.getItem('openFolderIds') || '';
      if (openFolderIds) {
        isFirst.current = true;
        openAccordion(openFolderIds, true)();
      }
      sessionStorage.removeItem('openFolderIds');
    }
  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [openAccordion]);
  // useEffect(() => {
  //   if (isFirst.current && tgUserGroupRef.current) {
  //     const observer = new MutationObserver(() => {
  //       const tgUserGroupRefScrollTop = window.sessionStorage.getItem('tgUserGroupRefScrollTop') || 0;
  //       const scrollTop = Number(tgUserGroupRefScrollTop);
  //       if (scrollTop > 0) {
  //         tgUserGroupRef.current?.scrollTo(0, scrollTop);
  //       }
  //       sessionStorage.removeItem('tgUserGroupRefScrollTop');
  //       isFirst.current = false;
  //       observer.disconnect();
  //     });
  //     observer.observe(tgUserGroupRef.current, { childList: true, subtree: true });

  //     return () => observer.disconnect();
  //   }

  //   return () => {};
  // }, [accordions, tgUserGroupRef, workData?.phone]);

  useEffect(() => {
    init();
  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [tgUserGroupRef.current]);

  const onChangeListItemRefs = useCallback((key:string, listItemRef:HTMLDivElement) => {
    setListItemRefs((prev) => {
      const newRefs = { ...prev };
      newRefs[key] = listItemRef;
      return newRefs;
    });
  }, []);
  return (
    <div key="tg-user-group" className="tg-user-group" ref={tgUserGroupRef}>
      { groups.length > 0 && groups.map((item) => {
        const isOpen = accordions.includes(item.folderId);
        return (
          <div
            key={item.folderId}
            className="tg-user-box"
            ref={(el) => {
              if (el && folderTitleRefs.current) folderTitleRefs.current[item.folderId!] = el;
            }}
          >
            <FolderTitle item={item} accordions={accordions} openAccordion={openAccordion} />
            {
              isOpen
            && (
              <div className={buildClassName('tg-user-group-list', isOpen && 'expand-list')}>
                <BuildTgUserList
                  listItemRefs={listItemRefs}
                  list={item.list}
                  folderId={item.folderId}
                  independent={independent}
                  workData={workData}
                  listImmobilization={listImmobilization}
                  content={content}
                  onMenuHandler={onMenuHandler}
                  handleUserClick={handleUserClick}
                  onChangeListItemRefs={onChangeListItemRefs}
                />
              </div>
            )
            }
          </div>
        );
      })}

    </div>

  );
};
export default memo(BuildTgUserGroup);
