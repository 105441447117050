import type { ResponseData, ResponseListData } from '../types';
import type {
  MassTaskListRequest, MassTextingFormData, RequestContactsListParams, TgContactsListModel,
  TgMassTaskListModel,
} from '../types/massTexting';

import requestServer from './axios';

/**
 * 获取成员列表
 */
export const getTgContactsList = (params:RequestContactsListParams) => {
  return requestServer.get<ResponseData<ResponseListData<TgContactsListModel>>>('/tg/tgContacts/list', { params });
};

/**
 * 新增/修改群发任务
 */
export const tgMassTaskEdit = (data:MassTextingFormData) => {
  return requestServer.post<ResponseData>('/tg/tgMassTask/edit', data);
};

/**
 *
 * 获取群发任务列表
 */
export const getTgMassTaskList = (params:MassTaskListRequest) => {
  return requestServer.get <ResponseData<ResponseListData<TgMassTaskListModel>>>('/tg/tgMassTask/list', { params });
};

/**
 * 获取分享列链接
 */
export const getShareLinkDomain = () => {
  return requestServer.get <ResponseData<{ domain:string }>>('/tg/tgMassTask/shareLinkDomain');
};
/**
 * 重置群发分享密码
 */
export const massTaskReset = (data:{ taskId:number;password:string }) => {
  return requestServer.post < ResponseData>('/tg/tgMassTask/reset', data);
};
