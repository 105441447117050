/* eslint-disable react/jsx-no-bind */
import type { FC } from '../../../lib/teact/teact';
import React, {
  memo, useCallback, useEffect, useMemo,
  useRef,
  useState,
} from '../../../lib/teact/teact';
import { getActions, withGlobal } from '../../../global';

import type { TgFolder } from '../../../api/request/Folder';
import type {
  ApiCountryCode,
  MemberInfo, MemberListRequest,
  TgUserListModel,
  TgUserListRequest,
} from '../../../api/types';
import type { TgUserListSortKey } from '../../../types';
import type { UnReadGroupProps, UnReadGroupTypeValues } from './TgUserMenuItemSetting';
import { LeftColumnContent, LoadMoreDirection } from '../../../types';

import {
  GLOBAL_LIST_IMMOBILIZATION,
  TG_USER_FILTER_DATA, WORK_GRAM_HASH, WORK_GRAM_IV, WORK_GRAM_TOKEN_MEMBER_ID,
} from '../../../config';
import {
  type IndependentTgUserItem,
  type TgUserGroupInfo,
  type TgUserUnread, type TTgUserGroup,
} from '../../../lib/workGramClient/tgUser';
import { clearCaching } from '../../../global/cache';
import { selectTabState } from '../../../global/selectors';
import { MAIN_IDB_STORE, PASSCODE_IDB_STORE } from '../../../util/browser/idb';
import { createClassNameBuilder } from '../../../util/buildClassName';
import { getLocalizationData, setLocalizationData } from '../../../util/initLocalization';
import praseWorkGramHash from '../../../util/praseWorkGramHash';
import { getMemberViewInfo, tgAutomaticReplyStartStop, tgAutomaticReplyView } from '../../../api/request';
import {
  getMemberList,
  getTgUserList,
  tgUserExportAuth,
  tgUserReadAll,
} from '../../../api/request/tgUsers';

import useFlag from '../../../hooks/useFlag';
import useHistoryBack from '../../../hooks/useHistoryBack';
import useKeyboardListNavigation from '../../../hooks/useKeyboardListNavigation';
import useLastCallback from '../../../hooks/useLastCallback';
import useWorkGramClient from '../../../hooks/useWorkGramClient';

import { GetTgFoldersList, PostTgFoldersEdit } from '../../../api/request/Folder';
import CommentModal from '../../common/CommentModal';
import AddFolderModal from './TgUserList/AddFolderModal';
import BuildMemberListView from './TgUserList/BuildMemberListView';
import BuildTgUsersListView from './TgUserList/BuildTgUsersListView';
import MoveFolderModal from './TgUserList/MoveFolderModal';

import './TgUsersList.scss';

export type OwnProps = {
  /** 是否独立使用 */
  isIndependent?: boolean;
  tgUsersGroupsFilter?: string;
  tgUsersGroupFilterBackup?: string;
  tgUsersFilter?: string;
  isActive?: boolean;
  isShowExpansion?: boolean;
  content: LeftColumnContent;
  onReset?: () => void;
  onContentChange?: (content: LeftColumnContent) => void;
  onSearchQuery?: (query: string, isBackup?: boolean) => void;
  handleSwitchTgUsers?: () => void;
  className?: string;
};

type StateProps = {
  memberId?: number;
  listImmobilization?: boolean;
  tgUserListSort: TgUserListSortKey;
  phoneCodeList: ApiCountryCode[];
};
// const GLOBAL_LIST_IMMOBILIZATION = 'globalListImmobilization';

const cn = createClassNameBuilder('Avatar');
cn.media = cn('media');
cn.icon = cn('icon');

let memberId: number;

const groupAllId = '0';
const ISLOGINUSERTOP = 'isLoginUserTop';
const ISOPENAUTOREPLYCONFIG = 'isOpenAutoRelpyConfig';
const UNREADGROUPTYPE = 'unReadGroupType';
const UNREADGROUPOTHERDATATIME = 'unReadOtherDataTime';

const TgUsersList: FC<OwnProps & StateProps> = ({
  isIndependent,
  isActive,
  // isShowExpansion = true,
  tgUsersGroupsFilter,
  tgUsersGroupFilterBackup,
  tgUsersFilter,
  content: initialContent,
  className,
  onReset,
  onContentChange,
  onSearchQuery,
  handleSwitchTgUsers,
  memberId: globalMemberId,
  listImmobilization: globalListImmobilization,
  tgUserListSort,
}) => {
  const {
    updateTgUsersFetchingStatus,
    setWorkGramSearchDate,
    showNotification,
    savaTgUserList,
    savaTgFolderList,
    updateTgUsers,
  } = getActions();

  const [shouldRenderCommentModal, markRenderCommentModal, unmarkRenderCommentModal] = useFlag();
  const [shouldRenderAddFolderModal, markRenderAddFolderModal, unmarkRenderAddFolderModal] = useFlag();
  const [shouldRenderMoveFolderModal, markRenderMoveFolderModal, unmarkRenderMoveFolderModal] = useFlag();
  const [moveFolderUserInfo, setMoveFolderUserInfo] = useState<IndependentTgUserItem | TgUserListModel>();
  const [commentUserInfo, setCommentUserInfo] = useState<IndependentTgUserItem | TgUserListModel>();
  const [accordion, setAccordion] = useState<string[]>([groupAllId]);
  const [searchPreAccordion, setSearchPreAccordion] = useState<string[]>([]);
  const [loginUserTop, setLoginUserTop] = useState<boolean>(!!getLocalizationData(ISLOGINUSERTOP));
  // eslint-disable-next-line max-len
  const [isOpenAutoReplyConfig, setIsOpenAutoReplyConfig] = useState<boolean>(!!getLocalizationData(ISOPENAUTOREPLYCONFIG));

  const [memberSet, setMemberSet] = useState<MemberInfo[][]>([]);
  const [content, setContent] = useState<LeftColumnContent>(initialContent);
  // eslint-disable-next-line no-null/no-null
  // const [unreadMap, setUnreadMap] = useState<{ [key: string]: number } | null>(null);
  const [memberListParams, setMemberListParams] = useState<MemberListRequest>({
    page: 1,
    pageSize: 50,
    username: tgUsersGroupsFilter,
  });

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [unReadGroupList, setUnReadGroupList] = useState<TgUserGroupInfo & {
    type: UnReadGroupProps;
  }>({
    list: [],
    folderId: '',
    folderName: '',
    type: {
      totalUnread: getLocalizationData(UNREADGROUPTYPE)?.totalUnread || undefined,
      todayUnread: getLocalizationData(UNREADGROUPTYPE)?.todayUnread || undefined,
      otherUnread: getLocalizationData(UNREADGROUPTYPE)?.otherUnread || undefined,
    },
  });
  // eslint-disable-next-line max-len
  const [unReadOtherDataTimeValue, setUnReadOtherDataTimeValue] = useState(getLocalizationData(UNREADGROUPOTHERDATATIME));
  const workGramSocket = useWorkGramClient();

  // eslint-disable-next-line no-null/no-null
  const [independentMemberInfo, setIndependentMemberInfo] = useState<MemberInfo | null>(null);
  const memberList = useMemo(() => {
    return memberSet.flat();
  }, [memberSet]);
  useEffect(() => {
    const tgUserFilterData = window.sessionStorage.getItem(TG_USER_FILTER_DATA);
    if (tgUserFilterData) {
      const { memberId: id }: { memberId: string } = JSON.parse(tgUserFilterData);
      memberId = Number(id);
      window.sessionStorage.removeItem(TG_USER_FILTER_DATA);
    }

    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);

  const listImmobilization = useMemo(() => {
    const newListImmobilization = globalListImmobilization
      ?? getLocalizationData(GLOBAL_LIST_IMMOBILIZATION) === '1';
    //  localStorage.getItem(GLOBAL_LIST_IMMOBILIZATION) === '1';
    return newListImmobilization;
  }, [globalListImmobilization]);

  useEffect(() => {
    if (!isIndependent) setContent(initialContent);
  }, [isIndependent, initialContent]);
  const [isReady, setIsReady] = useState(false);
  const [isIndependentReady, setIsIndependentReady] = useState(false);

  const [tgUserSet, setTgUserSet] = useState<TgUserListModel[][]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [independentUserList, setIndependentUserList] = useState<IndependentTgUserItem[]>([]);

  const [tgUserParams, setTgUserListParams] = useState<TgUserListRequest>({
    page: 1,
    pageSize: 50,
    phone: tgUsersFilter,
    memberId,
  });
  const workData = useMemo(() => {
    try {
      const hash = JSON.parse(sessionStorage.getItem(WORK_GRAM_HASH) ?? '');
      const iv = JSON.parse(sessionStorage.getItem(WORK_GRAM_IV) ?? '');
      return praseWorkGramHash(iv, hash);
    } catch (e) {
      // eslint-disable-next-line no-null/no-null
      return null;
    }
  }, []);
  // eslint-disable-next-line no-null/no-null
  const tgUserListRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line no-null/no-null
  const memberListRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line no-null/no-null
  const tgUserGroupRef = useRef<HTMLDivElement>(null);
  const folderTitleRefs = useRef<Record<string, HTMLDivElement>>({});

  const [folderList, setFolderList] = useState<TgFolder[]>([]);
  const requestFolderList = useCallback(async () => {
    const res = await GetTgFoldersList({ page: 1, pageSize: 9999 });
    if (res?.code === 0) {
      const list = res?.data?.list || [];
      setFolderList(list);
      savaTgFolderList({ tgFolderList: list });
    } else {
      showNotification({
        message: res.message || '获取失败',
      });
    }
  }, []);
  useEffect(() => {
    if (workGramSocket && isIndependent) {
      requestFolderList();
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);
  const unReadGroupClick = useCallback(() => {
    workGramSocket?.emitEvent('tg/usersUnread', {
      memberId: workData?.params?.memberId,
      phone: workData?.phone || '',
    });
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [workGramSocket]);
  const handleUnReadGroupType = useCallback((type: UnReadGroupTypeValues, value: UnReadGroupTypeValues | undefined) => {
    if (value) {
      setUnReadGroupList((prev) => {
        setLocalizationData(UNREADGROUPTYPE, {
          ...prev.type,
          [type]: value,
        });
        return {
          ...prev,
          type: {
            ...prev.type,
            [type]: value,
          },
        };
      });
    } else {
      setUnReadGroupList((prev) => {
        setLocalizationData(UNREADGROUPTYPE, {
          ...prev.type,
          [type]: value,
        });
        return {
          ...prev,
          type: {
            ...prev.type,
            folderId: '',
            [type]: value,
          },
        };
      });
    }

    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);
  const handleUnReadOtherDataTimeChange = useCallback((date: Date | number | undefined) => {
    setUnReadOtherDataTimeValue(date);
    setLocalizationData(UNREADGROUPOTHERDATATIME, date);
  }, []);
  const filterBySameDay = useCallback((array: IndependentTgUserItem[], inputTimestamp) => {
    // 将输入的时间戳转换为Date对象，并设置时间为当天的开始（即午夜）
    const inputDate = new Date(inputTimestamp);
    const inputDay = inputDate.getUTCDate();

    return array.filter((item) => {
      // 将每个对象的时间戳转换为Date对象
      const itemDate = new Date(item.date * 1000); // 转换为毫秒
      const itemDay = itemDate.getUTCDate();
      // 比较两个日期是否相同
      return itemDay === inputDay + 1;
    });
  }, []);
  const getFormattedMonthDay = useCallback((timestamp?: Date | number) => {
    const date = timestamp ? new Date(timestamp) : new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}/${month}/${day}`;
  }, []);
  const groupedUsersDispose = useLastCallback((
    groupedUsers: TgUserGroupInfo[],
    list: (IndependentTgUserItem | TgUserListModel)[],
  ) => {
    let totalUnreadFilteredList: TgUserGroupInfo[] = [];
    let todayUnreadFilteredList: TgUserGroupInfo[] = [];
    let otherUnreadFilteredList: TgUserGroupInfo[] = [];
    const unReadList = list?.filter((item) => item?.ContactIncreaseInfo?.unreadMsgCount! > 0) || [];
    if (unReadGroupList.type.totalUnread) {
      totalUnreadFilteredList = [{
        list: unReadList,
        folderName: '全部未读',
        folderId: '0.1',
      }];
    } else {
      totalUnreadFilteredList = [];
    }
    if (unReadGroupList.type.todayUnread) {
      todayUnreadFilteredList = [{
        list: filterBySameDay(
          unReadList as IndependentTgUserItem[],
          new Date().setHours(0, 0, 0, 0),
        ) || [],
        folderName: `${getFormattedMonthDay()} 未读`,
        folderId: '0.2',
      }];
    } else {
      todayUnreadFilteredList = [];
    }
    if (unReadGroupList.type.otherUnread) {
      otherUnreadFilteredList = [{
        list: filterBySameDay(
          unReadList as IndependentTgUserItem[],
          unReadOtherDataTimeValue,
        ) || [],
        folderName: `${getFormattedMonthDay(unReadOtherDataTimeValue)} 未读`,
        folderId: '0.3',
      }];
    } else {
      otherUnreadFilteredList = [];
    }

    const newGroupedUsers = [
      ...totalUnreadFilteredList,
      ...todayUnreadFilteredList,
      ...otherUnreadFilteredList,
      ...groupedUsers,
    ];

    return newGroupedUsers;
  });

  const tgUserList = useMemo(() => {
    let list: (IndependentTgUserItem | TgUserListModel)[] = isIndependent ? [...independentUserList] : tgUserSet.flat();
    let specialItemIndex = -1;
    let specialItem;
    let currentTgUser;

    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (isIndependent) {
        item.isOnline = 1;
      }
      item.ContactIncreaseInfo = isIndependent
        ? { unreadMsgCount: (item as IndependentTgUserItem).unreadMsgCount }
        : (item.ContactIncreaseInfo || {});

      if (isIndependent && searchQuery
        && ((item.phone?.indexOf(searchQuery) === -1)
          && (item.username?.indexOf(searchQuery) === -1)
          && `${item?.firstName || ''} ${item?.lastName || ''}`.toLowerCase().indexOf(searchQuery.toLowerCase()) === -1
        )) {
        list.splice(i, 1);
        i--;
        continue;
      }

      // 检查并记录特殊项
      if (item.phone === workData?.phone) {
        currentTgUser = item;
        if (loginUserTop) {
          specialItem = item;
          specialItemIndex = i;
        }
      }
    }

    if (isIndependent) {
      if (tgUserListSort !== 'default') {
        const sortedListWithoutSpecialItem = list.filter((_, index) => index !== specialItemIndex);
        sortedListWithoutSpecialItem.sort((a, b) => {
          const unreadMsgCountA = a?.ContactIncreaseInfo?.unreadMsgCount ?? 0;
          const unreadMsgCountB = b?.ContactIncreaseInfo?.unreadMsgCount ?? 0;

          if (a.topUp && b.topUp && a?.setTopUpTime && b?.setTopUpTime) {
            return new Date(b?.setTopUpTime).getTime() - new Date(a?.setTopUpTime).getTime();
          } else if (a.topUp && !b.topUp) {
            return -1;
          } else if (!a.topUp && b.topUp) {
            return 1;
          }
          /** 以firstName进行排序 */
          if (tgUserListSort === 'firstName' && a.firstName && b.firstName) {
            return a.firstName?.localeCompare(b.firstName || '', 'en');
          }
          // 当排序依据是 'unreadMsgCount' 时，首先按未读消息数量降序排序
          if (tgUserListSort === 'unreadMsgCount') {
            if (unreadMsgCountB !== unreadMsgCountA) {
              return unreadMsgCountB - unreadMsgCountA;
            }
            // 如果未读消息数量相同，则按日期降序排序
            const dateA = (a as IndependentTgUserItem)?.date ?? 0;
            const dateB = (b as IndependentTgUserItem)?.date ?? 0;
            return dateB - dateA;
          } else if ((unreadMsgCountA > 0 && unreadMsgCountB > 0) || (unreadMsgCountA === 0 && unreadMsgCountB === 0)) {
            // 如果两个项目都有未读消息或都没有未读消息，则按日期降序排序
            const dateA = (a as IndependentTgUserItem)?.date ?? 0;
            const dateB = (b as IndependentTgUserItem)?.date ?? 0;
            return dateB - dateA;
          } else if (unreadMsgCountA > 0) {
            // 如果项目 A 有未读消息而项目 B 没有，则项目 A 排在前面
            return -1;
          } else {
            // 如果项目 B 有未读消息而项目 A 没有，则项目 B 排在前面
            return 1;
          }
        });

        if (specialItemIndex !== -1 && specialItem) {
          list = [specialItem, ...sortedListWithoutSpecialItem];
        } else {
          list = sortedListWithoutSpecialItem;
        }
      }
    } else if (specialItemIndex > 0 && specialItem) {
      list.splice(specialItemIndex, 1);
      list.unshift(specialItem);
    }
    const groupedUsers = folderList.reduce((groups: TTgUserGroup, folder) => {
      const folderId = String(folder.id);
      if (!folderId) {
        return groups;
      }
      const groupsList = list.filter((item) => item.folderId === folderId);
      if (groupsList.length === 0) {
        return groups;
      }
      if (!groups[folderId]) {
        groups[folderId] = { list: [], folderName: folder?.folderName || '', folderId };
      }

      groups[folderId].list = groupsList;
      return groups;
    }, {});

    const seenIds: number[] = [];
    // 去除有相同分组的账号
    const filteredList = list.filter((item) => {
      const bool = !seenIds.includes(item.id);
      if (bool) seenIds.push(item.id);
      return bool;
    });
    const all = { [groupAllId]: { list: filteredList, folderName: '全部', folderId: groupAllId } };
    const groupedUserArray = Object.values({ ...all, ...groupedUsers });
    savaTgUserList({
      tgUserList: filteredList as IndependentTgUserItem[] || [],
      folderUserList: groupedUserArray,
      currentTgUser: currentTgUser as IndependentTgUserItem,
    }); // 保存全局tg用户列表
    return {
      list: filteredList,
      groupedUsers: groupedUsersDispose(groupedUserArray, filteredList),
    };
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [
    independentUserList,
    isIndependent,
    searchQuery,
    tgUserListSort,
    tgUserSet,
    workData?.phone,
    folderList,
    loginUserTop,
    unReadGroupList,
    unReadOtherDataTimeValue,
  ]);

  const handleLoginUserTop = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setLoginUserTop(e.target.checked);
    setLocalizationData(ISLOGINUSERTOP, e.target.checked);
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);
  const handleIsOpenAutoReplyConfigChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const res = await tgAutomaticReplyStartStop(e.target.checked ? 'start' : 'stop', {
        memberId: workData?.params?.memberId!,
      });
      setIsOpenAutoReplyConfig(e.target.checked);
      setLocalizationData(ISOPENAUTOREPLYCONFIG, e.target.checked);
      showNotification({ message: res.message || '启动自动回复任务成功' });
    } catch (error) {
      if (typeof error === 'object' && error && 'message' in error) {
        showNotification({ message: (error as { message: string }).message || '启动自动回复任务失败' });
      } else {
        // 处理不是对象或没有 message 属性的错误
        showNotification({ message: '发生未知错误' });
      }
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);
  const getAutoReplyIsOpen = useCallback(async () => {
    try {
      const res = await tgAutomaticReplyView({
        memberId: workData?.params?.memberId!,
      });
      setIsOpenAutoReplyConfig(res.data?.status === 1);
      setLocalizationData(ISOPENAUTOREPLYCONFIG, res.data?.status === 1);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error, 'tgAutomaticReplyView');
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);
  const setUnreadCountAndDate = useCallback((data: TgUserUnread) => {
    setIndependentUserList((pre) => {
      const newValue = [...pre];
      const map = new Map<keyof TgUserUnread, TgUserUnread[keyof TgUserUnread]>(Object.entries(data));

      for (let i = 0; i < newValue.length; i++) {
        const item = newValue[i];
        const unreadData = map.get(item.phone);
        if (unreadData) {
          item.unreadMsgCount = unreadData.unreadMsgCount;
          item.date = unreadData?.date > 0 ? unreadData.date : item.date;
          map.delete(item.phone);
        }
        if (!map.size) break;
      }
      return newValue;
    });
    setUnReadGroupList((pre) => {
      const newValue = { ...pre };
      const map = new Map<keyof TgUserUnread, TgUserUnread[keyof TgUserUnread]>(Object.entries(data));
      for (let i = 0; i < newValue.list?.length; i++) {
        const item = newValue.list[i] as IndependentTgUserItem;
        const unreadData = map.get(item.phone);
        if (unreadData) {
          item.unreadMsgCount = unreadData.unreadMsgCount;
          item.ContactIncreaseInfo = { ...item.ContactIncreaseInfo, unreadMsgCount: unreadData.unreadMsgCount };
          item.date = unreadData?.date > 0 ? unreadData.date : item.date;
          map.delete(item.phone);
        }
        if (!map.size) break;
      }
      return newValue;
    });
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);

  const tempTgUserList = useRef<IndependentTgUserItem[]>([]);
  useEffect(() => {
    if (workGramSocket && isIndependent) {
      workGramSocket?.subscribeToEvent('tg/users', (data) => {
        if (data.code === 0) {
          setIndependentUserList(data?.data ? data.data : []);
          setIsIndependentReady(true);
          updateTgUsers({ tgUserList: data?.data || [], phone: workData?.phone });
          tempTgUserList.current = data?.data ? data.data : [];
          // savaTgUserList({ tgUserList: data?.data || [] }); // 保存全局tg用户列表
        }
      })('tg/unread', (data) => {
        if (data.code === 0 && data?.data) {
          setUnreadCountAndDate(data.data);
        }
      })('tg/setTopUp', (data) => {
        if (data.code === 0 && data?.data) {
          setIndependentUserList((pre) => {
            const newValue = [...pre];
            for (let i = 0; i < newValue.length; i++) {
              const item = newValue[i];
              if (item.id === data?.data?.id) {
                item.setTopUpTime = data?.data?.setTopUpTime;
                item.topUp = data?.data?.topUp;
                break;
              }
            }
            return newValue;
          });
        }
      })('tg/setComment', (data) => {
        if (data.code === 0 && data?.data) {
          setIndependentUserList((pre) => {
            const newValue = [...pre];
            for (let i = 0; i < newValue.length; i++) {
              const item = newValue[i];
              if (item.id === data?.data?.id) {
                item.comment = data?.data?.comment;
                break;
              }
            }
            return newValue;
          });
        }
      })('msgNotice', (data) => {
        if (data.code === 0 && data?.data && data?.data?.isUserPeer === 1) {
          const {
            sender, message, receiver, msgType,
          } = data?.data || {};
          setIndependentUserList((pre) => {
            const newValue = [...pre];
            for (let i = 0; i < newValue.length; i++) {
              const item = newValue[i];
              if (item.phone === receiver?.phone) {
                item.lastMsgUserNickName = `${sender?.firstName} ${sender?.lastName}`;
                item.lastMsgContent = message || '';
                item.lastMsgType = msgType;
                break;
              }
            }
            return newValue;
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [workGramSocket, isIndependent, unReadGroupList]);

  useEffect(() => {
    if (isIndependent) {
      const workGramMemberId = window.sessionStorage.getItem(WORK_GRAM_TOKEN_MEMBER_ID);
      const id = workData?.params?.memberId || globalMemberId || workGramMemberId || workData?.params?.operateMemberId;
      if (id) {
        getMemberViewInfo(Number(id)).then((res) => {
          if (res.code === 0) {
            setIndependentMemberInfo(res.data!);
          }
        });
      }
    }
  }, [workData, initialContent, isIndependent, globalMemberId]);

  const fetchMemberList = useCallback(async (
    params: MemberListRequest = { ...memberListParams, username: tgUsersGroupsFilter },
    reset: boolean = false,
  ) => {
    if (content !== LeftColumnContent.SwitchTgUser1) return;
    if (!isReady) {
      setIsReady(true);
    }
    updateTgUsersFetchingStatus?.({ tgUsers: true });
    const res = await getMemberList({ ...params, selectSelf: true });
    if (res.code === 0) {
      setMemberSet((prev) => {
        const newSet = reset ? [] : [...prev];
        newSet[(params?.page ?? 1) - 1] = res?.data?.list ?? [];
        return newSet;
      });
    }
    updateTgUsersFetchingStatus?.({ tgUsers: false });
  }, [memberListParams, tgUsersGroupsFilter, content, isReady]);

  const fetchTgUserList = useCallback(async (
    params: TgUserListRequest = { ...tgUserParams, phone: tgUsersFilter },
    reset: boolean = false,
  ) => {
    if (isIndependent) {
      params.memberId = workData?.params?.memberId || globalMemberId;
      workGramSocket?.emitEvent('tg/users', {
        memberId: params!.memberId!,
        phone: workData?.phone!,
      });
      return;
    }
    if (!params?.memberId) {
      params.memberId = memberId;
    }
    if (!isIndependent) updateTgUsersFetchingStatus?.({ tgUsers: true });
    const res = await getTgUserList({ ...params, accountStatus: 0 });
    if (res.code === 0) {
      setTgUserSet((prev) => {
        const newSet = reset ? [] : [...prev];
        newSet[(params?.page ?? 1) - 1] = res?.data?.list ?? [];
        return newSet;
      });
    }
    updateTgUsersFetchingStatus?.({ tgUsers: false });
  }, [globalMemberId, isIndependent, tgUserParams, tgUsersFilter, workData, workGramSocket]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!independentUserList || independentUserList.length === 0) {
        await fetchTgUserList();
      } else {
        clearInterval(intervalId);
      }
    }, 5000); // 5 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [fetchTgUserList, independentUserList]);
  // const fetchUnread = useCallback(async () => {
  //   try {
  //     const res = await getUnreadCountReq({ phones: tgUserList.map((tgUser) => tgUser.phone!) });
  //     if (res.code === 0 && res.data?.unreadMap) {
  //       setUnreadMap(res.data.unreadMap);
  //     }
  //   } catch (error) {
  //     // eslint-disable-next-line no-console
  //     console.error(error);
  //   }
  // }, [setUnreadMap, tgUserList]);

  // useEffect(() => {
  //   let intervalId: NodeJS.Timeout;
  //   if (isIndependent) {
  //     intervalId = setInterval(() => {
  //       fetchUnread();
  //     }, 10000);
  //   }
  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //     }
  //   };
  // }, [fetchUnread, isIndependent]);

  const getMemberMore = useLastCallback(({
    direction,
  }: { direction: LoadMoreDirection; noScroll?: boolean }) => {
    if (direction === LoadMoreDirection.Backwards) {
      setMemberListParams((prev) => {
        const newParams = { ...prev, page: isReady ? (prev?.page ?? 1) + 1 : prev?.page ?? 1 };
        if (!memberSet.length || memberSet[memberSet.length - 1]?.length >= (newParams?.pageSize ?? 0)) {
          fetchMemberList(newParams);
          return newParams;
        } else {
          return prev;
        }
      });
    }
  });

  const getTgUserMore = useLastCallback(({
    direction,
  }: { direction: LoadMoreDirection; noScroll?: boolean }) => {
    if (direction === LoadMoreDirection.Backwards) {
      setTgUserListParams((prev) => {
        const newParams = { ...prev, page: isReady ? (prev?.page ?? 1) + 1 : prev?.page ?? 1 };
        if (isIndependent) {
          if (!independentUserList.length && !isIndependentReady) fetchTgUserList(newParams);
          return newParams;
        }
        if (!tgUserSet.length || tgUserSet[tgUserSet.length - 1]?.length >= (newParams?.pageSize ?? 0)) {
          fetchTgUserList(newParams);
          if (!isReady) {
            setIsReady(true);
          }
          return newParams;
        } else {
          return prev;
        }
      });
    }
  });

  const debounceDelay = 300;
  // eslint-disable-next-line no-null/no-null
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      setMemberListParams((prev) => {
        if (tgUsersGroupsFilter === prev.username) return prev;
        const newParams = { ...prev, page: 1, username: tgUsersGroupsFilter };
        if (isReady) {
          fetchMemberList({ ...newParams, username: tgUsersGroupsFilter }, true);
        }
        return newParams;
      });
    }, debounceDelay);

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [tgUsersGroupsFilter, isReady]);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      setTgUserListParams((prev) => {
        if (tgUsersFilter === prev.phone) return prev;
        const newParams = { ...prev, page: 1, phone: tgUsersFilter };
        fetchTgUserList({ ...newParams, phone: tgUsersFilter }, true);
        return newParams;
      });
    }, debounceDelay);

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [tgUsersFilter]);
  useHistoryBack({
    isActive: !isIndependent ? isActive : false,
    onBack: () => (!isIndependent ? onReset?.() : undefined),
  });

  const handleMemberClick = useCallback((id: number) => {
    setTgUserListParams((prev) => {
      memberId = id;
      onSearchQuery?.('', false);
      const newParams = { ...prev, page: 1, memberId };
      onContentChange?.(LeftColumnContent.SwitchTgUser2);
      return newParams;
    });
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [onContentChange, onSearchQuery]);

  const onImageError = useCallback((item: MemberInfo, independent: boolean) => {
    if (independent && independentMemberInfo) {
      setIndependentMemberInfo((pre) => ({ ...pre as MemberInfo, avatar: '' }));
    }
    // 待完善列表中头像报错问题
  }, [independentMemberInfo]);

  const onMenuHandler = useCallback(async (
    type: 'single' | 'all' | 'unpinTop' | 'pinTop' | 'comment' | 'addGroup' | 'moveFolder',
    item?: TgUserListModel | IndependentTgUserItem,
  ) => {
    if (['all', 'single'].includes(type)) {
      const all = type === 'all';
      await tgUserReadAll({
        phones: all
          ? tgUserList.list.map((user) => user.phone!)
          // ?tgUserList.filter(item=>(item.groupName===''))
          : [item!.phone!],
        all: false,
      });
    }
    if (['unpinTop', 'pinTop'].includes(type) && item?.id) {
      workGramSocket?.emitEvent('tg/setTopUp', { id: item?.id, topUp: Number(type === 'pinTop') });
    }
    if (type === 'comment') {
      setCommentUserInfo(item);
      markRenderCommentModal();
    }
    if (type === 'addGroup') {
      markRenderAddFolderModal();
    }
    if (type === 'moveFolder') {
      setMoveFolderUserInfo(item);
      markRenderMoveFolderModal();
    }
  }, [tgUserList, workGramSocket]);
  const handleReadAll = useCallback(() => {
    onMenuHandler('all');
  }, [onMenuHandler]);
  const handleUserClick = useCallback(async (
    item: TgUserListModel | IndependentTgUserItem,
    e?: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    if (isIndependent) e?.preventDefault();
    if (!item?.phone || workData?.phone === item?.phone) return;
    // eslint-disable-next-line no-console
    const res = await tgUserExportAuth({
      phone: item?.phone,
      params: {
        memberId: item?.memberId,
        memberUsername: item?.memberUsername,
      },
    });
    if (res.code === 0) {
      const queryParams = new URLSearchParams({
        work_gram_iv: res.data?.iv ?? '',
        work_gram_hash: res.data?.auth ?? '',
        openFolderIds: accordion.toString(),
        ...tgUserGroupRef.current?.scrollTop?.toString()
        && { tgUserGroupRefScrollTop: tgUserGroupRef.current?.scrollTop?.toString() },
      }).toString();
      window.sessionStorage.clear();
      PASSCODE_IDB_STORE.clear();
      MAIN_IDB_STORE.clear();
      clearCaching();
      window.sessionStorage.setItem(
        TG_USER_FILTER_DATA,
        JSON.stringify({
          memberId,
          tgUsersGroupsFilter: tgUsersGroupsFilter || tgUsersGroupFilterBackup,
          tgUsersFilter,
        }),
      );
      setWorkGramSearchDate?.({ data: { memberId } });
      window.location.replace(`${window.location.origin}?${queryParams}`);
      // window.open(`${window.location.origin}?${queryParams}`, '_blank');
    }
  }, [isIndependent, tgUsersFilter, tgUsersGroupFilterBackup, tgUsersGroupsFilter, workData?.phone, accordion]);

  const handleKeyDown = useKeyboardListNavigation(
    content === LeftColumnContent.SwitchTgUser1
      ? memberListRef
      : tgUserListRef,
    true,
    (index) => {
      if (index < 0) return;
      if (content === LeftColumnContent.SwitchTgUser1) {
        handleMemberClick(memberList[index]?.id);
      } else if (content === LeftColumnContent.SwitchTgUser2) {
        handleUserClick(tgUserList.list[index]);
      }
    },
    '.ListItem-button:not(.no-focus)',
    true,
  );

  const onChangeSearchQuery = useCallback((query: string) => setSearchQuery(query), []);

  const listImmobilizationClick = useCallback(() => {
    setWorkGramSearchDate?.({ data: { listImmobilization: !listImmobilization } });
    // localStorage.setItem(GLOBAL_LIST_IMMOBILIZATION, !listImmobilization ? '1' : '0');
    setLocalizationData(GLOBAL_LIST_IMMOBILIZATION, !listImmobilization ? '1' : '0');
  }, [listImmobilization]);

  const openAccordion = useCallback(
    (folderId: string, isInit?: boolean) => {
      return () => {
        if (searchQuery) {
          return;
        }
        if (isInit) {
          setAccordion(folderId.split(','));
          return;
        }
        setAccordion(
          (pre) => {
            if (pre.includes(folderId)) {
              return pre.filter((item) => item !== folderId);
            }
            return [...pre, folderId];
          },
        );
      };
    },
    [searchQuery],
  );
  const isSearch = useMemo(() => searchQuery && searchQuery.length > 0, [searchQuery]);
  useEffect(() => {
    const preAccordion = searchPreAccordion.length === 0 ? [groupAllId] : searchPreAccordion;
    if (searchPreAccordion.length === 0 && searchQuery) {
      setSearchPreAccordion(accordion);
    }
    const nextAccordion = searchQuery
      ? tgUserList.groupedUsers.map((item) => item.folderId)
      : preAccordion;
    setAccordion(nextAccordion);
    if (!isSearch) {
      setSearchPreAccordion([]);
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [searchQuery]);

  const onScrollToFolder = useCallback((folderId: string) => {
    return () => {
      const top = folderTitleRefs.current[folderId]?.offsetTop || 0;
      tgUserGroupRef.current?.scrollTo({ top });
    };
  }, [folderTitleRefs]);

  const handleCommentClose = useCallback((comment = '') => {
    if (commentUserInfo?.id) {
      workGramSocket?.emitEvent('tg/setComment', { id: commentUserInfo?.id, comment });
      unmarkRenderCommentModal();
    }
  }, [commentUserInfo, workGramSocket]);
  const handleAddFolderClose = useCallback(async (folderName = '') => {
    if (folderName) {
      const res = await PostTgFoldersEdit({ folderName });
      if (res.code === 0) {
        unmarkRenderAddFolderModal();
        requestFolderList();
        return;
      }
      showNotification({
        message: res.message || '新增失败',
      });
    }
    unmarkRenderAddFolderModal();
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, []);
  const handleMoveFolderClose = useCallback(async (folderInfo?: TgFolder) => {
    if (folderInfo && moveFolderUserInfo?.id) {
      const list = tgUserList.groupedUsers.find(((item) => item.folderId === String(folderInfo?.id)))?.list || [];

      const res = await PostTgFoldersEdit({
        id: folderInfo.id,
        accounts: [...list.map((item) => item.id), moveFolderUserInfo?.id],
        folderName: folderInfo.folderName,
      });
      if (res?.code !== 0) {
        showNotification({
          message: res.message || '移动失败',
        });
        return;
      }
      fetchTgUserList();
      setMoveFolderUserInfo(undefined);
    }
    unmarkRenderMoveFolderModal();
  }, [fetchTgUserList, moveFolderUserInfo?.id, tgUserList.groupedUsers]);
  return (
    <>
      {content === LeftColumnContent.SwitchTgUser1 ? (
        <BuildMemberListView
          key="buildMemberListView"
          workData={workData}
          memberSet={memberSet}
          className={className}
          memberListRef={memberListRef}
          memberList={memberList}
          content={content}
          tgUsersGroupsFilter={tgUsersGroupsFilter}
          tgUsersFilter={tgUsersFilter}
          getMemberMore={getMemberMore}
          handleKeyDown={handleKeyDown}
          handleMemberClick={handleMemberClick}
        />
      ) : (
        <BuildTgUsersListView
          key="buildTgUsersListView"
          workData={workData}
          className={className}
          content={content}
          tgUsersGroupsFilter={tgUsersGroupsFilter}
          tgUsersFilter={tgUsersFilter}
          handleKeyDown={handleKeyDown}
          getTgUserMore={getTgUserMore}
          handleMemberClick={handleMemberClick}
          isIndependent={isIndependent}
          tgUserList={tgUserList}
          tgUserListRef={tgUserListRef}
          loginUserTop={loginUserTop}
          isOpenAutoReplyConfig={isOpenAutoReplyConfig}
          unReadGroupValue={unReadGroupList.type}
          tgUserSet={tgUserSet}
          listImmobilization={listImmobilization}
          independentMemberInfo={independentMemberInfo}
          listImmobilizationClick={listImmobilizationClick}
          handleLoginUserTop={handleLoginUserTop}
          handleReadAll={handleReadAll}
          unReadGroupClick={unReadGroupClick}
          handleUnReadGroupType={handleUnReadGroupType}
          handleUnReadOtherDataTimeChange={handleUnReadOtherDataTimeChange}
          handleIsOpenAutoReplyConfigChange={handleIsOpenAutoReplyConfigChange}
          onMenuHandler={onMenuHandler}
          onScrollToFolder={onScrollToFolder}
          handleUserClick={handleUserClick}
          onImageError={onImageError}
          accordions={accordion}
          tgUserGroupRef={tgUserGroupRef}
          openAccordion={openAccordion}
          folderTitleRefs={folderTitleRefs}
          isIndependentReady={isIndependentReady}
          onChangeSearchQuery={onChangeSearchQuery}
          getAutoReplyIsOpen={getAutoReplyIsOpen}
          handleSwitchTgUsers={handleSwitchTgUsers}
        />
      )}

      {shouldRenderCommentModal && (
        <CommentModal
          isOpen={shouldRenderCommentModal}
          oldComment={commentUserInfo?.comment || ''}
          onClose={handleCommentClose}
          onCloseAnimationEnd={unmarkRenderCommentModal}
        />
      )}
      {
        shouldRenderAddFolderModal && (
          <AddFolderModal
            isOpen={shouldRenderAddFolderModal}
            onClose={handleAddFolderClose}
            onCloseAnimationEnd={unmarkRenderCommentModal}
          />
        )
      }
      {
        shouldRenderMoveFolderModal && (
          <MoveFolderModal
            isOpen={shouldRenderMoveFolderModal}
            folderList={folderList}
            onClose={handleMoveFolderClose}
            onCloseAnimationEnd={unmarkRenderMoveFolderModal}
          />
        )
      }
    </>
  );
};

export default memo(withGlobal<OwnProps>(
  (global): StateProps => {
    const tabState = selectTabState(global);
    const { tgUserListSort } = global.settings.byKey;
    const {
      workGramSearch,
    } = tabState.globalSearch;

    const {
      countryList: {
        phoneCodes: phoneCodeList,
      },
    } = global;

    return {
      memberId: workGramSearch?.memberId,
      listImmobilization: workGramSearch?.listImmobilization,
      tgUserListSort,
      phoneCodeList,
    };
  },
)(TgUsersList));
