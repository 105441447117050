import CTR from '../lib/gramjs/crypto/CTR';

export interface WorkGramWebSocketConfig {
  socketIp?: string;
  socketPort?: string;
  webSocket: string;
  workGramSocket: string;
}

export interface WorkGramUserData extends WorkGramWebSocketConfig {
  dc: number;
  authKeyId: number;
  authKey: string;
  authHash: string;
  device: Device;
  phone: string;
  authorization: string;

  params?:{
    chatId?: number;
    memberId?: number;
    memberUsername?: string;
    operateMemberId?:number;
  };
}

export interface Device {
  appId: string;
  appHash: string;
  deviceModel: string;
  systemVersion: string;
  appVersion: string;
  systemLangCode: string;
  langPack: string;
  langCode: string;
}

export default function praseWorkGramHash(iv: string, workGramHash: string): WorkGramUserData {
  const workGramHashBuffer = Buffer.from(workGramHash, 'hex');
  const ivBuffer = Buffer.from(iv, 'hex');
  const keyBuffer = Buffer.from(process?.env?.AES_KEY ?? '');
  const ctr = new CTR(keyBuffer, ivBuffer);
  const charArray = Array.from(ctr.decrypt(workGramHashBuffer ?? ''))
    .map((code) => String.fromCharCode(code));
  const jsonString = charArray.join('');
  const data = JSON.parse(jsonString) as WorkGramUserData;
  data.socketIp = SOCKETS_IP || data?.socketIp;
  data.socketPort = SOCKETS_PORT || data?.socketPort;
  data.workGramSocket = WORKGRAM_WEB_SOCKET || data?.workGramSocket;
  return data;
}
