import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

// import { getActions } from '../../global';
import { WORK_GRAM_HASH, WORK_GRAM_IV, WORK_GRAM_TOKEN } from '../../config';
import praseWorkGramHash from '../../util/praseWorkGramHash';
import { log } from '../gramjs/helpers';

interface RequestServer {
  request: <T = any>(config: AxiosRequestConfig) => Promise<T>;
  get: <T = any>(url: string, config?: AxiosRequestConfig) => Promise<T>;
  post: <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<T>;
  delete: <T = any>(url: string, config?: AxiosRequestConfig) => Promise<T>;
  patch: <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<T>;
  put: <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<T>;
}

const defaultConfig: AxiosRequestConfig = {
  baseURL: '/api',
  timeout: 15000,
};
const authorization = (() => {
  const workGramAuthorization = sessionStorage.getItem(WORK_GRAM_TOKEN);
  const hash = sessionStorage.getItem(WORK_GRAM_HASH);
  const iv = sessionStorage.getItem(WORK_GRAM_IV);
  if (hash && iv) {
    const workData = praseWorkGramHash(JSON.parse(iv), JSON.parse(hash));
    return workData.authorization;
  }
  return workGramAuthorization;
})();

// const actions = getActions();
const createAxiosInstance = (config: AxiosRequestConfig = {}): AxiosInstance => {
  const instance = axios.create({
    ...defaultConfig,
    ...config,
  });

  instance.interceptors.request.use(
    (requestConfig) => {
      if (authorization) {
        requestConfig.headers = requestConfig.headers || {};
        requestConfig.headers.Authorization = authorization;
      }
      return requestConfig;
    },
    (error) => Promise.reject(error),
  );

  instance.interceptors.response.use(
    (response: AxiosResponse) => response.data,
    (error) => {
      if (error.response && error.response.status === 401) {
        log('INVOKE ERROR', error);
        sessionStorage.clear();
        window?.location?.reload();
      }
      return Promise.reject(error);
    },
  );

  return instance;
};

const createRequestFunction = (instance: AxiosInstance): RequestServer => ({
  request: <T = any>(config: AxiosRequestConfig) => instance.request<T, T>(config),
  get: <T = any>(url: string, config: AxiosRequestConfig = {}) => instance.get<T, T>(url, config),
  post: <T = any>(url: string, data?: any, config: AxiosRequestConfig = {}) => instance.post<T, T>(url, data, config),
  delete: <T = any>(url: string, config: AxiosRequestConfig = {}) => instance.delete<T, T>(url, config),
  patch: <T = any>(url: string, data?: any, config: AxiosRequestConfig = {}) => instance.patch<T, T>(url, data, config),
  put: <T = any>(url: string, data?: any, config: AxiosRequestConfig = {}) => instance.put<T, T>(url, data, config),
});

// 工厂函数，用于创建带有特定配置的请求函数集
const createRequestServer = (config?: AxiosRequestConfig): RequestServer => {
  const axiosInstance = createAxiosInstance(config);
  return createRequestFunction(axiosInstance);
};

// 创建一个默认的请求服务实例
const requestServer = createRequestServer();

export default requestServer;
