import type { FC } from '../../../../lib/teact/teact';
import React, {
  memo,
  useCallback, useEffect, useMemo, useState,
} from '../../../../lib/teact/teact';

import type { TgUserGroupInfo } from '../../../../lib/workGramClient/tgUser';

import buildClassName from '../../../../util/buildClassName';

import useOldLang from '../../../../hooks/useOldLang';

import Button from '../../../ui/Button';
import Menu from '../../../ui/Menu';
import MenuItem from '../../../ui/MenuItem';

import './styles/TgUsersListGroupButton.scss';

type OwnProps = {
  isShown?: boolean;
  groups:TgUserGroupInfo[];
  onScrollTo:(folderId:string)=>()=>void;
};

const GroupButton: FC<OwnProps> = ({
  isShown,
  groups,
  onScrollTo,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (!isShown) {
      setIsMenuOpen(false);
    }
  }, [isShown]);

  const lang = useOldLang();

  const fabClassName = buildClassName(
    'TgUsersListGroupButton',
    isShown && 'revealed',
    isMenuOpen && 'menu-is-open',
  );

  const toggleIsMenuOpen = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  const handleClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const menuItems = useMemo(() => (
    groups.map((group) => {
      return (
        <MenuItem onClick={onScrollTo(group.folderId)}>{group.folderName}</MenuItem>
      );
    })
  ), [groups, onScrollTo]);

  return (
    <div className={fabClassName} dir={lang.isRtl ? 'rtl' : undefined}>
      <Button
        round
        color="primary"
        className={isMenuOpen ? 'active' : ''}
        onClick={toggleIsMenuOpen}
        ariaLabel={isMenuOpen ? lang('Close') : '分组'}
        tabIndex={-1}
      >

        {
          isMenuOpen ? <i className="icon icon-close" /> : <i className="icon icon-group-filled" />
        }

      </Button>
      <Menu
        isOpen={isMenuOpen}
        positionX={lang.isRtl ? 'left' : 'right'}
        positionY="bottom"
        autoClose
        onClose={handleClose}
      >
        {menuItems}
      </Menu>
    </div>
  );
};

export default memo(GroupButton);
