import {
  useCallback, useMemo, useState,
} from '../../../lib/teact/teact';

import type { MassTextingFormData } from '../../../api/types/massTexting';

type MassTextingError = { [key in keyof MassTextingFormData]: string };

const useMassTextingCheck = (formData:MassTextingFormData) => {
  const [isShowError, setIsShowError] = useState(false);

  const error = useMemo<MassTextingError>(() => {
    let newError:MassTextingError = { };
    if (!formData.taskName) {
      newError = { ...newError, taskName: '群发任务名称不能为空' };
    }
    if (!formData.accounts?.length) {
      newError = { ...newError, accounts: '群发账号不能为空' };
    }
    if (formData.action === 2 && ((formData?.intervalTime ?? 0) < 1 || (formData?.intervalTime ?? 0) > 999)) {
      newError = { ...newError, intervalTime: '间隔时间不符合要求' };
    }
    if ((formData?.executeLimit ?? 0) < 1 || (formData?.executeLimit ?? 0) > (formData?.accounts?.length ?? 1)) {
      newError = { ...newError, executeLimit: '执行次数不符合要求' };
    }
    if ((formData?.triggerLimit ?? 0) < 1 || (formData?.triggerLimit ?? 0) > 5) {
      newError = { ...newError, triggerLimit: '账号限制重试次数不符合要求' };
    }
    if ((formData.tagetsType === 2 || formData.tagetsType === 3) && formData.inputType === 3 && !formData.dataId) {
      newError = { ...newError, dataId: '数据仓库不能为空' };
    }
    if ((formData.tagetsType === 2 || formData.tagetsType === 3)
       && formData.inputType !== 3
     && (!formData.tagets || !formData.tagets.length)) {
      newError = { ...newError, tagets: '群发对象不能为空' };
    }
    if (formData?.sendMode === 1 && formData.delayTime === undefined) {
      newError = { ...newError, delayTime: '延迟时间不能为空' };
    }
    if (formData.scriptId === undefined) {
      const text = formData.scriptMode === 1 ? '营销话术' : '营销话术组';
      newError = { ...newError, scriptId: `${text}不能为空` };
    }

    return newError;
  }, [formData]);

  const checkFormData = useCallback(() => {
    const errorMap = new Map(Object.entries(error));
    if (errorMap.size) {
      setIsShowError(true);
      return false;
    }
    setIsShowError(false);
    return true;
  }, [error]);

  return { formDataError: isShowError ? error : {}, checkFormData };
};
export default useMassTextingCheck;
