import type { CachedLangData } from '../../api/types';
import type { WgGlobalState } from './type';

import {
  GLOBAL_LIST_IMMOBILIZATION, TRANSLATION_LANGUAGE, TRANSLATION_LANGUAGE_INPUT, WG_GLOBAL_STATE,
} from '../../config';
// import { log } from '../../api/gramjs/helpers';
import { MAIN_IDB_STORE } from '../browser/idb';

const LANGPACK_STORE_PREFIX = 'langpack-';
function loadCachedLangData(langCode: string) {
  return MAIN_IDB_STORE.get<CachedLangData>(`${LANGPACK_STORE_PREFIX}${langCode}`);
}
export async function initLocalizationData(langCode: string) {
  const wgGlobalState = localStorage.getItem(WG_GLOBAL_STATE)
    ? JSON.parse(localStorage.getItem(WG_GLOBAL_STATE) || '{}')
    : undefined;
  const cachedData = await loadCachedLangData(langCode);
  //   log('INVOKE ERROR', 'Localization loaded', cachedData, langCode, wgGlobalState);
  let stateUpdated = false;

  if (wgGlobalState && !Reflect.has(wgGlobalState, TRANSLATION_LANGUAGE)) {
    wgGlobalState[TRANSLATION_LANGUAGE] = {
      langCodes: cachedData?.language.langCode || langCode || 'en',
      titleC: cachedData?.language.name || langCode || 'English',
      titleE: cachedData?.language.nativeName || langCode || 'English',
    };
    stateUpdated = true;
  }

  if (wgGlobalState && !Reflect.has(wgGlobalState, TRANSLATION_LANGUAGE_INPUT)) {
    wgGlobalState[TRANSLATION_LANGUAGE_INPUT] = {
      langCodes: cachedData?.language.langCode || langCode || 'en',
      titleC: cachedData?.language.name || langCode || 'English',
      titleE: cachedData?.language.nativeName || langCode || 'English',
    };
    stateUpdated = true;
  }
  if (wgGlobalState && !Reflect.has(wgGlobalState, GLOBAL_LIST_IMMOBILIZATION)) {
    wgGlobalState[GLOBAL_LIST_IMMOBILIZATION] = '1';
    stateUpdated = true;
  }

  if (stateUpdated) {
    localStorage.setItem(WG_GLOBAL_STATE, JSON.stringify(wgGlobalState));
  }
}
export function setLocalizationData<T extends keyof WgGlobalState>(key: T, value: WgGlobalState[T]) {
  const wgGlobalState = localStorage.getItem(WG_GLOBAL_STATE)
    ? JSON.parse(localStorage.getItem(WG_GLOBAL_STATE) || '{}')
    : undefined;
  let stateUpdated = false;
  if (wgGlobalState) {
    wgGlobalState[key] = value;
    stateUpdated = true;
  }
  if (stateUpdated) {
    localStorage.setItem(WG_GLOBAL_STATE, JSON.stringify(wgGlobalState));
  }
}
export function getLocalizationData<T extends keyof WgGlobalState>(key:T) {
  const wgGlobalState = localStorage.getItem(WG_GLOBAL_STATE)
    ? JSON.parse(localStorage.getItem(WG_GLOBAL_STATE) || '{}') as WgGlobalState
    : undefined;
  return wgGlobalState?.[key];
}
