import type { TeactNode } from '../../../lib/teact/teact';
import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from '../../../lib/teact/teact';

import type { TextPart } from '../../../types';
import type { ListItemType, PeerPickerModelDefaultT } from './PeerPickerSelectContainer';
import type { HandlePeerPickerChangeParams } from './types';
import { AllCheckStateEnum } from './types';

import useLastCallback from '../../../hooks/useLastCallback';
import useOldLang from '../../../hooks/useOldLang';

import PickerModal from '../../common/pickers/PickerModal';
import PeerPickerSelectContainer from './PeerPickerSelectContainer';

type OwnProps<T, K> = {
  type?: 'checkbox' | 'radio' | 'nothing';
  title?: string | TextPart[];
  isLoading?: boolean;
  isOpen?: boolean;
  /** 选择的内容，回显 */
  initialSelectedMap: Map<string, ListItemType<K>>;
  listData: ListItemType<T, K>[];
  /** 搜索需要的key数组 */
  searchKey?:(keyof T | keyof K)[];
  /** 是否需要选择后展示纸片 */
  needChip?:boolean;
  /** 是否需要默认搜索框 */
  needSearch?:boolean;
  /** 顶部自定义内容 */
  HeaderChildren?:TeactNode;
  /** 底部自定义内容 */
  FooterChildren?:TeactNode;
  confirmButtonText?: string;
  /** 是否打开全部文件夹（搜索打开全部） */
  isOpenAllFolder?:boolean;
  /** 搜索回调 */
  onSearchChange?: (query: string) => void;
  /** 自定义渲染列表项 */
  renderBaseItem?: (item:ListItemType<T, K>) => TeactNode;
  /** 自定义渲染子列表项 */
  renderChildrenItem?: (item:ListItemType<K>) => TeactNode;
  /** 确认回调 */
  onSelectedIdsConfirmed: (submitValue: Map<string, ListItemType<K>>) => void;
  onItemClick?: (params:HandlePeerPickerChangeParams<T, K>) => any;
  onClose: NoneToVoidFunction;
};

const PeerPickerModel = <T extends PeerPickerModelDefaultT, K = any>({
  type = 'checkbox',
  title,
  isOpen,
  isLoading,
  initialSelectedMap,
  listData,
  searchKey = [],
  needChip = true,
  needSearch = true,
  HeaderChildren,
  FooterChildren,
  isOpenAllFolder,
  confirmButtonText,
  onSearchChange,
  renderBaseItem,
  renderChildrenItem,
  onSelectedIdsConfirmed,
  onItemClick,
  onClose,
}: OwnProps<T, K>) => {
  const lang = useOldLang();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectDataMap, setSelectDataMap] = useState<Map<string, ListItemType<K>>>(new Map());
  useEffect(() => {
    if (isOpen) {
      setSelectDataMap(initialSelectedMap);
    }
  }, [initialSelectedMap, isOpen]);

  const filterData = useCallback(
    (item: ListItemType<T, K> | ListItemType<K>, searchKeys:(keyof T | keyof K)[], searchQueryValue:string) => {
      const data = item?.data;
      return (searchKeys.length > 0
        && searchKeys.some((key) => {
          if (data) {
            const value = data[key as keyof typeof data];
            if (typeof value === 'string') {
              return value.toLowerCase().includes(searchQueryValue.toLowerCase());
            }
          }
          return false;
        }))
     || (item.name && item.name.toLowerCase().includes(searchQueryValue.toLowerCase()));
    }, [],
  );
  const listDataSearch = useMemo(() => {
    if (searchQuery.trim?.()) {
      return listData?.map(
        (item) => {
          if (item.children) {
            const newChildren = item.children.filter((child) => (filterData(child, searchKey, searchQuery)));
            return newChildren.length > 0 ? { ...item, children: newChildren } : undefined;
          }
          return filterData(item, searchKey, searchQuery) ? item : undefined;
        },
      ).filter((item):item is ListItemType<T, K> => item !== undefined) || [];
    }
    return listData;
  }, [filterData, listData, searchKey, searchQuery]);

  const handleModalConfirm = useLastCallback(() => {
    onSelectedIdsConfirmed(selectDataMap);
    onClose();
  });

  const handlePeerPickerChange = useCallback((params:HandlePeerPickerChangeParams<T, K>) => {
    onItemClick?.(params);
    /** 全选框 */
    if ('state' in params) {
      setSelectDataMap((p) => {
        const newMap = new Map(p);
        if ([AllCheckStateEnum.Unchecked, AllCheckStateEnum.Indeterminate].includes(params.state)) {
          listData.forEach((item) => {
            if (item.children) {
              item.children.forEach((child) => {
                if (child?.disabled) {
                  newMap.delete(`${child.id}`);
                  return;
                }
                newMap.set(`${child.id}`, child);
              });
            } else {
              if (item?.disabled) {
                newMap.delete(`${item.id}`);
                return;
              }
              newMap.set(`${item.id}`, item as unknown as ListItemType<K>);
            }
          });
        }
        if (params.state === AllCheckStateEnum.Checked) {
          listData.forEach((item) => {
            if (item.children) {
              item.children.forEach((child) => newMap.has(`${child.id}`) && newMap.delete(`${child.id}`));
            }
            if (newMap.has(`${item.id}`)) {
              newMap.delete(`${item.id}`);
            }
          });
        }
        return newMap;
      });
      return;
    }
    /** 选择分组或选择单个 */
    const { isChecked, data, mapKey } = params;
    setSelectDataMap((prevMap) => {
      if (type === 'checkbox') {
        const newMap = new Map(prevMap);
        if (data.children) {
          data.children.forEach((item) => {
            const id = `${item.id}`;
            if (isChecked) newMap.delete(id);
            else {
              if (item.disabled) {
                newMap.delete(id);
                return;
              }
              newMap.set(id, item);
            }
          });
        } else {
          const id = `${mapKey}`;
          if (isChecked) {
            newMap.delete(id);
          } else {
            newMap.set(id, data as ListItemType<K>);
          }
        }
        return newMap;
      } else {
        const newMap = new Map();
        newMap.set(mapKey, data as ListItemType<K>);
        return newMap;
      }
    });
  }, [listData, onItemClick, type]);
  const isOpenAllFolderMemo = useMemo(() => !!isOpenAllFolder || !!searchQuery, [isOpenAllFolder, searchQuery]);

  return (
    <PickerModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      hasCloseButton
      shouldAdaptToSearch
      withFixedHeight
      confirmButtonText={confirmButtonText}
      onConfirm={handleModalConfirm}
      // onEnter={handleModalConfirm}
    >
      <PeerPickerSelectContainer<T, K>
        isLoading={isLoading}
        filterValue={searchQuery}
        listData={listDataSearch || []}
        originListData={listData}
        filterPlaceholder={lang('Search')}
        onFilterChange={onSearchChange ?? setSearchQuery}
        // isSearchable
        withDefaultPadding
        itemInputType={type}
        selectDataMap={selectDataMap}
        needChip={needChip}
        needSearch={needSearch}
        HeaderChildren={HeaderChildren}
        FooterChildren={FooterChildren}
        isOpenAllFolder={isOpenAllFolderMemo}
        renderBaseItem={renderBaseItem}
        renderChildrenItem={renderChildrenItem}
        onPeerPickerChange={handlePeerPickerChange}
      />
    </PickerModal>
  );
};

export default memo(PeerPickerModel);
